/* eslint-disable no-case-declarations */
import { ActionTypes, CultureRadioModalReducer } from './types';

const cultureFormModalInit = {
  isOpen: false,
  thisCard: null,
};

const cultureFormModalReducer: CultureRadioModalReducer = (
  state = cultureFormModalInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_CULTURE_FORM_MODAL:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default cultureFormModalReducer;
