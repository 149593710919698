/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThunkActionReturn } from 'Store/types';
import setAccountType from './action';

const setAccountTypeThunk = (accountType: string): ThunkActionReturn => {
  return dispatch => {
    dispatch(setAccountType(accountType));
    localStorage.setItem('accountType', accountType);
  };
};

export default setAccountTypeThunk;
