import styled from 'styled-components';

export const Container = styled.div`
  h1,
  p {
    text-align: left;
  }

  h1 {
    font-size: 18px !important;
  }

  @media screen and (max-width: 480px) {
    h1 {
      width: 90%;
      margin: initial;
      margin-bottom: 18px;
    }
  }
`;
