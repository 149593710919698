import { colors } from 'AppV2/styles/colors';
import styled, { css } from 'styled-components';

interface Props {
  loadingWallet: boolean;
}

export const HeaderOwnerWalletContainer = styled.div.attrs({
  className: 'flexbox-center cubicBezierAnimation',
})<Props>`
  height: auto;
  display: flex;
  gap: 5px;

  ${({ loadingWallet }) =>
    loadingWallet &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ loadingWallet }) =>
    !loadingWallet &&
    css`
      animation: loading 2s ease-out;
      @keyframes loading {
        100% {
          flex-direction: row-reverse;
        }
      }
    `}

  @media screen and (max-width: 480px) {
    gap: 3px;
  }
`;

export const IconContent = styled.div.attrs({
  className: 'flexbox-center cubicBezierAnimation',
})<Props>`
  color: ${colors.purple300};
  width: 16px;
  height: 16px;

  ${({ loadingWallet }) =>
    loadingWallet &&
    css`
      svg {
        animation: loading 1s linear infinite;
        @keyframes loading {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    `}

  @media screen and (max-width: 480px) {
    width: 18px;
    height: 18px;
  }
`;

export const Ammount = styled.div.attrs({
  className: 'cubicBezierAnimation flexbox-center',
})<Props>`
  .label-content {
    font-family: 'Mulish', sans-serif !important;
    color: ${colors.purple300};
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;

    display: inline-block;
    margin: 0px;
    margin-bottom: 0px;
    opacity: 0;
    transition: 0.3s;
    transform: translateX(-90px);
  }

  ${({ loadingWallet }) =>
    !loadingWallet &&
    css`
      .label-content {
        opacity: 1;
        transform: translateX(0px);
      }
    `}

  @media screen and (max-width: 480px) {
    .label-content {
      font-size: 12px;
    }
  }
`;
