/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function TaskFlagIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6687 2.14282H8.77213C5.9273 2.14282 4.14282 4.1287 4.14282 7.31493V20.4659C4.14282 21.392 4.87615 22.1428 5.78075 22.1428C6.05462 22.1426 6.32417 22.0729 6.56524 21.9398L12.1773 18.8242L18.7118 21.9575C18.889 22.0336 19.0885 22.0346 19.2663 21.9601C19.4442 21.8856 19.5858 21.7418 19.6601 21.5603C19.7696 21.2259 19.6368 20.8585 19.3411 20.6777L12.8497 17.5709C12.4256 17.3322 11.9117 17.3322 11.4876 17.5709L5.86696 20.6777C5.8025 20.7138 5.72452 20.7138 5.66006 20.6777C5.59878 20.6373 5.56276 20.5669 5.56524 20.4923V7.31493C5.57386 4.95836 6.72903 3.61679 8.79799 3.61679H15.6945C18.3497 3.61679 18.7376 5.82332 18.7376 7.14724V7.74741H11.2721C11.0823 7.75431 10.903 7.83819 10.7736 7.98056C10.6443 8.12294 10.5755 8.31214 10.5825 8.50646C10.5916 8.8925 10.8951 9.20324 11.2721 9.21255H18.7118V16.9354C18.7118 17.34 19.0321 17.668 19.4273 17.668C19.8191 17.668 20.1381 17.3454 20.1428 16.9442V16.9442V7.14724C20.1428 4.05809 18.4359 2.14282 15.6687 2.14282Z"
        fill={iconColor}
      />
    </svg>
  );
}
