export const regexPattern = (type: string) => {
  const regex = {
    email:
      '^[a-zA-Z0-9_+-]+[a-zA-Z0-9._+-]*[a-zA-Z0-9_+-]+@[a-zA-Z0-9_+-]+[a-zA-Z0-9._+-]*[.]{1,1}[a-zA-Z]{2,}$',
    password:
      /^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{6,}$/,
    // mínimo 1 letra (minúscula e maiúscula), 1 número e 1 caratere especial
    onlyDigit: /[^0-9.]+/g,
  };

  if (regex[type]) {
    return regex[type];
  }
};
