import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_CMS_PROJECTS = '@CMSProjects/SET',
}

export type CMSProjects = {
  isFilter: boolean;
  searchJSON: null;
};

export type CMSProjectsReducer = Reducer<
  CMSProjects,
  Action<ActionTypes, CMSProjects>
>;
