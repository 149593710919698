/* eslint-disable no-case-declarations */
import { ActionTypes, DragSideModalReducer } from './types';

const dragSideModalInit = {
  isOpen: false,
  cards: null,
  actions: null,
};

const dragSideModalReducer: DragSideModalReducer = (
  state = dragSideModalInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_DRAG_SIDE_MODAL:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default dragSideModalReducer;
