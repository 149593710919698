import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { WorkspaceUserData } from './types';

export const getUsers = async (): Promise<
  AxiosResponse<WorkspaceUserData[]>
> => {
  try {
    const response = await apiV2Instance.get(`/workspace/getUsers`);
    console.log('[services][Workspace][getUsers][RESPONSE]', response);

    return response;
  } catch (error) {
    console.log('[services][Workspace][getUsers][ERROR]', error);

    throw error;
  }
};
