import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const useGoogleTagManager = () => {
  const location = useLocation();
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        page: location.pathname,
      },
      dataLayerName: 'PageDataLayer',
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);
};

export default useGoogleTagManager;
