/* eslint-disable no-case-declarations */
import { ActionTypes, AccountTypeReducer } from './types';

const accountTypeReducer: AccountTypeReducer = (
  state = 'squadMember',
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_ACCOUNT_TYPE:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default accountTypeReducer;
