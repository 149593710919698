/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import Lottie from 'lottie-react';

const LottieAnimation = ({ thisSrc, thisWidth }) => {
  return (
    <div className="animation-content flexbox-center cubicBezierAnimation">
      <Lottie
        loop={true}
        autoPlay={true}
        animationData={thisSrc}
        style={{ width: thisWidth, margin: '0 auto' }}
      />
    </div>
  );
};

export default LottieAnimation;
