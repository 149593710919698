import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MODAL = '@modal/SET',
}

export type Modal = null;

export type ModalReducer = Reducer<Modal, Action<ActionTypes>>;
