import MoneyIconDefault from './variations/Default';
import MoneyIconOldVersion from './variations/OldVersion';

type MoneyIconDefaultType = typeof MoneyIconDefault;
type MoneyIconOldVersionType = typeof MoneyIconOldVersion;

interface CompoundedComponent extends MoneyIconOldVersionType {
  Default: MoneyIconDefaultType;
}

const MoneyIcon = MoneyIconOldVersion as CompoundedComponent;
MoneyIcon.Default = MoneyIconDefault;

export default MoneyIcon;
