import { Action } from 'Store/types';
import { ActionTypes } from './types';

const setLoginOrSignupForm = (
  isLoginForm: boolean,
): Action<ActionTypes, boolean> => ({
  type: ActionTypes.SET_LOGIN_OR_SIGNUP_FORM,
  payload: isLoginForm,
});

export default setLoginOrSignupForm;
