import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { GetOwnerByEmailData, GetOwnerByEmailRequest } from './types';

export const getOwnerByEmail = async ({
  ownerEmail,
}: GetOwnerByEmailRequest): Promise<AxiosResponse<GetOwnerByEmailData>> => {
  try {
    const response = await apiV2Instance.get(`users/findowner/${ownerEmail}`);
    console.log('[services][MyUsers][getOwnerByEmail][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][MyUsers][getOwnerByEmail][ERROR]', errorResponse);

    throw errorResponse;
  }
};
