/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function PostProjectIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1451_1140)">
        <path
          d="M6.6365 17.8475H11.2198C11.6046 17.8475 11.9165 18.1594 11.9165 18.5442C11.9165 18.9289 11.6046 19.2408 11.2198 19.2408H6.64567C3.10734 19.2408 0.916504 17.05 0.916504 13.5208V6.64584C0.916504 2.84167 2.61234 0.916672 5.93984 0.916672H7.69984C8.55234 0.916672 9.34984 1.30167 9.87234 1.96167L10.679 3.03417C10.9265 3.35501 11.3115 3.53834 11.7148 3.53834H14.2173C17.5998 3.53834 19.2498 5.34417 19.2498 9.05667V9.99167C19.2498 10.3858 18.9382 10.6975 18.5532 10.6975C18.3607 10.6975 18.1865 10.6242 18.049 10.4958C17.9115 10.3675 17.8382 10.1842 17.8382 10.0008V9.06584C17.8382 6.12334 16.8298 4.95001 14.2082 4.95001H11.7148C10.8623 4.95001 10.0648 4.54667 9.5515 3.86834L8.74484 2.80501C8.49734 2.48417 8.11234 2.29167 7.709 2.29167H5.93984C3.40067 2.29167 2.319 3.58417 2.319 6.61834V11.4492H13.6032C13.9882 11.4492 14.2998 11.7608 14.2998 12.155C14.2998 12.5492 13.9882 12.8608 13.6032 12.8608H2.319V13.53C2.319 16.3167 3.84984 17.8475 6.6365 17.8475Z"
          fill={iconColor}
        />
        <path
          d="M17.5907 21.12C17.1965 21.12 16.8757 20.7992 16.8757 20.405V13.6125C16.8757 13.2183 17.1965 12.8975 17.5907 12.8975C17.9848 12.8975 18.3057 13.2183 18.3057 13.6125V20.405C18.3057 20.7992 17.9848 21.12 17.5907 21.12Z"
          fill={iconColor}
        />
        <path
          d="M20.9823 17.7283H14.1898C13.7957 17.7283 13.4748 17.4075 13.4748 17.0133C13.4748 16.6192 13.7957 16.2983 14.1898 16.2983H20.9823C21.3765 16.2983 21.6973 16.6192 21.6973 17.0133C21.6973 17.4075 21.3765 17.7283 20.9823 17.7283Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1451_1140">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
