/* eslint-disable no-case-declarations */
import { ActionTypes, ScorePerfilReducer } from './types';

const scorePerfilInit = 0;

const scorePerfilReducer: ScorePerfilReducer = (
  state = scorePerfilInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_SCORE_PERFIL:
      const { payload } = action;
      return payload;
    default:
      return state;
  }
};

export default scorePerfilReducer;
