import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_SOFT_SKILLS_TYPE = '@userSoftSkillsType/SET',
}
export type UserSoftSkills = {
  innovation: string;
  level: string;
  mentalSecurity: string;
  purpose: string;
  teamWork: string;
  totalPoints: string;
  trustiness: string;
};

export type UserSoftSkillsReducer = Reducer<
  UserSoftSkills,
  Action<ActionTypes>
>;
