/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function LinkIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81787 1.52801C8.5024 0.866877 9.41921 0.501048 10.3708 0.509317C11.3225 0.517586 12.2328 0.899293 12.9057 1.57223C13.5787 2.24516 13.9604 3.15547 13.9686 4.10711C13.9769 5.05874 13.6111 5.97555 12.9499 6.66008L12.9428 6.66748L11.1205 8.48966C10.7525 8.85771 10.3097 9.1424 9.8221 9.32428C9.33447 9.50618 8.81343 9.58108 8.29431 9.5439C7.7752 9.50672 7.27015 9.35834 6.81342 9.10881C6.35669 8.85928 5.95897 8.51444 5.64724 8.09768C5.45131 7.83576 5.50482 7.4646 5.76675 7.26868C6.02867 7.07275 6.39983 7.12626 6.59575 7.38819C6.80575 7.66893 7.07367 7.90123 7.38134 8.06932C7.68901 8.23741 8.02923 8.33737 8.37893 8.36242C8.72863 8.38746 9.07962 8.33701 9.40811 8.21447C9.73659 8.09194 10.0349 7.90019 10.2827 7.65224L12.1013 5.83368C12.5445 5.37293 12.7897 4.75683 12.7842 4.1174C12.7786 3.47634 12.5215 2.86312 12.0681 2.4098C11.6148 1.95649 11.0016 1.69935 10.3605 1.69378C9.72084 1.68822 9.10449 1.93361 8.64368 2.37721L7.60208 3.41275C7.37012 3.64336 6.99513 3.64227 6.76451 3.41031C6.5339 3.17834 6.53499 2.80335 6.76695 2.57273L7.81787 1.52801Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85067 4.94671C5.33829 4.76481 5.85933 4.68991 6.37845 4.72709C6.89756 4.76427 7.40261 4.91266 7.85934 5.16219C8.31607 5.41172 8.71379 5.75655 9.02552 6.17331C9.22145 6.43524 9.16794 6.80639 8.90602 7.00232C8.64409 7.19824 8.27293 7.14473 8.07701 6.88281C7.86701 6.60206 7.59909 6.36977 7.29142 6.20167C6.98375 6.03358 6.64353 5.93362 6.29383 5.90858C5.94413 5.88353 5.59314 5.93399 5.26466 6.05652C4.93617 6.17905 4.63788 6.3708 4.39002 6.61875L2.57146 8.43732C2.12822 8.89806 1.88304 9.51416 1.8886 10.1536C1.89417 10.7947 2.1513 11.4079 2.60462 11.8612C3.05793 12.3145 3.67116 12.5716 4.31222 12.5772C4.95166 12.5828 5.56776 12.3376 6.02851 11.8943L7.06338 10.8595C7.29467 10.6282 7.66967 10.6282 7.90096 10.8595C8.13225 11.0908 8.13225 11.4658 7.90096 11.697L6.85495 12.743C6.17043 13.4042 5.25356 13.7699 4.30192 13.7617C3.35029 13.7534 2.43997 13.3717 1.76704 12.6988C1.09411 12.0258 0.712404 11.1155 0.704134 10.1639C0.695864 9.21226 1.06169 8.29544 1.72283 7.61092L1.72998 7.60351L3.55229 5.78133C3.92022 5.41329 4.36307 5.1286 4.85067 4.94671Z"
        fill={iconColor}
      />
    </svg>
  );
}
