/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
const InputIconLeft = ({
  thisType,
  thisIconClass,
  thisEyeIcon,
  thisSetEyeIcon,
  thisName,
  thisValue,
  thisId,
  thisContainerId,
  thisPlaceholder,
  thisRef,
  thisPattern,
  thisMinLength,
  thisOnChange,
  thisOnFocus,
  thisOnBlur,
  thisTestId,
  isPasswordInput = false,
  children,
}) => {
  return (
    <div className="input-with-icon-left" id={thisContainerId}>
      <i className={thisIconClass} />
      <input
        className="input-text with-border"
        type={thisType}
        name={thisName}
        id={thisId}
        value={thisValue}
        placeholder={thisPlaceholder}
        ref={thisRef}
        pattern={thisPattern}
        size="30"
        onChange={thisOnChange}
        onFocus={thisOnFocus}
        onBlur={thisOnBlur}
        minLength={thisMinLength}
        required
        data-testid={thisTestId}
      />
      {children}
      {isPasswordInput && (
        <i className={thisEyeIcon} onClick={thisSetEyeIcon} />
      )}
    </div>
  );
};

export default InputIconLeft;
