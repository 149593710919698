import { ActionTypes, ExperiencesReducer } from './types';

/* eslint-disable no-case-declarations */
const storagedExperiences = localStorage.getItem('userExperiences');

const userExperiencesInit: any[] = [];

const userExperiences = storagedExperiences
  ? JSON.parse(storagedExperiences)
  : userExperiencesInit;

const experiencesReducer: ExperiencesReducer = (
  state = userExperiences,
  action,
) => {
  switch (action.type) {
    case ActionTypes.ADD_EXPERIENCES:
      return [...state, action.payload];

    case ActionTypes.UPDATE_EXPERIENCES:
      return action.payload;

    case ActionTypes.REMOVE_EXPERIENCES:
      return action.payload;

    case ActionTypes.RESET_EXPERIENCES:
      return action.payload;

    default:
      return state;
  }
};

export default experiencesReducer;
