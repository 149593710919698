/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-filename-extension */
import { ReactNode, useEffect, useState } from 'react';
import Modal, { Styles } from 'react-modal';
// import CloseSquareIcon from 'AppV2/assets/icons/CloseSquare';
import { ModalSectionContainer } from './styles';
import { useModal } from 'AppV2/providers/ModalProvider';
import CloseIcon from 'AppV2/assets/icons/Close';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

const customStyles = {
  content: {
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px',
    borderRadius: '12px',
    border: 'none',
    overflow: 'hidden',
    minHeight: '100vh',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(6, 6, 6, 72%)',
    backdropFilter: 'blur(6px)',
    zIndex: 99999,
  },
};

Modal.setAppElement('#root');

interface ModalProps {
  isOpen?: boolean;
  onlyOverLay?: boolean;
  bgColor?: string;
  children: ReactNode;
  outPadding?: boolean;
  showButtonClose?: boolean;
  buttonCloseColor?: string;
  styles?: Styles;
  closeButtonAction?: () => void;
  keypressEscAction?: () => void;
}

export const ModalMainDefault = ({
  isOpen,
  onlyOverLay,
  children,
  outPadding = false,
  bgColor,
  showButtonClose = true,
  buttonCloseColor,
  styles = { content: {}, overlay: {} },
  closeButtonAction,
  keypressEscAction,
}: ModalProps): React.ReactElement => {
  const { closeModal } = useModal();
  const [openOverlay, setOpenOverlay] = useState(false);

  const handleCloseModal = () => {
    closeButtonAction && closeButtonAction();
    closeModal();
  };

  if (onlyOverLay) {
    outPadding = true;
    showButtonClose = false;
    bgColor = 'transparent';
  }

  useEffect(() => {
    if (isOpen) {
      setOpenOverlay(true);
    }
    if (isOpen === false) {
      setTimeout(() => setOpenOverlay(false), 450);
    }
  }, [isOpen]);

  return (
    <Modal
      style={{
        content: { ...customStyles.content, ...styles.content },
        overlay: { ...customStyles.overlay, ...styles.overlay },
      }}
      isOpen={openOverlay}
      onRequestClose={keypressEscAction || handleCloseModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
    >
      <AnimatePresence>
        {isOpen && (
          <ModalSectionContainer
            outPadding={outPadding}
            bgColor={bgColor}
            buttonCloseColor={buttonCloseColor}
            initial={{
              y: 1000,
            }}
            animate={{ y: 0 }}
            exit={{ y: 1000 }}
          >
            {showButtonClose && (
              <button id="close-modal-button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            )}

            {children}
          </ModalSectionContainer>
        )}
      </AnimatePresence>
    </Modal>
  );
};
