import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MEMBER_DATA = '@memberData/SET',
}

export type MemberData = any;

export type MemberDataReducer = Reducer<MemberData, Action<ActionTypes>>;
