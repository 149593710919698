import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_SOFTWARES = '@softwares/ADD',
  SET_SOFTWARES = '@softwares/SET',
  REMOVE_SOFTWARES = '@softwares/REMOVE',
  RESET_SOFTWARES = '@softwares/RESET',
}

export type Softwares = any[];

export type SoftwaresExpertise = {
  tag: string;
  expertise: number;
}[];

export type SoftwaresReducer = Reducer<Softwares, Action<ActionTypes>>;
