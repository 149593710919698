import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_NEW_LANGUAGE = '@newLanguage/SET',
  ADD_NEW_LANGUAGE = '@newLanguage/ADD',
  REMOVE_NEW_LANGUAGE = '@newLanguage/REMOVE',
}

export type NewLanguages = any[];

export type NewLanguagesReducer = Reducer<NewLanguages, Action<ActionTypes>>;
