import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PostAddUsersRequest } from './types';

export const postAddUsers = async ({
  usersId,
}: PostAddUsersRequest): Promise<AxiosResponse<Response>> => {
  try {
    const response = await apiV2Instance.post(`/workspace/addUser`, {
      usersId: usersId,
    });

    console.log(
      '[services][Workspace][AddResourse:postAddUser][RESPONSE]',
      response,
    );

    return response;
  } catch (error) {
    console.log('[services][Workspace][AddResourse:postAddUser][ERROR]', error);

    throw error;
  }
};
