/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode } from 'react';
import {
  AnimatePresence,
  HTMLMotionProps,
} from 'framer-motion/dist/framer-motion';
import * as S from './styles';
import { CSSObject } from 'styled-components';
import CloseIcon from 'AppV2/assets/icons/Close';
import HelperIcon from 'AppV2/assets/icons/HelperIcon';
import { useScreenDetect } from 'AppV2/hooks/ScreenDetect';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  headerTitle?: string;
  headerIcon?: ReactNode;
  headerOnClose?: () => void;
  customStyles?: {
    backdrop?: CSSObject;
    container?: CSSObject;
  };
  customAnimations?: {
    backdrop?: HTMLMotionProps<'aside'>;
    container?: HTMLMotionProps<'div'>;
  };
}

export const ModalMainAside = ({ ...props }: Props): ReactElement => {
  const { isMobile } = useScreenDetect();
  const backDropAnimation = isMobile
    ? S.MobileBackdropAnimation
    : S.BackdropAnimation;
  const containerAnimation = isMobile
    ? S.MobileContainerAnimation
    : S.ContainerAnimation;

  return (
    <AnimatePresence mode="wait">
      {props.isOpen && (
        <S.BackdropWrapper
          {...backDropAnimation}
          {...props.customAnimations?.backdrop}
          customStyles={props?.customStyles?.backdrop}
        >
          <S.Container
            {...containerAnimation}
            {...props.customAnimations?.container}
            customStyles={props?.customStyles?.container}
          >
            {props.headerTitle && (
              <S.Header>
                <div className="modal-aside-title-content">
                  <div className="modal-aside-icon-content">
                    {props?.headerIcon || <HelperIcon />}
                  </div>
                  <h2 className="modal-aside-title">{props.headerTitle}</h2>
                </div>

                <button
                  className="modal-aside-button-close"
                  onClick={props?.headerOnClose}
                >
                  <CloseIcon />
                </button>
              </S.Header>
            )}

            {props.children}
          </S.Container>
        </S.BackdropWrapper>
      )}
    </AnimatePresence>
  );
};
