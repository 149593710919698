import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_PERSONALITY_TYPE = '@userPersonalityType/SET',
}
export type UserPersonality = {
  calm: string;
  careful: string;
  competitive: string;
  conformity: string;
  dominance: string;
  enthusiastic: string;
  influence: string;
  influencer: string;
  listener: string;
  objective: string;
  optimist: string;
  organized: string;
  patient: string;
  personal: string;
  persuasive: string;
  planner: string;
  precision: string;
  radical: string;
  selfish: string;
  selfsteem: string;
  spontaneous: string;
  stability: string;
  trustworthy: string;
  visionary: string;
};

export type UserPersonalityReducer = Reducer<
  UserPersonality,
  Action<ActionTypes>
>;
