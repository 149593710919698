import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_PROJECT_BRIEF_FLOW = '@projectBriefFlow/SET',
}

export type BriefFlow = any[];

export type BriefFlowReducer = Reducer<BriefFlow, Action<ActionTypes>>;
