/* eslint-disable react/jsx-filename-extension */
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ProviderDefaultProps } from 'AppV2/interfaces/providerDefaultProps';
import {
  AlertProps,
  AlertContextData,
  SetDefaultAlertWarningProps,
} from './types';
import { translateText } from 'AppV2/common/translateText';
import { Alert } from 'AppV2/components/Alert';
import { ResponseError } from 'AppV2/interfaces/responseError';

const AlertContext = createContext<AlertContextData>({} as AlertContextData);
const useAlert = () => useContext(AlertContext);

const AlertProvider = ({ children }: ProviderDefaultProps) => {
  // STATES
  const [openAlert, setOpenAlert] = useState({
    shouldOpen: false,
  } as AlertProps);

  const isAlertClosed = useMemo(() => !openAlert?.shouldOpen, [openAlert]);

  // FUNCTIONS
  const closeAlert = useCallback(
    () =>
      !isAlertClosed &&
      setOpenAlert({ shouldOpen: null, description: null, type: null }),
    [isAlertClosed],
  );

  const setDefaultAlertError = useCallback(
    (error: Error | ResponseError, onlyErrorMessage = false) => {
      const errorMessage = error?.message || '';
      const description = onlyErrorMessage
        ? errorMessage
        : `${translateText(
            'ALERT:GENERAL_ERROR_DESCRIPTION',
          )} \n\n${errorMessage}`;

      setOpenAlert({
        type: 'error',
        shouldOpen: true,
        title: translateText('ALERT:GENERAL_ERROR_TITLE'),
        description: description,
      });
    },
    [],
  );

  const setDefaultAlertWarning = useCallback(
    ({ ...props }: SetDefaultAlertWarningProps) => {
      const description = props?.customMessage || props?.errorMessage;

      setOpenAlert({
        type: 'warning',
        shouldOpen: true,
        title: props.title || translateText('ALERT:GENERAL_ERROR_TITLE'),
        description: description,
      });
    },
    [],
  );

  const EXPORTED_VALUES = {
    openAlert,
    isAlertClosed,
    setOpenAlert,
    closeAlert,
    setDefaultAlertError,
    setDefaultAlertWarning,
  };

  return (
    <AlertContext.Provider value={EXPORTED_VALUES}>
      {openAlert.shouldOpen && (
        <Alert
          alertType={openAlert?.type}
          icon={openAlert?.icon}
          title={openAlert?.title}
          description={openAlert?.description}
          time={openAlert?.time}
          onClose={() => setOpenAlert({ shouldOpen: false })}
        />
      )}

      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider, useAlert };
