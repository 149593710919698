/* eslint-disable react/jsx-filename-extension */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import InputIconLeft from 'Components/Atoms/Generals/InputIconLeft';
import Button from 'Components/Atoms/Generals/Button';
import { loginFormTexts } from 'Texts/LoginSignUp';
import Spinner from 'Components/Atoms/Generals/Spinner';
import { regexPattern } from 'Common/regexPatterns';
import { useAuth } from 'Contexts/AuthContext';

const LoginForm = () => {
  const history = useHistory();
  const { browserLanguage } = useSelector(state => state);
  const { register, handleSubmit } = useForm();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { emailLogin, isFirstSocialLogin, setIsLoginError } = useAuth();

  const { emailInput, passwordInput, aForgotPassword, btn } = loginFormTexts;
  const regexValidEmail = regexPattern('email');

  const [isTypePassword, setIsPassword] = useState(true);

  const handleLogin = async data => {
    setIsLoadingButton(true);
    setIsLoginError(false);
    const isLoginSuccess = await emailLogin(data.email, data.password);
    if (!isLoginSuccess) {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (isFirstSocialLogin) {
      history.push('/signup');
    }
  }, [isFirstSocialLogin, history]);

  return (
    <form onSubmit={handleSubmit(handleLogin)} id="login-form">
      <InputIconLeft
        thisIconClass={'icon-material-baseline-mail-outline'}
        thisName={'email'}
        thisType={'email'}
        thisId={'emailaddress'}
        thisPlaceholder={emailInput[browserLanguage]}
        thisPattern={regexValidEmail}
        thisRef={register}
        thisTestId="testid-email-address"
      ></InputIconLeft>

      <InputIconLeft
        isPasswordInput
        thisIconClass={'icon-material-outline-lock'}
        thisEyeIcon={isTypePassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
        thisSetEyeIcon={() => setIsPassword(!isTypePassword)}
        thisName={'password'}
        thisId={'password'}
        thisType={isTypePassword ? 'password' : 'text'}
        thisPlaceholder={passwordInput[browserLanguage]}
        thisRef={register}
        thisTestId="testid-password"
      ></InputIconLeft>

      <div className="forgot-password-content">
        <a className="forgot-password" href={'send-email-reset-password'}>
          {aForgotPassword[browserLanguage]}
        </a>
      </div>

      {isLoadingButton ? (
        <Spinner />
      ) : (
        <Button
          thisType={'submit'}
          thisText={btn[browserLanguage]}
          thisId="btn-login"
          thisDisabled={isLoadingButton}
          thisTestId="testid-submit-button"
        />
      )}
    </form>
  );
};

export default LoginForm;
