/* eslint-disable no-case-declarations */
import { ActionTypes, MemberMyCompanyDataReducer } from './types';

const memberMyCompanyDataReducer: MemberMyCompanyDataReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_MEMBER_MY_COMPANY_DATA:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default memberMyCompanyDataReducer;
