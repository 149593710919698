import { colors } from 'AppV2/styles/colors';
import styled, { css } from 'styled-components';
import { HeaderHeight } from '../components/Header/styles';

interface MainTemplateTypes {
  mainType?: 'primary' | 'secondary';
}

const BACKGROUNDS = {
  primary: `${colors.background200}`,
  secondary: `${colors.lightBackground}`,
};

export const DescriptionParagraph = styled.p`
  font-size: 0.875rem;
  margin-bottom: 4px;
  color: ${colors.text};
`;

export const MainTemplateContent = styled.main<MainTemplateTypes>`
  flex: 1;
  overflow: auto;
  min-height: ${`calc(100vh - ${HeaderHeight})`};
  max-height: ${`calc(100vh - ${HeaderHeight})`};

  margin-left: auto;
  margin-top: ${HeaderHeight};
  padding: 0px 3rem;
  /* padding-bottom: ${HeaderHeight}; */
  background: ${props => BACKGROUNDS[props.mainType || 'primary']};

  section {
    margin: 0 auto;
    width: 100%;
    max-width: 1150px;
    padding-top: 60px;
    h1 {
      font-size: 18px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 4px;
      color: ${colors.purple400};
    }
    .description-paragraph {
      font-size: 16px;
      margin-bottom: 3rem;
    }
    h2 {
      font-size: 18px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0px 2rem;
    padding-bottom: 32px;
    section {
      padding-top: 32px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
  }

  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      background-color: transparent !important;
      width: 0px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${colors.text} !important;
      border-style: solid !important;
      border-width: 0px !important;
      border-radius: 8px !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
    padding: 24px;
    padding-top: 0px;
    section {
      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
    ::-webkit-scrollbar {
      background-color: transparent;
      border: none;
      width: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: none;
      border-radius: 0px;
    }
  }
`;

export const MainTemplateBasicContainer = styled.main<{
  isLogoff?: boolean;
}>`
  flex: 1;
  background-color: ${colors.background200};
  max-width: 100%;
  max-height: calc(100vh - ${HeaderHeight});
  min-height: calc(100vh - ${HeaderHeight});
  margin-top: ${HeaderHeight};

  ${({ isLogoff }) =>
    isLogoff &&
    css`
      max-height: 100vh;
      margin-top: 0px;
    `}
`;

export const MainTemplateContainer = styled.main<{
  isLogoff?: boolean;
}>`
  flex: 1;
  background-color: ${colors.background200};
  max-width: 100%;
  max-height: calc(100vh - ${HeaderHeight});
  min-height: calc(100vh - ${HeaderHeight});
  max-height: calc(100dvh - ${HeaderHeight});
  min-height: calc(100dvh - ${HeaderHeight});
  margin-top: ${HeaderHeight};
  padding: 32px;
  padding-bottom: 0px;

  section {
    width: 100%;
    max-width: 1154px;
    margin: 0 auto;
    padding-bottom: 32px;
  }

  ${({ isLogoff }) =>
    isLogoff &&
    css`
      max-height: 100vh;
      margin-top: 0px;
    `}

  @media screen and (max-width: 480px) {
    padding: 16px;
    padding-bottom: 0px;
    section {
      padding-bottom: 16px;
    }
  }
`;

export const MainTemplateLoadingWrapper = styled.main`
  flex: 1;
  background-color: ${colors.background200};
  max-width: 100%;
  display: grid;
  place-items: center;
  height: 100vh;
  margin-top: 0px;
`;
