import { ActionTypes, UserDataReducer } from './types';

/* eslint-disable no-case-declarations */
const storagedUserData = localStorage.getItem('userData');

const userDataInit = {
  about: '',
  country: '',
  city: '',
  nativeLanguage: '',
  englishLevel: '',
  pages: {
    linkedin: '',
    behance: '',
    github: '',
    webPage: '',
  },
  dailyTime: [],
  newLanguagesList: [],
  newLanguagesListForModal: [],
  photoUrl: '',
};

const stateUserData = storagedUserData
  ? { ...userDataInit, ...JSON.parse(storagedUserData) }
  : userDataInit;

const userDataReducer: UserDataReducer = (state = stateUserData, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_DATA:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default userDataReducer;
