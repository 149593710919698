/* eslint-disable no-case-declarations */
import { ActionTypes, NavButtonReducer } from './types';

const navActionReducer: NavButtonReducer = (state = true, action) => {
  switch (action.type) {
    case ActionTypes.SET_NAV_ACTION:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default navActionReducer;
