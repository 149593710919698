import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_TESTS_COMPLETED = '@testsCompleted/SET',
}
export type TestsCompleted = any[];

export type TestsCompletedReducer = Reducer<
  TestsCompleted,
  Action<ActionTypes>
>;
