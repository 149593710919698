import { ThunkActionReturn } from 'Store/types';
import { setAdminData } from './action';
import { AdminData } from './types';

export const setAdminDataThunk = (adminData: AdminData): ThunkActionReturn => {
  return dispatch => {
    if (adminData) {
      dispatch(setAdminData(adminData));
    }
  };
};
