/* eslint-disable no-case-declarations */
import { ActionTypes, SearchMatchReducer } from './types';

const itsMatchReducer: SearchMatchReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_ITS_MATCH:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default itsMatchReducer;
