/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { memo } from 'react';

import {
  Link,
  LinkIconContent,
  LogoContent,
  MenuContent,
  OpenCloseMenuButton,
  ULinksList,
} from '../style';

import { translateText } from 'AppV2/common/translateText';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';

import NavBarHoverIcon from 'AppV2/assets/icons/NavBarHover';
import ArrowLeftSolidIcon from 'AppV2/assets/icons/ArrowLeftSolid';
import { Logo } from 'AppV2/components/Logo';
import { MenuNavBarProps } from '../../MenuNavBar/index';
import { Tooltip } from 'AppV2/components/MenuTooltip';
import { MEMBER_LINKS } from '../userRoleLinks';
import { useAuth } from 'Contexts/AuthContext';
import { ASSESSMENT_MEMBER_PATHS } from 'AppV2/assets/allUserRoleRoutes';

export const MemberViewMenuNavBar = memo(
  ({
    appVersionStyle,
    isMenuOpen,
    openTooltip,
    handleOpenTooltip,
    handleOpenCloseMenu,
    handleRedirect,
    isLinkSelected,
    openCloseMenuTooltipText,
  }: MenuNavBarProps) => {
    const { currentUser } = useAuth();
    const isAssessmentUser = !!currentUser?.claims?.assessmentUser;
    let LINKS = MEMBER_LINKS;

    if (isAssessmentUser) {
      LINKS = LINKS.filter(link => ASSESSMENT_MEMBER_PATHS.includes(link.path));
    }

    return (
      <MenuContent appVersionStyle={appVersionStyle} isOpen={isMenuOpen}>
        <OpenCloseMenuButton
          onClick={handleOpenCloseMenu}
          onMouseOver={() =>
            !openTooltip && handleOpenTooltip('openCloseButton')
          }
          onMouseLeave={() => openTooltip && handleOpenTooltip('')}
        >
          <ArrowLeftSolidIcon />

          <Tooltip
            isOpen={openTooltip === 'openCloseButton'}
            text={openCloseMenuTooltipText}
            positionX={isMenuOpen ? 70 : 67}
            initialPositionX={32}
          />
        </OpenCloseMenuButton>

        <LogoContent isOpen={isMenuOpen}>
          <Logo onlyIcon={!isMenuOpen} />
        </LogoContent>

        <ULinksList isMenuOpen={isMenuOpen}>
          {LINKS.map(link => (
            <Link
              key={link.path}
              isMenuOpen={isMenuOpen}
              isSelected={isLinkSelected(link.path)}
              onClick={() =>
                handleRedirect(link.path, link.text, UserRoleEnum.squadMember)
              }
              onMouseEnter={() => !isMenuOpen && handleOpenTooltip(link.path)}
              onMouseLeave={() => !isMenuOpen && handleOpenTooltip('')}
            >
              <LinkIconContent
                isSelected={isLinkSelected(link.path)}
                iconSize={link?.iconSize}
              >
                {link.icon}
              </LinkIconContent>
              <span className="li-span">
                <>{translateText(link.text)}</>
              </span>
              <div className="nav-bar-hover-icon flexbox-start">
                <NavBarHoverIcon />
              </div>

              {!isMenuOpen && (
                <Tooltip
                  isOpen={openTooltip === link.path}
                  text={translateText(link.text)}
                />
              )}
            </Link>
          ))}
        </ULinksList>
      </MenuContent>
    );
  },
);
