/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
import { memo } from 'react';
import primaryLogoSVG from 'AppV2/assets/images/logo-Growyx.svg';
import secondaryLogoSVG from 'AppV2/assets/images/logo-Growyx-whiteText.svg';
import tertiaryLogoSVG from 'AppV2/assets/images/logo-Growyx-white.svg';
import jobsLogoSVG from 'AppV2/assets/images/logo-jobs.svg';
import workspaceGrowyxLogoSVG from 'AppV2/assets/images/logo-Growyx-workspace.svg';
import workspaceGrowyxIconSVG from 'AppV2/assets/images/icon-Growyx-workspace.svg';
import logoV2 from 'AppV2/assets/images/logo-growyx-v2.svg';
import logoNew from 'AppV2/assets/images/logo-Growyx-new.svg';
import iconGrowyxSVG from 'AppV2/assets/images/icon-Growyx.svg';
import { LogoContent } from './styles';
import { useTemplatesConfig } from 'AppV2/hooks/TemplatesConfig';
import { TemplatesKeysEnum } from 'AppV2/hooks/TemplatesConfig/types';

interface Props {
  v2?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  jobsVariant?: boolean;
  onlyIcon?: boolean;
  isWorkspaceSelected?: boolean;
  width?: string;
  new?: boolean;
}

export const Logo = memo(({ ...props }: Props) => {
  const { templateKey, logos } = useTemplatesConfig();
  const isDefaultTemplate = templateKey === TemplatesKeysEnum.GROWYX;
  let thisSrc = primaryLogoSVG;

  if (props.v2) {
    thisSrc = logoV2;
  }
  if (props.secondary) {
    thisSrc = secondaryLogoSVG;
  }
  if (props.tertiary) {
    thisSrc = tertiaryLogoSVG;
  }
  if (props.jobsVariant) {
    thisSrc = jobsLogoSVG;
  }
  if (props.onlyIcon) {
    thisSrc = iconGrowyxSVG;
  }
  if (props.isWorkspaceSelected) {
    thisSrc = workspaceGrowyxLogoSVG;
  }
  if (props.isWorkspaceSelected && props.onlyIcon) {
    thisSrc = workspaceGrowyxIconSVG;
  }
  if (props.new) {
    thisSrc = logoNew;
  }
  if (!isDefaultTemplate) {
    thisSrc = logos[templateKey].logo;
  }
  if (!isDefaultTemplate && props.onlyIcon) {
    thisSrc = logos[templateKey].icon;
  }

  return (
    <LogoContent
      width={props.width}
      isWorkspaceSelected={props.isWorkspaceSelected && !props.onlyIcon}
      isOnlyIcon={props.onlyIcon}
      className="logo-content cubicBezierAnimation"
    >
      <img src={thisSrc} alt={'logo'} />
    </LogoContent>
  );
});
