import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import accountTypeReducer from './modules/loginSignUpForms/accountType/reducer';
import loginOrSignupFormReducer from './modules/loginSignUpForms/changeForm/reducer';
import browserLanguageReducer from './modules/browserLanguage/reducer';
import newLanguagesReducer from './modules/fillMyProfile/newLanguages/reducer';
import experiencesReducer from './modules/fillProfessionalExperience/reducer';
import fillProfileFormsIndexReducer from './modules/fillProfile/formsIndex/reducer';
import fillProfileFormCompletedReducer from './modules/fillProfile/aside/reducer';
import educationsReducer from './modules/fillEducation/reducer';
import skillsReducer from './modules/fillSkills/skills/reducer';
import softwaresReducer from './modules/fillSkills/softwares/reducer';
import methodologiesReducer from './modules/fillSkills/methodologies/reducer';
import languagesReducer from './modules/fillLanguages/languages/reducer';
import databasesReducer from './modules/fillLanguages/databases/reducer';
import profileLevelReducer from './modules/fillProfile/profileLevel/reducer';
import userDataReducer from './modules/fillMyProfile/userData/reducer';
import fieldsFilledReducer from './modules/fillProfile/fieldsFilled/reducer';
import questionIndexReducer from './modules/tests/questionsIndex/reducer';
import questionsListReducer from './modules/tests/questions/reducer';
import testsCompletedReducer from './modules/tests/select/reducer';
import modalResultsReducer from './modules/tests/modalResults/reducer';
import loadingPageReducer from './modules/loadingPage/reducer';
import redirectPathReducer from './modules/loginSignUpForms/redirect/reducer';
import skipFormReducer from './modules/skipForms/reducer';
import userValuesReducer from './modules/fillValues/reducer';
import modalFormReducer from './modules/squadMemberProfile/modalForms/reducer';
import navActionReducer from './modules/squadMemberProfile/mobileNavButton/reducer';
import mobileFormReducer from './modules/squadMemberProfile/mobileForms/reducer';
import userSoftSkillsReducer from './modules/tests/userStatus/softSkills/reducer';
import userPersonalityReducer from './modules/tests/userStatus/personality/reducer';
import userCultureCompanyReducer from './modules/tests/userStatus/companyCulture/reducer';
import {
  profilePictureReducer,
  reloadProfilePictureReducer,
} from './modules/profilePicture/reducer';
import allSkillsReducer from './modules/squadMemberProfile/allSkills/reducer';
import userIsAvailableReducer from './modules/squadMemberProfile/userIsAvailable/reducer';
import popUpErrorReducer from './modules/popUpError/reducer';
import userAboutReducer from './modules/squadMemberProfile/userAbout/reducer';
import squadOwnerDataReducer from './modules/squadOwner/register/reducer';
import projectBriefFlowReducer from './modules/projectBrief/briefFlow/reducer';
import projectBriefFormAnswerReducer from './modules/projectBrief/briefAnswer/reducer';
import toolTipReducer from './modules/toolTip/reducer';
import briefForReducer from './modules/projectBrief/briefFor/reducer';
import onboardingIndexReducer from './modules/projectBrief/onboarding/reducer';
import cardRadioModalReducer from './modules/projectBrief/modals/cardsRadio/reducer';
import cultureFormModalReducer from './modules/projectBrief/modals/cultureCards/reducer';
import itsMatchReducer from './modules/projectBrief/searchMatch/reducer';
import scorePerfilReducer from './modules/squadMemberProfile/scorePerfil/reducer';
import dragSideModalReducer from './modules/projectBrief/modals/dragSide/reducer';
import ownerDataReducer from './modules/squadOwner/profile/reducer';
import memberDataReducer from './modules/squadMemberProfile/memberOpenViewData/reducer';
import inEditProjectDescriptionReducer from './modules/projectBrief/inEditProjectDescription/reducer';
import backButtonTestToProfilePageReducer from './modules/tests/backButton/reducer';
import ownerEditReducer from './modules/squadOwner/editModal/reducer';
import cmsProfessionalsReducer from './modules/CMS/professionals/reducer';
import cmsSquadSelectionReducer from './modules/CMS/squad-selection/reducer';
import cmsProjectsReducer from './modules/CMS/projects/reducer';
import adminReducer from './modules/admin/adminData/reducer';
import jobSpecificationsModalReducer from './modules/projectBrief/modals/jobSpecifications/reducer';
import modalReducer from './modules/modal/reducer';
import grossNetMonthValuesReducer from './modules/budgetInHouse/grossNetMonthValues/reducer';
import memberMyCompanyDataReducer from './modules/squadMemberProfile/myCompanyData/reducer';
import scorePerfilCategoryReducer from './modules/squadMemberProfile/scorePerfilCategory/reducer';
import projectBriefIsEndFormReducer from './modules/projectBrief/isEndForm/reducer';
import currentPageReducer from './modules/opportunities/currentpage/reducer';
import isUpsellFinishReducer from './modules/projectBrief/upsell/reducer';
import alertReducer from './modules/alert/reducer';
import myCompanyReducer from './modules/fillMyCompany/reducer';
import userMotivatorsReducer from './modules/tests/userStatus/movingMotivators/reducer';

const reducers = combineReducers({
  accountType: accountTypeReducer,
  isLoginForm: loginOrSignupFormReducer,
  browserLanguage: browserLanguageReducer,
  newLanguages: newLanguagesReducer,
  experiences: experiencesReducer,
  formIndex: fillProfileFormsIndexReducer,
  formsCompletedIndex: fillProfileFormCompletedReducer,
  educations: educationsReducer,
  skills: skillsReducer,
  softwares: softwaresReducer,
  methodologies: methodologiesReducer,
  languages: languagesReducer,
  databases: databasesReducer,
  profileLevel: profileLevelReducer,
  userData: userDataReducer,
  fieldsFilled: fieldsFilledReducer,
  questionIndex: questionIndexReducer,
  questionsList: questionsListReducer,
  testsCompleted: testsCompletedReducer,
  modalResults: modalResultsReducer,
  loadingPage: loadingPageReducer,
  redirectPath: redirectPathReducer,
  isSkipForm: skipFormReducer,
  userValues: userValuesReducer,
  modalForm: modalFormReducer,
  navAction: navActionReducer,
  mobileForm: mobileFormReducer,
  userSoftSkillsType: userSoftSkillsReducer,
  userPersonalityType: userPersonalityReducer,
  userCultureCompanyType: userCultureCompanyReducer,
  profilePicture: profilePictureReducer,
  reloadPicture: reloadProfilePictureReducer,
  allSkills: allSkillsReducer,
  userIsAvailable: userIsAvailableReducer,
  popUpError: popUpErrorReducer,
  userAbout: userAboutReducer,
  squadOwnerData: squadOwnerDataReducer,
  briefFlow: projectBriefFlowReducer,
  briefAnswer: projectBriefFormAnswerReducer,
  isToolTip: toolTipReducer,
  briefFor: briefForReducer,
  onboardingIndex: onboardingIndexReducer,
  cardRadioModal: cardRadioModalReducer,
  cultureFormModal: cultureFormModalReducer,
  itsMatch: itsMatchReducer,
  scorePerfil: scorePerfilReducer,
  scorePerfilCategory: scorePerfilCategoryReducer,
  dragSideModal: dragSideModalReducer,
  ownerData: ownerDataReducer,
  memberData: memberDataReducer,
  inEditProjectDescription: inEditProjectDescriptionReducer,
  backButtonTestToProfilePage: backButtonTestToProfilePageReducer,
  ownerEdit: ownerEditReducer,
  CMSProfessional: cmsProfessionalsReducer,
  CMSSquadSelection: cmsSquadSelectionReducer,
  CMSProjects: cmsProjectsReducer,
  adminData: adminReducer,
  jobSpecificationsModal: jobSpecificationsModalReducer,
  modal: modalReducer,
  grossNetMonthValues: grossNetMonthValuesReducer,
  memberMyCompanyData: memberMyCompanyDataReducer,
  isEndForm: projectBriefIsEndFormReducer,
  currentPage: currentPageReducer,
  isUpsellFinish: isUpsellFinishReducer,
  openAlert: alertReducer,
  myCompanyData: myCompanyReducer,
  userMotivatorsReducer: userMotivatorsReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
