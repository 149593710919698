/* eslint-disable react/jsx-filename-extension */
import { Fragment, ReactElement } from 'react';
import Routes from 'Routes';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { APPV2GlobalStyles } from 'AppV2/styles/global';
import { GlobalStyles } from 'Containers';
import { Head } from 'AppV2/templates/components/Head';
import { Header } from 'AppV2/templates/components/Header';
import { MenuNavBar } from 'AppV2/templates/components/MenuNavBar';
import { MainTemplateLoadingWrapper } from 'AppV2/templates/Main/style';
import { LoadingPage } from 'AppV2/components/LoadingPage';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';
import { useAppHelper } from 'AppV2/hooks/AppHelper';

const App = (): ReactElement => {
  const {
    isFetching,
    isAppV2Paths,
    templatesConfig,
    appFragmentKey,
    shouldShowHeader,
    shouldShowNavBar,
    defaultAxiosHeaders,
    isCurrentUserInAnotherWorkspace,
    isMobile,
  } = useAppHelper();

  if (isFetching) {
    return (
      <MainTemplateLoadingWrapper>
        <LoadingPage.Lazy />
      </MainTemplateLoadingWrapper>
    );
  }

  return (
    <Fragment key={appFragmentKey}>
      <Head templatesConfig={templatesConfig} />

      {shouldShowHeader && (
        <Header
          userRole={defaultAxiosHeaders?.userRole}
          headersWorkspaceId={defaultAxiosHeaders?.workspaceId}
          isAnotherWorkspaceSelected={isCurrentUserInAnotherWorkspace}
          shouldShowLogo={isMobile || !shouldShowNavBar}
        />
      )}

      {shouldShowNavBar && (
        <MenuNavBar
          userRole={defaultAxiosHeaders?.userRole as UserRoleEnum}
          isAnotherWorkspaceSelected={isCurrentUserInAnotherWorkspace}
          appVersionStyle={isAppV2Paths ? 'V2' : 'V1'}
        />
      )}

      {isAppV2Paths ? (
        <APPV2GlobalStyles />
      ) : (
        <GlobalStyles templateKey={templatesConfig.templateKey} />
      )}
      <ThemeProvider theme={theme}>
        <Routes
          userRole={defaultAxiosHeaders?.userRole}
          workspaceId={defaultAxiosHeaders?.workspaceId}
        />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
