export const changeFormsTexts = {
  login: {
    'pt-BR': 'Logar',
    'en-US': 'Login',
  },

  signup: {
    'pt-BR': 'Registrar',
    'en-US': 'Sign Up',
  },
};

export const accountTypeTexts = {
  freelancer: {
    'pt-BR': 'Profissional',
    'en-US': 'Professional',
  },
  employer: {
    'pt-BR': 'Empregador',
    'en-US': 'Employer',
  },
};

export const welcomeTexts = {
  login: {
    h3: {
      'pt-BR': 'Estamos felizes em vê-lo novamente!',
      'en-US': "We're glad to see you again!",
    },
    span: {
      'pt-BR': 'Não tem uma conta?',
      'en-US': "Don't have an account?",
    },
    a: {
      'pt-BR': 'Registrar',
      'en-US': 'Sign Up',
    },
  },

  signup: {
    h3: {
      'pt-BR': 'Vamos criar sua conta!',
      'en-US': "Let's create your account!",
    },
    span: {
      'pt-BR': 'Já tem uma conta?',
      'en-US': 'Already have an account?',
    },
    a: {
      'pt-BR': 'Logar',
      'en-US': 'Login',
    },
  },
};

export const loginButton = {
  'pt-BR': 'Continuar',
  'en-US': 'Continue',
};

export const loginFormTexts = {
  emailInput: {
    'pt-BR': 'Endereço de Email',
    'en-US': 'Email Address',
  },
  passwordInput: {
    'pt-BR': 'Senha',
    'en-US': 'Password',
  },

  aForgotPassword: {
    'pt-BR': 'Esqueceu a senha?',
    'en-US': 'Forgot Password?',
  },
  btn: {
    'pt-BR': 'Logar',
    'en-US': 'Login',
  },

  errorMessage: {
    email: {
      'pt-BR': 'Email não cadastrado, registre-se agora',
      'en-US': 'Email not registered, register now',
    },
    password: {
      'pt-BR': 'Senha inválida, por favor insira novamente',
      'en-US': 'Invalid password, please re-enter',
    },
  },

  forgotPasswordMessage: {
    'pt-BR': 'Um email para redefinição da senha foi enviado para você!',
    'en-US': 'A password reset email has been sent to you!',
  },
};

export const signupFormTexts = {
  emailInput: {
    'pt-BR': 'Endereço de Email',
    'en-US': 'Email Address',
  },

  passwordInput: {
    'pt-BR': 'Senha',
    'en-US': 'Password',
  },
  passwordValidityMessage: {
    'pt-BR':
      'A senha deve conter no mínimo 6 caracteres sendo pelo menos uma letra maiúscula, uma minúscula e um número',
    'en-US': 'The password must contain at least 6 characters with at least one uppercase letter, one lowercase letter and one number',
  },

  confirmPasswordInput: {
    'pt-BR': 'Confirmar Senha',
    'en-US': 'Confirm Password',
  },
  confirmPasswordValidityMessage: {
    'pt-BR': 'Senhas Diferentes!',
    'en-US': 'Different Passwords!',
  },

  btn: {
    'pt-BR': 'Registrar',
    'en-US': 'Register',
  },

  errorMessage: {
    'pt-BR': 'Email já cadastrado, faça o login agora',
    'en-US': 'Email already registered',
  },

  useTerms: {
    p: {
      'pt-BR': 'Ao criar sua conta você concorda com os nossos',
      'en-US': 'By creating your account you agree with our',
    },
    registerStrong: {
      'pt-BR': 'Registrar',
      'en-US': 'Register',
    },
    a1: {
      'pt-BR': ' Termos de Uso ',
      'en-US': ' Terms of use ',
    },
    and: {
      'pt-BR': 'e',
      'en-US': 'and',
    },
    a2: {
      'pt-BR': ' Política de Privacidade',
      'en-US': ' Privacy Policy',
    },
    alertText: {
      'pt-BR': 'Primeiro leia nossos Termos de Uso e Política de Privacidade, se for de acordo aceite para continuar',
      'en-US': 'First read our Terms of Use and Privacy Policy, if you agree, accept to continue',
    }
  },
};

export const socialButtonsTexts = {
  loginButtons: {
    facebook: {
      'pt-BR': ' Logar via Facebook',
      'en-US': ' Login via Facebook',
    },
    google: {
      'pt-BR': ' Logar via Google+',
      'en-US': ' Login via Google+',
    },
  },

  registerButtons: {
    facebook: {
      'pt-BR': ' Registrar via Facebook',
      'en-US': ' Sign up via Facebook',
    },
    google: {
      'pt-BR': ' Registrar via Google+',
      'en-US': ' Sign up via Google+',
    },
  },

  spanOr: {
    'pt-BR': 'Ou',
    'en-US': 'Or',
  },
};
