/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function XIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.90937 22.137L11.9028 15.7847L16.8617 22.1334C17.146 22.4973 17.582 22.71 18.0438 22.71H21.7176C22.9795 22.71 23.6774 21.2468 22.8834 20.2661L15.5956 11.2654L22.3038 2.94121C23.0946 1.95993 22.3961 0.5 21.1358 0.5H17.542C17.0783 0.5 16.6407 0.714427 16.3566 1.08085L11.907 6.81931L7.45739 1.08085C7.17327 0.714427 6.73566 0.5 6.272 0.5H2.64809C1.38475 0.5 0.687253 1.9662 1.48431 2.94637L8.25219 11.269L0.938227 20.2637C0.141202 21.2438 0.838702 22.71 2.10203 22.71H5.7301C6.19028 22.71 6.62499 22.4988 6.90937 22.137Z"
        fill={iconColor}
        stroke="#4125C2"
      />
    </svg>
  );
}
