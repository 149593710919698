/* eslint-disable no-case-declarations */
import { ActionTypes, BriefForReducer } from './types';

const briefForInit = {
  answerIndex: 0,
  userCount: 0,
};

const briefForReducer: BriefForReducer = (state = briefForInit, action) => {
  switch (action.type) {
    case ActionTypes.SET_BRIEF_FOR:
      const { payload } = action;
      return payload;

    default:
      return state;
  }
};

export default briefForReducer;
