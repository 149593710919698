import GrowyxFavIcon from './Growyx.ico';
import GrowyxAppleFavIcon from './Growyx192.png';
import Hunters99FavIcon from './99HuntersFavIcon.svg';
import { TemplatesKeysEnum } from '../types';

export const useFavIcons = () => {
  return {
    [TemplatesKeysEnum.GROWYX]: {
      favIcon: GrowyxFavIcon,
      appleTouch: GrowyxAppleFavIcon,
    },
    [TemplatesKeysEnum.HUNTERS_99]: {
      favIcon: Hunters99FavIcon,
      appleTouch: Hunters99FavIcon,
    },
  };
};
