import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_SKILLS = '@skills/ADD',
  SET_SKILLS = '@skills/SET',
  REMOVE_SKILLS = '@skills/REMOVE',
  RESET_SKILLS = '@skills/RESET',
}

export type Skills = any[];

export type SkillsExpertise = {
  tag: string;
  expertise: number;
}[];

export type SkillsReducer = Reducer<Skills, Action<ActionTypes>>;
