import { ActionTypes, SkillsReducer } from './types';

const storageSkills = localStorage.getItem('userSkills');

const userSkillsInit: any[] = [];

const userSkills = storageSkills ? JSON.parse(storageSkills) : userSkillsInit;

const skillsReducer: SkillsReducer = (state = userSkills, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SKILLS:
      return [...state, action.payload];

    case ActionTypes.SET_SKILLS:
      return action.payload;

    case ActionTypes.REMOVE_SKILLS:
      return action.payload;

    case ActionTypes.RESET_SKILLS:
      return action.payload;

    default:
      return state;
  }
};

export default skillsReducer;
