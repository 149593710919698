/* eslint-disable camelcase */
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import {
  JobTitleTypesEnum,
  JobTitlesArray,
  PontoItemsObject,
  ProfessionsGroupQueryCacheKey,
  ProfessionsGroupResponseData,
  ProfessionsGroupQuery,
} from '../../services/ProfessionsGroup/GetProfessionsGroup/types';
import { professionsGroupServices } from 'AppV2/services/ProfessionsGroup';

export const useProfessionsGroup = (query = {} as ProfessionsGroupQuery) => {
  const getProfessionsGroup = useCallback(async () => {
    const response = await professionsGroupServices.getProfessionsGroup(query);
    return response?.data;
  }, [query]);

  const { data, error, isFetched, isFetching } = useQuery<
    ProfessionsGroupResponseData | JobTitlesArray | PontoItemsObject,
    Error
  >(ProfessionsGroupQueryCacheKey, getProfessionsGroup);

  const getJobTitles = useCallback((): string[] => {
    try {
      return Object.keys(data);
    } catch (error) {
      console.log('[useProfessionsGroup][getJobTitles][ERROR]', error);
      return [];
    }
  }, [data]);

  const getSkillTagsByJobTitle = useCallback(
    jobTitle => {
      if (data[jobTitle] && data[jobTitle] !== undefined) {
        return data[jobTitle].skill_tags;
      }
    },
    [data],
  );

  const getTechProductJobTitles = useCallback((): string[] => {
    try {
      return Object.keys(data).filter(jobTitle =>
        [JobTitleTypesEnum.techProduct, JobTitleTypesEnum.generic].includes(
          data[jobTitle].type,
        ),
      );
    } catch (error) {
      console.log(
        '[useProfessionsGroup][getTechProductJobTitles][ERROR]',
        error,
      );
      return [];
    }
  }, [data]);

  const getTechProduct = useCallback((): ProfessionsGroupResponseData => {
    try {
      const techProduct = {};

      Object.keys(data).forEach(jobTitle => {
        const isTechProductOrGeneric = [
          JobTitleTypesEnum.techProduct,
          JobTitleTypesEnum.generic,
        ].includes(data[jobTitle].type);

        if (isTechProductOrGeneric) {
          techProduct[jobTitle] = data[jobTitle];
        }
      });

      return techProduct;
    } catch (error) {
      console.log('[useProfessionsGroup][getTechProduct][ERROR]', error);
      return {};
    }
  }, [data]);

  const getMarketingDesignJobTitles = useCallback((): string[] => {
    try {
      return Object.keys(data).filter(jobTitle =>
        [JobTitleTypesEnum.marketingDesign, JobTitleTypesEnum.generic].includes(
          data[jobTitle].type,
        ),
      );
    } catch (error) {
      console.log(
        '[useProfessionsGroup][getMarketingDesignJobTitles][ERROR]',
        error,
      );
      return [];
    }
  }, [data]);

  const getMarketingDesign = useCallback((): ProfessionsGroupResponseData => {
    try {
      const marketingDesign = {};

      Object.keys(data).forEach(jobTitle => {
        const isMarketingOrGeneric = [
          JobTitleTypesEnum.marketingDesign,
          JobTitleTypesEnum.generic,
        ].includes(data[jobTitle].type);

        if (isMarketingOrGeneric) {
          marketingDesign[jobTitle] = data[jobTitle];
        }
      });

      return marketingDesign;
    } catch (error) {
      console.log('[useProfessionsGroup][getMarketingDesign][ERROR]', error);
      return {};
    }
  }, [data]);

  return {
    professionsGroup: data,
    getJobTitles,
    getTechProductJobTitles,
    getTechProduct,
    getMarketingDesignJobTitles,
    getMarketingDesign,
    error,
    isFetched,
    isFetching,
    getSkillTagsByJobTitle,
  };
};
