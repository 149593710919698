/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode } from 'react';
import * as S from './styles';
import Modal from 'react-modal';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import CloseIcon from 'AppV2/assets/icons/Close';
import { CSSObject } from 'styled-components';

Modal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  styles?: CSSObject;
}

export const ModalMobile = ({
  isOpen,
  onClose,
  children,
  styles,
}: Props): ReactElement => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          isOpen
          style={S.ModalCustomStyles}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
        >
          <S.ModalContainer
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 1000 }}
            transition={{
              type: 'just',
            }}
            styles={styles}
          >
            <button className="button-close" onClick={onClose}>
              <CloseIcon />
            </button>

            {children}
          </S.ModalContainer>
        </Modal>
      )}
    </AnimatePresence>
  );
};
