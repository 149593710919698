/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function TicketIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 10.0384C22 10.4272 21.675 10.7428 21.2745 10.7428C20.5616 10.7428 19.9812 11.3073 19.9812 11.9995C19.9812 12.6918 20.5616 13.2553 21.2745 13.2553C21.467 13.2553 21.6518 13.3295 21.7872 13.4619C21.9236 13.5934 22 13.7738 22 13.9597L21.999 16.3257C21.999 18.3526 20.3014 20 18.214 20H5.78603C3.69956 20 2.00193 18.3526 2.00193 16.3257L2 13.8837C2 13.6977 2.07642 13.5173 2.21281 13.3849C2.3492 13.2534 2.53299 13.1792 2.72548 13.1792C3.46257 13.1792 4.01877 12.672 4.01877 11.9995C4.01877 11.3073 3.43935 10.7428 2.72548 10.7428C2.32501 10.7428 2 10.4272 2 10.0384V7.67432C2 5.64837 3.69762 4 5.7841 4H7.00967C7.41014 4 7.73515 4.31559 7.73515 4.70443C7.73515 5.09328 7.41014 5.40886 7.00967 5.40886H5.7841C4.49758 5.40886 3.45096 6.42512 3.45096 7.67432V9.42882C4.61366 9.73877 5.46972 10.7729 5.46972 11.9995C5.46972 13.213 4.6272 14.2068 3.45192 14.4998L3.45289 16.3257C3.45289 17.5749 4.49952 18.5911 5.78603 18.5911H13.0273V17.3936C13.0273 17.0048 13.3523 16.6892 13.7528 16.6892C14.1532 16.6892 14.4782 17.0048 14.4782 17.3936V18.5911H18.214C19.5015 18.5911 20.5481 17.5749 20.5481 16.3257L20.549 14.5693C19.3863 14.2593 18.5303 13.2252 18.5303 11.9995C18.5303 10.7729 19.3863 9.73877 20.549 9.42882V7.67432C20.549 6.42512 19.5024 5.40886 18.2159 5.40886H14.4782V6.97834C14.4782 7.36719 14.1532 7.68277 13.7528 7.68277C13.3523 7.68277 13.0273 7.36719 13.0273 6.97834V5.40886H11.0636C10.6632 5.40886 10.3382 5.09328 10.3382 4.70443C10.3382 4.31559 10.6632 4 11.0636 4H18.2159C20.3024 4 22 5.64837 22 7.67432V10.0384ZM14.4783 14.1678C14.4783 14.5566 14.1533 14.8722 13.7529 14.8722C13.3524 14.8722 13.0274 14.5566 13.0274 14.1678V10.4108C13.0274 10.022 13.3524 9.70637 13.7529 9.70637C14.1533 9.70637 14.4783 10.022 14.4783 10.4108V14.1678Z"
        fill={iconColor}
      />
    </svg>
  );
}
