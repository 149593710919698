import styled, { css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

interface UserMenuProps {
  isWorkspaceSelected: boolean;
  isSquadOwner?: boolean;
}

export const UserMenuContainer = styled.div<UserMenuProps>`
  display: flex;
  align-items: center;
  position: relative;
  height: 46px;
  min-width: 176px;
  padding: 7px 21px 8px 8px;
  border: 1px solid ${colors.faded};
  border-top-right-radius: 73px;
  border-top-left-radius: 73px;
  border-bottom-right-radius: 73px;
  border-bottom-left-radius: 73px;

  ${({ isSquadOwner }) =>
    isSquadOwner &&
    css`
      cursor: pointer;
    `}

  .avatar-username-content {
    display: flex;
    align-items: center;
    gap: 7px;
    .workspace-name-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      .username {
        margin: 0px;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: ${colors.text};
        max-width: 145px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: 'Mulish', sans-serif !important;
      }
      .anotherworkspace-label {
        margin: 0px;
        font-size: 12px;
        line-height: 14px;
        color: ${colors.text};
        font-family: 'Mulish', sans-serif !important;
      }
    }
  }

  ${({ isWorkspaceSelected }) =>
    isWorkspaceSelected &&
    css`
      background-color: ${colors.purple50};
      .avatar-username-content {
        .workspace-name-content {
          flex-direction: column-reverse
        }
    `}

  @media screen and (max-width: 480px) {
    .avatar-username-content {
      .workspace-name-content {
        .username {
          max-width: 90px;
        }
      }
    }
  }
`;
