import Default from './variation/default';
import Secondary from './variation/secondary';
import Third from './variation/third';

type DefaultType = typeof Default;
type SecondaryType = typeof Secondary;
type ThirdType = typeof Third;

interface CompoundedComponent extends DefaultType {
  Secondary: SecondaryType;
  Third: ThirdType;
}

const UserIcon = Default as CompoundedComponent;
UserIcon.Secondary = Secondary;
UserIcon.Third = Third;

export default UserIcon;
