import { ActionTypes, LoginOrSignupFormReducer } from './types';

/* eslint-disable no-case-declarations */
const loginOrSignupFormReducer: LoginOrSignupFormReducer = (
  state = true,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_LOGIN_OR_SIGNUP_FORM:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default loginOrSignupFormReducer;
