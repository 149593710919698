import { ActionTypes, ModalReducer } from './types';

/* eslint-disable no-case-declarations */
const modalReducer: ModalReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL:
      return action.payload;

    default:
      return state;
  }
};

export default modalReducer;
