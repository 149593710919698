import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_EXPERIENCES = '@experiences/ADD',
  UPDATE_EXPERIENCES = '@experiences/UPDATE',
  REMOVE_EXPERIENCES = '@experiences/REMOVE',
  RESET_EXPERIENCES = '@experiences/RESET',
}

export type Experiences = any[];

export type ExperiencesReducer = Reducer<Experiences, Action<ActionTypes>>;
