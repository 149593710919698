import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { auth } from 'FirebaseFiles/Firebase';

const getIdToken = async () => {
  const idTokenRequest = auth.currentUser
    ? await auth.currentUser.getIdToken(true)
    : null;

  return idTokenRequest;
};

const setRefreshHeadersToken = async (
  config: AxiosRequestConfig,
  instance: AxiosInstance,
) => {
  const refreshToken = await getIdToken();

  config.headers.Authorization = refreshToken;
  instance.defaults.headers.Authorization = refreshToken;

  return config;
};

export { getIdToken, setRefreshHeadersToken };
