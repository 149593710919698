export interface PostSelfAdmissionRequestProps {
  projectId: string;
  positionIndex: string;
  upsellId?: string;
  replacementId?: string;
  matchmakingId?: string;
}

export type SelfAdmissionStorageActions =
  | 'setJobDetailsUrlForLogoffUsers'
  | 'setApplyDate'
  | 'getApplyDate'
  | 'removeStorages'
  | 'getJobDetailsUrl';

export enum SelfAdmissionStorageKeys {
  selfAdmissionJobDetailsUrl = 'selfAdmissionJobDetailsUrl',
  isLogOffUserSelfAdmission = 'isLogOffUserSelfAdmission',
  selfAdmissionDate = 'selfAdmissionDate',
}
