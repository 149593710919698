import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_VALUES = '@userValues/SET',
}

export type UserValues = {
  hourValue: string;
  hourReceive: string;
  monthValue: string;
  monthReceive: string;
  currency: string;
};

export type UserValuesReducer = Reducer<UserValues, Action<ActionTypes>>;
