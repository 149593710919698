import { ActionTypes, MethodologiesReducer } from './types';

const storagedMethodologies = localStorage.getItem('userMethodologies');

const userMethodologiesInit: any[] = [];

const userMethodologies = storagedMethodologies
  ? JSON.parse(storagedMethodologies)
  : userMethodologiesInit;

const methodologiesReducer: MethodologiesReducer = (
  state = userMethodologies,
  action,
) => {
  switch (action.type) {
    case ActionTypes.ADD_METHODOLOGIES:
      return [...state, action.payload];

    case ActionTypes.SET_METHODOLOGIES:
      return action.payload;

    case ActionTypes.REMOVE_METHODOLOGIES:
      return action.payload;

    case ActionTypes.RESET_METHODOLOGIES:
      return action.payload;

    default:
      return state;
  }
};

export default methodologiesReducer;
