/* eslint-disable no-case-declarations */
import { ActionTypes, UserCultureCompanyReducer } from './types';

const userCultureCompanyInit = {
  adhocracia: '',
  cla: '',
  companyCulture: '',
  hierarchy: '',
  market: '',
};

const userCultureCompanyReducer: UserCultureCompanyReducer = (
  state = userCultureCompanyInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_CULTURE_COMPANY_TYPE:
      const { payload } = action;
      return payload;

    default:
      return state;
  }
};

export default userCultureCompanyReducer;
