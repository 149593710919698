/* eslint-disable react/display-name */
import { ReactElement, useLayoutEffect } from 'react';
import * as S from '../styles';
import { CSSObject } from 'styled-components';
import { useTemplatesConfig } from 'AppV2/hooks/TemplatesConfig';

interface Props {
  styles?: CSSObject;
}

export const LazyLoadingPage = ({ ...props }: Props): ReactElement => {
  const { templateKey } = useTemplatesConfig();

  useLayoutEffect(() => {
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.style.overflow = 'hidden';
    }
  }, []);

  return (
    <S.LazyPageContainer styles={props.styles}>
      <S.BulletsWrapper {...S.MotionAnimation}>
        <S.BulletLoader bulletIndex={0} templateKey={templateKey} />
        <S.BulletLoader bulletIndex={1} templateKey={templateKey} />
        <S.BulletLoader bulletIndex={2} templateKey={templateKey} />
      </S.BulletsWrapper>
    </S.LazyPageContainer>
  );
};
