import { useCallback, useEffect, useState, useRef } from 'react';

export const useScreenDetect = (detectWidth: number | null = null) => {
  const detectTimerRef = useRef(null);

  const [isThisWidthDetected, setIsThisWidthDetected] = useState(
    window.outerWidth <= (detectWidth as number),
  );
  const [isMobile, setIsMobile] = useState(window.outerWidth <= 768);
  const isDesktop = !isMobile;

  const detectScreen = useCallback(() => {
    if (detectTimerRef.current) {
      clearTimeout(detectTimerRef.current);
    }

    detectTimerRef.current = setTimeout(() => {
      const isMobileScreen = window.outerWidth <= 768;
      setIsMobile(isMobileScreen);

      if (typeof detectWidth === 'number') {
        const isThisWidthDetected = window.outerWidth <= detectWidth;
        setIsThisWidthDetected(isThisWidthDetected);
      }
    }, 450);
  }, [detectWidth]);

  useEffect(() => {
    window.addEventListener('resize', detectScreen);

    return () => window.removeEventListener('resize', detectScreen);
  }, [detectScreen]);

  return {
    isMobile,
    isDesktop,
    isThisWidthDetected,
  };
};
