/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
import { HTMLAttributes, memo, ReactElement } from 'react';
import { ImgContainer } from './style';
import defaultAvatar from 'AppV2/assets/images/avatarMenu.svg';
import randomAvatar from 'Common/randomAvatarIcon';
import { colors } from 'AppV2/styles/colors';
import { CSSObject } from 'styled-components';

type SizeTypes = 'min' | 'small' | 'medium' | 'large';
type BorderTypes = 'default' | 'noBorder' | 'large';

interface Props extends HTMLAttributes<HTMLImageElement> {
  size?: SizeTypes;
  url?: string;
  border?: BorderTypes;
  borderColor?: string;
  isRandomAvatar?: boolean;
  width?: string;
  height?: string;
  isClickable?: boolean;
  grayscale?: boolean;
  getAvatarUrl?: (avatarUrl: string) => void;
  styles?: CSSObject;
}

export const Avatar = memo<Props>((props): ReactElement => {
  const thisUrl = props.isRandomAvatar
    ? randomAvatar.generateCard()
    : props.url || defaultAvatar;

  props.getAvatarUrl && props.getAvatarUrl(thisUrl);

  return (
    <ImgContainer
      src={thisUrl}
      onError={(e: any) => (e.target.src = defaultAvatar)}
      size={props.size}
      width={props.width}
      height={props.height}
      borderColor={
        props.borderColor
          ? props.borderColor
          : props.url
          ? colors.primary
          : colors.darkGray
      }
      border={
        props.isRandomAvatar || !props.url
          ? 'noBorder'
          : props.border || 'default'
      }
      grayscale={props.grayscale}
      isClickable={props.isClickable}
      onClick={props.onClick}
      styles={props.styles}
      {...props}
    />
  );
});
