/* eslint-disable no-case-declarations */
import { ActionTypes, JobSpecificationModalReducer } from './types';

const jobSpecificationsModal = {
  isOpen: false,
  text: null,
};

const jobSpecificationsModalReducer: JobSpecificationModalReducer = (
  state = jobSpecificationsModal,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_JOB_SPECIFICATIONS:
      const { payload } = action;

      return payload;
    default:
      return state;
  }
};

export default jobSpecificationsModalReducer;
