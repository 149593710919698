/* eslint-disable no-case-declarations */
import { ActionTypes, BriefFlowReducer } from './types';

const briefFlowStorage = localStorage.getItem('projectBriefFlow');

const briefFlowModel = {
  pathSelection: '',
  thisFormIndex: null,
  contentKey: null,
  questionsList: [],
};
const briefFlowInit = briefFlowStorage
  ? JSON.parse(briefFlowStorage)
  : briefFlowModel;

const projectBriefFlowReducer: BriefFlowReducer = (
  state = briefFlowInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_PROJECT_BRIEF_FLOW:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default projectBriefFlowReducer;
