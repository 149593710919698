/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function CheckIconCircle({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g mask="url(#mask0_2913_10471)">
        <path
          d="M9.4375 13L14.3958 8.0625L13.3333 7L9.4375 10.875L7.66667 9.125L6.60417 10.1875L9.4375 13ZM10.5 18C9.40278 18 8.36806 17.7917 7.39583 17.375C6.42361 16.9583 5.57292 16.3854 4.84375 15.6562C4.11458 14.9271 3.54167 14.0764 3.125 13.1042C2.70833 12.1319 2.5 11.0972 2.5 10C2.5 8.88889 2.70833 7.85069 3.125 6.88542C3.54167 5.92014 4.11458 5.07292 4.84375 4.34375C5.57292 3.61458 6.42361 3.04167 7.39583 2.625C8.36806 2.20833 9.40278 2 10.5 2C11.6111 2 12.6493 2.20833 13.6146 2.625C14.5799 3.04167 15.4271 3.61458 16.1562 4.34375C16.8854 5.07292 17.4583 5.92014 17.875 6.88542C18.2917 7.85069 18.5 8.88889 18.5 10C18.5 11.0972 18.2917 12.1319 17.875 13.1042C17.4583 14.0764 16.8854 14.9271 16.1562 15.6562C15.4271 16.3854 14.5799 16.9583 13.6146 17.375C12.6493 17.7917 11.6111 18 10.5 18ZM10.5 16.5C12.3056 16.5 13.8403 15.8681 15.1042 14.6042C16.3681 13.3403 17 11.8056 17 10C17 8.19444 16.3681 6.65972 15.1042 5.39583C13.8403 4.13194 12.3056 3.5 10.5 3.5C8.69444 3.5 7.15972 4.13194 5.89583 5.39583C4.63194 6.65972 4 8.19444 4 10C4 11.8056 4.63194 13.3403 5.89583 14.6042C7.15972 15.8681 8.69444 16.5 10.5 16.5Z"
          fill={iconColor}
        />
      </g>
    </svg>
  );
}
