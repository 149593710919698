/* eslint-disable no-case-declarations */
import { ActionTypes, OwnerEditReducer } from './types';

const ownerEditReducer: OwnerEditReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_OWNER_EDIT:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default ownerEditReducer;
