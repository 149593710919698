import styled, { css, keyframes } from 'styled-components';
import { ButtonProps } from '.';
import { colors } from '../../styles/colors';

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  height: 48px;

  padding: 1rem 2rem;
  background: ${colors.linearPurple};
  box-shadow: ${colors.buttonShadow};
  border-style: none;
  border: 2px solid transparent;
  border-radius: 6px;

  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  color: ${colors.white};

  transition: all 0.3s ease;
  background-size: 400% 400%;

  &:hover {
    animation: hoverAnimation 0.6s ease forwards;
  }

  @keyframes hoverAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background: ${colors.linearPurpleHover};
      background-position: 0% 50%;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${colors.darkInput};
      opacity: 1;
      box-shadow: ${colors.buttonBorderedShadow};
      cursor: not-allowed;

      &:hover {
        background: ${colors.darkInput};
      }
    `}

  ${({ bordered }) =>
    bordered &&
    css`
      border: 2px solid ${colors.primary};
      color: ${colors.primary};
      /* background-color: transparent; */
      background: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: ${colors.buttonBorderedShadow};
        animation: null;
        background: ${colors.lightPurple};
      }
    `}

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: transparent;
      color: ${colors.primary};
      &:hover {
        background-color: transparent;
        color: ${colors.primary};
      }
    `}

  ${({ outlined, disabled }) =>
    outlined &&
    disabled &&
    css`
      background-color: transparent;
      color: ${colors.primary};
      opacity: 0.2;
    `}

  ${({ text }) =>
    text &&
    css`
      color: ${colors.primary};
      background-color: transparent;

      &:hover {
        background-color: ${colors.darkInput};
      }
    `}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ hlarge }) =>
    hlarge &&
    css`
      min-width: 254px;
    `}

  ${({ icon, outlined }) =>
    icon &&
    css`
      position: relative;
      svg {
        position: absolute;
        transform: translateX(50%);
        opacity: 0;
        transition: all 0.3s ease;
        right: 48px;
      }
      :hover {
        padding-left: 1.5rem;
        padding-right: 2.5rem;
        svg {
          right: ${outlined ? '24' : '36'}px;
          opacity: 1;
        }
      }
    `}

  ${({ icon, full, hlarge }) =>
    icon &&
    !full &&
    !hlarge &&
    css`
      position: relative;
      svg {
        position: absolute;
        transform: translateX(50%);
        opacity: 0;
        transition: all 0.3s ease;
        right: 30%;
      }
      :hover {
        padding-left: 1.5rem;
        padding-right: 2.5rem;
        svg {
          right: 15%;
          opacity: 1;
        }
      }
      :disabled {
        :hover {
          padding-left: 2rem;
          padding-right: 2rem;
          svg {
            opacity: 0;
          }
        }
      }
    `}

  ${({ outlined }) =>
    !outlined &&
    css`
      :disabled {
        color: ${colors.faded};
        border-color: ${colors.faded};
        background: ${colors.background100};
        opacity: 0.7;
        :hover {
          opacity: 0.5;
          background: ${colors.background100};
          animation: null;
        }
      }
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `}

  ${({ fullColor }) =>
    fullColor &&
    css`
      color: ${fullColor};
      border-color: ${fullColor};
      :hover {
        background: ${fullColor};
        color: ${colors.textLight};
      }
      :disabled {
        :hover {
          color: ${colors.darkInput};
          background: transparent;
        }
      }
    `}

  ${({ red }) =>
    red &&
    css`
      color: ${colors.textLight};
      border-color: ${colors.red};
      background: ${colors.red};
      box-shadow: none;
      :hover {
        border-color: #6951d8;
      }
      :disabled {
        :hover {
          color: ${colors.darkInput};
          background-color: transparent;
        }
      }
    `}

		${({ blue }) =>
    blue &&
    css`
      background: linear-gradient(146.16deg, #4886d9 35.79%, #927aff 117.07%);
      box-shadow: 0px 7px 13px rgba(50, 112, 194, 0.25);
      :hover {
        animation: none;
        filter: brightness(1.2);
      }
      :disabled {
        :hover {
          color: ${colors.darkInput};
          background-color: transparent;
        }
      }
    `}

  ${({ iconWidth }) =>
    iconWidth &&
    css`
      svg {
        width: ${iconWidth} !important;
        height: ${iconWidth} !important;
      }
    `}

  ${({ white }) =>
    white &&
    css`
      background: ${colors.white};
      color: ${colors.primary};
      animation: none !important;
    `}

	  ${({ redBordered }) =>
    redBordered &&
    css`
      border: 2px solid ${colors.red};
      color: ${colors.red};
      background: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: ${colors.buttonBorderedShadow};
        animation: null;
        background: ${colors.redLight};
      }
    `}


  @media screen and (min-width: 1024px) and (max-height: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  ${({ stylesVariation }) => {
    if (stylesVariation === 'mini') {
      return css`
        height: 32px;
        padding: 6px;
        font-weight: 400;
        gap: 4px;
        svg {
          min-width: 18px !important;
          min-height: 18px !important;
        }
      `;
    }
  }}

  ${({ stylesVariation }) => {
    if (stylesVariation === 'text_mini') {
      return css`
        height: 32px;
        padding: 6px;
        font-weight: 400;
        border: none;
        background: transparent;
        box-shadow: none;
        color: ${colors.primary};
        gap: 4px;
        :hover {
          animation: none;
          filter: brightness(1.1);
        }
        :disabled {
          background-color: transparent;
        }
        svg {
          min-width: 18px !important;
          min-height: 18px !important;
        }
      `;
    }
  }}

  ${({ styles }) => styles};
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
  border: 4px solid ${colors.primary};
  border-radius: 100%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;

  border-top-color: ${colors.faded};
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
`;
