import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_CULTURE_FORM_MODAL = '@cultureFormModal/SET',
}

export type CultureRadioModal = {
  isOpen: boolean;
  thisCard: null;
};

export type CultureRadioModalReducer = Reducer<
  CultureRadioModal,
  Action<ActionTypes>
>;
