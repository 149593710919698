/* eslint-disable no-case-declarations */
import { IsUpsellFinishReducer, ActionTypes } from './types';

const isUpsellFinishReducer: IsUpsellFinishReducer = (
  state = false,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_UPSELL_FINISH:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default isUpsellFinishReducer;
