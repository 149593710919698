/* eslint-disable react/jsx-filename-extension */
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import setLoginOrSignupFormThunk from 'Store/modules/loginSignUpForms/changeForm/thunk';

import { welcomeTexts } from 'Texts/LoginSignUp';

const WelcomeText = ({
  isLoginContent = false,
  isSignUpContent = false,
  showsLoginOption = true,
}) => {
  const { browserLanguage } = useSelector(state => state);
  const { login, signup } = welcomeTexts;
  const history = useHistory();
  let welcomeTextContent = null;

  if (isLoginContent) {
    welcomeTextContent = (
      <div className="welcome-text">
        <h3>{login.h3[browserLanguage]}</h3>
        <span>
          {login.span[browserLanguage]}
          <button
            className="linkButton"
            onClick={() => {
              let pushTo = '/signup';
              if (sessionStorage.getItem('isSquadOwnerSession')) {
                pushTo = '/sales-signup';
              }
              history.push(pushTo);
            }}
          >
            {login.a[browserLanguage]}
          </button>
        </span>
      </div>
    );
  }

  if (isSignUpContent) {
    welcomeTextContent = (
      <div className="welcome-text">
        <h3 style={{ fontSize: '26px' }}>{signup.h3[browserLanguage]}</h3>
        {showsLoginOption && (
          <span>
            {signup.span[browserLanguage]}
            <button
              className="linkButton"
              onClick={() => {
                history.push('/login');
              }}
            >
              {signup.a[browserLanguage]}
            </button>
          </span>
        )}
      </div>
    );
  }

  return welcomeTextContent;
};

export default WelcomeText;
