import UsersIconDefault from './variations/default';
import UsersIconV2 from './variations/v2';

type UsersIconDefaultType = typeof UsersIconDefault;
type UsersIconV2Type = typeof UsersIconV2;
interface CompounedComponent extends UsersIconDefaultType {
  V2: UsersIconV2Type;
}

const UsersIcon = UsersIconDefault as CompounedComponent;
UsersIcon.V2 = UsersIconV2;

export default UsersIcon;
