import { colors } from 'AppV2/styles/colors';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.background200};

  position: fixed;
  top: 0;
  z-index: 9996;

  display: flex;
  align-items: center;
  justify-content: center;

  .animation-content {
    width: 100%;
    max-width: 630px;
  }
`;
