import ReactGA from 'react-ga4';

const eventGaTracker = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
  transport?: any,
) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: nonInteraction,
    transport: transport,
  });
};
export default eventGaTracker;
