/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { GetSkillsByAiRequest, GetSkillsByAiResponse } from './types';

export const getSkillsByAI = async ({
  ...props
}: GetSkillsByAiRequest): Promise<AxiosResponse<GetSkillsByAiResponse>> => {
  try {
    const response = await apiV2Instance.get('/getSkillsByAI', {
      params: {
        professionName: props.professionName,
      },
    });
    console.log('[services][GetSkillsByAI][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;

    console.log('[services][GetSkillsByAI][ERROR]', errorResponse);

    throw errorResponse;
  }
};
