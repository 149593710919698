import { ThunkActionReturn } from 'Store/types';
import setPopUpError from './action';
import { PopUpError } from './types';

const setPopUpErrorThunk = (popUpError: PopUpError): ThunkActionReturn => {
  return dispatch => {
    dispatch(setPopUpError(popUpError));
  };
};

export default setPopUpErrorThunk;
