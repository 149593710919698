import { AppVersionType } from 'AppV2/interfaces/appVersion';
import { UlColumn } from 'AppV2/styles/containers/ULColumn';
import styled, { css } from 'styled-components';
import { colors } from '../../../styles/colors';

interface HeaderProps {
  isWorkspaceSelected: boolean;
}

interface Props {
  appVersion?: AppVersionType;
}
interface LIProps {
  selected?: boolean;
}

export const HeaderHeight = '66px';

export const HeaderWrapper = styled.header<HeaderProps>`
  width: 100%;
  max-width: 100vmax;
  border-bottom: 1px solid ${colors.faded};
  background-color: ${colors.gray100};

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;

  .inner-wrapper {
    width: 100%;
    height: ${HeaderHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    background-color: ${colors.gray100};
  }

  ${({ isWorkspaceSelected }) =>
    isWorkspaceSelected &&
    css`
      border-bottom: 0px;

      ::after {
        content: '';
        position: absolute;
        z-index: -1;
        background: linear-gradient(
          90deg,
          #5a16ef 0%,
          #b845f4 26.82%,
          #3e38f4 33.8%,
          #5a64f4 37.2%,
          #d6e7e4 41.68%,
          #322df7 49.56%,
          #ababed 59.9%,
          #ad83e1 69.27%,
          #4f2df5 77.08%,
          #8791eb 84.9%,
          #5b4df4 88.54%,
          #d963e4 94.79%,
          #eccab2 100%
        );
        width: 100%;
        height: 3px;
        left: 0px;
        bottom: -1px;
      }
    `}

  @media screen and (max-width: 480px) {
    #header-logo {
      width: 100px;
    }
  }
`;

export const UserSection = styled.div`
  display: flex;
  margin-left: auto;
`;

export const UserSectionSeparator = styled.div`
  height: auto;
  width: 1px;
  background-color: ${colors.darkInput};
  margin: 0px 32px;

  @media screen and (max-width: 480px) {
    margin: 0px 8px;
  }
`;

export const UserDropdown = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: ${({ appVersion }) =>
    appVersion === 'V2' ? '0.875rem' : '1.4rem'};
  font-family: 'Mulish';
  font-weight: 700;
  color: ${colors.darkText};

  cursor: pointer;

  #btn-collapser {
    width: 10px;
    svg {
      width: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 8px;
    .username-content {
      display: none;
    }
  }
`;

export const DropDownModalContent = styled.div`
  background-color: ${colors.gray100};
  ::before {
    right: 30px;
  }
  .user-name {
    display: flex;
    align-items: center;
    padding-top: 0px;
    font-size: 14px;
    color: ${colors.darkText};
  }

  @media screen and (max-width: 480px) {
    width: 100vw !important;
    left: 0;
    top: 56px;
  }
`;

export const DropDownUL = styled(UlColumn)`
  gap: 6px;
  padding: 25px 25px !important;

  @media screen and (max-width: 480px) {
    gap: 14px;
  }
`;

export const LIContent = styled.li<LIProps>`
  a:not([href]) {
    color: ${({ selected }) =>
      selected ? `${colors.purple}` : `${colors.purple600}`};
    text-decoration: none;
    :hover {
      color: ${colors.purple};
    }
  }
  .li-link {
    color: ${({ selected }) =>
      selected ? `${colors.purple}` : `${colors.purple600}`};
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.3s;
    font-size: 14px;
    :hover {
      color: ${colors.purple};
    }
  }
  .icon-link {
    width: 16px;
    height: 16px;
    margin: 0 !important;
    text-align: center;
    color: ${colors.faded};
  }
`;
