import { Reducer } from 'redux';
import { Action } from 'Store/types';
export enum ActionTypes {
  SET_REDIRECT_PATH = '@redirectPath/SET',
}

export type RedirectPath = any;

export type RedirectPathReducer = Reducer<
  RedirectPath,
  Action<ActionTypes, RedirectPath>
>;
