import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_FIELDS_FILLED = '@fieldsFilled/SET',
}

export type FieldsFilled = number;

export type FieldsFilledReducer = Reducer<FieldsFilled, Action<ActionTypes>>;
