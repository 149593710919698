/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useModal } from 'AppV2/providers/ModalProvider';

import CloseCircleIcon from 'AppV2/assets/icons/CloseCircle';
import InfoCircleIcon from 'AppV2/assets/icons/InfoCircle';
import {
  AlertContainer,
  AlertType,
  ButtonCloseAlert,
  Description,
  HeaderContent,
  TimerBar,
  SupportRedirect,
} from './styles';
import { translateText } from 'AppV2/common/translateText';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'Contexts/AuthContext';

interface Props {
  title?: string;
  icon?: ReactNode;
  description?: ReactNode | string;
  alertType?: AlertType;
  time?: number;
}

export const Alert = ({
  title,
  icon,
  description,
  alertType = 'success',
  time = 15,
}: Props): ReactElement => {
  const { closeAlert } = useModal();
  const history = useHistory();
  const { currentUser } = useAuth();

  const [handleClose, setHandleClose] = useState(false);
  const alertRef = useRef(null);
  const timerBarRef = useRef(null);
  const defaultErrorTitle = translateText('ALERT:ERROR_LOAD_DATA_TITLE');
  const defaultErrorDescription = translateText(
    'ALERT:ERROR_LOAD_DATA_DESCRIPTION',
  );
  const isSuccess = alertType === 'success';
  const isError = alertType === 'error';
  const userRole = currentUser?.claims?.userRole;
  const isAdmin = userRole === UserRoleEnum.admin;
  const shouldShowSupportRedirectButton = isError && !!userRole && !isAdmin;

  const closeAnimateAlert = () => {
    alertRef.current.style.animation = 'close 1s forwards';
    setTimeout(() => closeAlert(), 1200);
  };

  const openAnimateAlert = () => {
    alertRef.current.style.animation = 'open 1s ease forwards';
    if (isSuccess) {
      timerBarRef.current.style.animation = `timeBarLoading ${time}s ease-in forwards`;
    }
  };

  const handleCloseAlert = () => {
    setHandleClose(true);
    closeAnimateAlert();
  };

  const handleRedirectToSupport = () => {
    const supportPath =
      userRole === UserRoleEnum.squadOwner
        ? '/support-squadOwner-view'
        : '/support-squadMember-view';

    history.push(supportPath, {
      isReportBug: true,
    });

    closeAlert();
  };

  useEffect(() => {
    if (!handleClose) {
      openAnimateAlert();
      if (isSuccess) {
        const timer = setTimeout(() => closeAnimateAlert(), time * 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [handleClose]);

  return (
    <AlertContainer
      ref={node => (alertRef.current = node)}
      styleType={alertType}
      data-testid="alert-component"
    >
      <HeaderContent styleType={alertType}>
        <div className="icon-content">{icon || <InfoCircleIcon />}</div>
        <h3>{title || (isError ? defaultErrorTitle : '')}</h3>

        <ButtonCloseAlert onClick={handleCloseAlert} styleType={alertType}>
          <CloseCircleIcon />
        </ButtonCloseAlert>
      </HeaderContent>

      <Description>
        {description || (isError ? defaultErrorDescription : '')}

        {shouldShowSupportRedirectButton && (
          <SupportRedirect onClick={handleRedirectToSupport}>
            {translateText('ALERT:REPORT_A_BUG')}
          </SupportRedirect>
        )}
      </Description>

      {isSuccess && (
        <div className="timebar-container">
          <TimerBar
            ref={node => (timerBarRef.current = node)}
            styleType={alertType}
          />
        </div>
      )}
    </AlertContainer>
  );
};
