/* eslint-disable no-case-declarations */
import { ActionTypes, RedirectPathReducer } from './types';

const redirectPathReducer: RedirectPathReducer = (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_REDIRECT_PATH:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default redirectPathReducer;
