import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MOBILE_FORM = '@mobileForm/SET',
}
export type MobileForms = {
  isOpen: boolean;
  isHidden: boolean;
  thisFormIndex: any;
};

export type MobileFormsReducer = Reducer<MobileForms, Action<ActionTypes>>;
