import styled, { css } from 'styled-components';
import { motion } from 'framer-motion/dist/framer-motion';
import { colors } from 'AppV2/styles/colors';

interface ModalSectionProps {
  outPadding: boolean;
  bgColor?: string;
  buttonCloseColor: string;
}

export const ModalMainContent = styled.main`
  width: 100vw;
  height: 100vh;
  max-width: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalSectionContainer = styled(motion.section)<ModalSectionProps>`
  background: ${({ bgColor }) => bgColor || colors.background200};
  padding: 24px;
  border-radius: 12px;
  position: relative;
  max-height: 96vh;
  max-height: 96dvh;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.purple};
  }

  p,
  h1 {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  label {
    font-size: 0.75rem !important;
  }

  .title-secondary-variation {
    color: ${colors.textBlack};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #close-modal-button {
    position: absolute;
    top: 27px;
    right: 24px;
    font-size: 1rem;
    color: ${({ buttonCloseColor }) => buttonCloseColor || colors.darkText};
    transition: 0.3s;
    :hover {
      opacity: 0.7;
    }
  }

  button {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    padding: 1rem;
    h1 {
      font-size: 1.2rem;
    }
    p {
      font-size: 1rem;
    }
    #close-modal-button {
      right: 12px;
      top: 12px;
    }
  }

  ${({ outPadding }) =>
    outPadding &&
    css`
      padding: 0rem;

      @media screen and (max-width: 480px) {
        padding: 0rem;
      }
    `}
`;
