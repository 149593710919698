import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_BROWSER_LANGUAGE = '@browserLanguage/SET',
}

export type BrowserLanguage = Navigator['language'];

export type BrowserLanguageReducer = Reducer<
  BrowserLanguage,
  Action<ActionTypes, BrowserLanguage>
>;
