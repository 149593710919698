/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import { TooltipContainer } from './styles';
import ArrowLeftSolidIcon from 'AppV2/assets/icons/ArrowLeftSolid';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

interface Props {
  isOpen: boolean;
  text: string;
  positionX?: number | string;
  initialPositionX?: number | string;
}

export const Tooltip = ({
  isOpen,
  text,
  initialPositionX = 0,
  positionX = 45,
}: Props): ReactElement => {
  return (
    <AnimatePresence initial={false} mode="wait">
      {isOpen && (
        <TooltipContainer
          initial={{
            opacity: 0,
            x: initialPositionX,
          }}
          animate={{
            opacity: isOpen ? 1 : 0,
            x: isOpen ? positionX : initialPositionX,
          }}
          exit={{
            opacity: 0,
            x: initialPositionX,
          }}
        >
          <div className="arrow-icon-content">
            <ArrowLeftSolidIcon />
          </div>
          <span>{text}</span>
        </TooltipContainer>
      )}
    </AnimatePresence>
  );
};
