import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { BalanceData, BalanceRequest } from './types';

export const getBalance = async ({
  userId,
}: BalanceRequest): Promise<AxiosResponse<BalanceData>> => {
  try {
    const res = await apiV2Instance.get('/balance', {
      params: {
        userId: userId,
      },
    });

    console.log('[services][extractPayment][getExtractPaymentData.res]', res);
    return res;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;

    console.error(
      '[services][extractPayment][getExtractPaymentData.ERROR]',
      errorResponse,
    );

    throw errorResponse;
  }
};
