import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_SCORE_PERFIL_CATEGORY = '@scorePerfilCategory/SET',
}

export type ScorePerfilCategory = string;

export type ScorePerfilCategoryReducer = Reducer<
  ScorePerfilCategory,
  Action<ActionTypes>
>;
