/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function HelperIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00049 12C2.00049 12.51 2.03849 13.021 2.11449 13.519C2.17549 13.916 2.54449 14.193 2.94349 14.127C3.34049 14.066 3.61249 13.695 3.55249 13.299C3.48749 12.874 3.45449 12.437 3.45449 12C3.45449 7.288 7.28849 3.455 12.0005 3.455C16.7125 3.455 20.5465 7.288 20.5465 12C20.5465 16.713 16.7125 20.546 12.0005 20.546C9.32749 20.546 6.85749 19.331 5.22649 17.21C4.97949 16.893 4.52349 16.832 4.20549 17.078C3.88849 17.322 3.82849 17.78 4.07349 18.098C5.98349 20.577 8.87249 22 12.0005 22C17.5145 22 22.0005 17.514 22.0005 12C22.0005 6.486 17.5145 2 12.0005 2C6.48649 2 2.00049 6.486 2.00049 12Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2106 7.78239C11.6869 7.69256 11.1483 7.79098 10.6902 8.06021C10.2321 8.32944 9.88403 8.75211 9.70771 9.25336C9.57025 9.6441 9.14206 9.84943 8.75132 9.71198C8.36058 9.57452 8.15525 9.14634 8.2927 8.75559C8.58658 7.92018 9.16664 7.21573 9.93015 6.76701C10.6936 6.31829 11.5913 6.15427 12.4642 6.30398C13.337 6.4537 14.1287 6.9075 14.6991 7.585C15.2692 8.26234 15.5814 9.11957 15.5802 10.0049C15.58 11.4028 14.5414 12.3484 13.7462 12.8785C13.3258 13.1588 12.9106 13.3659 12.6036 13.5023C12.449 13.571 12.3191 13.623 12.2261 13.6585C12.1796 13.6762 12.1421 13.6898 12.1152 13.6994L12.0828 13.7107L12.0729 13.7141L12.0696 13.7152L12.0683 13.7157C12.0683 13.7157 12.0674 13.716 11.8302 13.0045L12.0674 13.716C11.6744 13.847 11.2497 13.6346 11.1187 13.2416C10.9878 12.8491 11.1997 12.4248 11.5919 12.2933L11.5948 12.2924L11.6126 12.2861C11.6297 12.28 11.6566 12.2703 11.6921 12.2567C11.7632 12.2297 11.8677 12.1879 11.9944 12.1316C12.2498 12.0181 12.5847 11.8501 12.9142 11.6304C13.6189 11.1606 14.0802 10.6063 14.0802 10.0045L14.0802 10.0034C14.081 9.472 13.8937 8.95751 13.5515 8.55101C13.2093 8.1445 12.7343 7.87222 12.2106 7.78239Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1602 17.0045C11.1602 16.5903 11.496 16.2545 11.9102 16.2545H11.9202C12.3344 16.2545 12.6702 16.5903 12.6702 17.0045C12.6702 17.4187 12.3344 17.7545 11.9202 17.7545H11.9102C11.496 17.7545 11.1602 17.4187 11.1602 17.0045Z"
        fill={iconColor}
      />
    </svg>
  );
}
