/* eslint-disable no-case-declarations */
import { ActionTypes, AdminReducer } from './types';

const adminReducer: AdminReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_DATA:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default adminReducer;
