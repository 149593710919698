import { Reducer } from 'redux';
import { Action } from 'Store/types';
export enum ActionTypes {
  SET_LOGIN_OR_SIGNUP_FORM = '@LoginOrSignupForm/SET',
}

export type LoginOrSignupForm = boolean;

export type LoginOrSignupFormReducer = Reducer<
  LoginOrSignupForm,
  Action<ActionTypes, LoginOrSignupForm>
>;
