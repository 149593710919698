/* eslint-disable no-case-declarations */
import { ActionTypes, SquadOwnerDataReducer } from './types';

const isLocalEnvironment = process.env.REACT_APP_AMBIENT === 'local';
const localStorageOwnerData = localStorage.getItem('squadOwnerData');
const localStorageWorkspaceInviteData =
  localStorage.getItem('WORKSPACE_INVITE');
const workspaceInviteParseData = JSON.parse(
  localStorageWorkspaceInviteData || '{}',
);

const ownerDataModel = {
  name: isLocalEnvironment ? 'Owner' : '',
  lastName: isLocalEnvironment ? 'Master' : '',
  phone: {
    number: isLocalEnvironment ? '(51) 99888-8888' : '',
    code: isLocalEnvironment ? '55' : '',
  },
  company: isLocalEnvironment ? 'Company' : '',
  positionCompany: isLocalEnvironment ? 'Cargo' : '',
  employees: isLocalEnvironment ? 'Apenas eu' : '',
  country: isLocalEnvironment ? 'Brasil' : '',
  city: isLocalEnvironment ? 'Brasília' : '',
  site: isLocalEnvironment ? 'www.site.com' : '',
};

const workspaceInviteDataModel = {
  name: isLocalEnvironment ? 'Owner' : '',
  lastName: isLocalEnvironment ? 'Master' : '',
  phone: {
    number: isLocalEnvironment ? '(51) 99888-8888' : '',
    code: isLocalEnvironment ? '55' : '',
  },
  company: workspaceInviteParseData?.companyName,
  positionCompany: isLocalEnvironment ? 'Cargo' : '',
  employees: workspaceInviteParseData?.companyEmployees,
  country: isLocalEnvironment ? 'Brasil' : '',
  city: isLocalEnvironment ? 'Brasília' : '',
  site: workspaceInviteParseData?.companySite,
};

const squadOwnerInit = localStorageWorkspaceInviteData
  ? workspaceInviteDataModel
  : localStorageOwnerData
  ? JSON.parse(localStorageOwnerData)
  : ownerDataModel;

const squadOwnerDataReducer: SquadOwnerDataReducer = (
  state = squadOwnerInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_SQUAD_OWNER_DATA:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default squadOwnerDataReducer;
