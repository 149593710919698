/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import * as S from './styles';

interface Props {
  color?: string;
  type?: 'regular' | 'svg';
}

export const Spinner = ({ ...props }: Props): ReactElement => {
  if (props.type === 'svg') {
    return <S.SpinnerSVG />;
  }

  return <S.Container color={props.color} />;
};
