import styled, { css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

interface RadioCheckedProps {
  isChecked: boolean;
}

export const RadioInputContainer = styled.div<RadioCheckedProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  min-width: 18px;
  height: 18px;

  border-radius: 50%;
  border: 2px solid ${colors.text300};

  ${({ isChecked }) =>
    isChecked &&
    css`
      border-color: ${colors.purple300};
    `};
`;

export const Radio = styled.input`
  background: transparent;
  background-color: transparent;
  webkit-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: '';
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    border-radius: 50%;
    background: ${colors.primary};
    opacity: 0;
    transform: scale(1);
    transition: 0.3s;
  }

  :checked {
    :after {
      opacity: 1;
    }
  }
`;
