import { AppLanguage } from 'AppV2/hooks/AppLanguage/types';
import i18n from 'AppV2/translations/i18next.init';
import { ThunkActionReturn } from 'Store/types';
import setBrowserLanguage from './action';

export const setBrowserLanguageDefaultPattern = (
  navigatorLanguage: Navigator['language'],
) => {
  const defaultLanguage = 'en-US';
  const portugueseLanguage = 'pt-BR';
  try {
    const isPortugueseNavigator = navigatorLanguage.startsWith('pt');
    return isPortugueseNavigator ? portugueseLanguage : defaultLanguage;
  } catch (error: any) {
    console.log(
      '[Store:modules:browserLanguage:thunk:setBrowserLanguageDefaultPattern][ERROR]',
      error,
    );
    return defaultLanguage;
  }
};

const setBrowserLanguageThunk = (language: AppLanguage): ThunkActionReturn => {
  return dispatch => {
    const i18nAppLanguageEnum = {
      pt: 'pt-BR',
      en: 'en-US',
    };

    const appLanguage = i18nAppLanguageEnum[language || i18n.language];

    const defaultNavigatorLanguage = setBrowserLanguageDefaultPattern(
      navigator.language,
    );

    dispatch(setBrowserLanguage(appLanguage || defaultNavigatorLanguage));
  };
};

export default setBrowserLanguageThunk;
