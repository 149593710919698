/* eslint-disable no-case-declarations */
import { ActionTypes, PopUpErrorReducer } from './types';

const POPUP_ERROR_INIT = {
  isOpen: false,
  message: '',
};

const popUpErrorReducer: PopUpErrorReducer = (
  state = POPUP_ERROR_INIT,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_POPUP_ERROR:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default popUpErrorReducer;
