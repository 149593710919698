/* eslint-disable no-case-declarations */
import { ActionTypes, OwnerDataReducer } from './types';

const ownerDataReducer: OwnerDataReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_OWNER_DATA:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default ownerDataReducer;
