import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_DRAG_SIDE_MODAL = '@dragSideModal/SET',
}

export type DragSideModal = {
  isOpen: boolean;
  cards: null;
  actions: null;
};

export type DragSideModalReducer = Reducer<DragSideModal, Action<ActionTypes>>;
