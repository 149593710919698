import { WorkspacePathsEnum } from 'AppV2/assets/allWorkspacePaths';
import { OwnerLinksProps } from '../userRoleLinks';
import { translateText } from './../../../../common/translateText';

export const setNavLinks = (
  hasWorkspacePathPermission: (path: WorkspacePathsEnum) => boolean,
  isAnotherWorkspaceSelected: boolean,
  ownerLinks: OwnerLinksProps,
) => {
  try {
    if (isAnotherWorkspaceSelected) {
      const hasWorkspaceUserBalancePermission = hasWorkspacePathPermission(
        WorkspacePathsEnum.balance,
      );

      let anotherWorkspaceProjectLinks = Array.from(ownerLinks.projectLI);

      if (!hasWorkspaceUserBalancePermission) {
        // remove Balance link
        anotherWorkspaceProjectLinks = anotherWorkspaceProjectLinks.filter(
          li => li.path !== '/extract-payments',
        );
      }

      // remove MyUsers link
      anotherWorkspaceProjectLinks = anotherWorkspaceProjectLinks.filter(
        li => li.path !== '/workspace',
      );

      // change text Meus Projetos to Projetos
      anotherWorkspaceProjectLinks = anotherWorkspaceProjectLinks.map(li => {
        if (li.path === '/my-projects') {
          li.text = translateText('MENU_NAV_BAR_LINKS:OWNER_PROJECTS');
        }
        return li;
      });

      return { ...ownerLinks, projectLI: anotherWorkspaceProjectLinks };
    } else {
      let anotherWorkspaceProjectLinks = Array.from(ownerLinks.projectLI);

      anotherWorkspaceProjectLinks = anotherWorkspaceProjectLinks.map(li => {
        if (li.path === '/my-projects') {
          li.text = translateText('MENU_NAV_BAR_LINKS:OWNER_PROJECTS');
        }
        return li;
      });

      return { ...ownerLinks, projectLI: anotherWorkspaceProjectLinks };
    }
  } catch {
    return [];
  }
};
