import { ActionTypes, ProfileLevelReducer } from './types';

const profileFieldsCompleted = {
  myProfile: 0,
  experiences: 0,
  educations: 0,
  skills: 0,
  languages: 0,
  values: 0,
};

const profileLevelReducer: ProfileLevelReducer = (
  state = profileFieldsCompleted,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_PROFILE_LEVEL:
      return action.payload;

    default:
      return state;
  }
};

export default profileLevelReducer;
