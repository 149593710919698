export enum TemplatesKeysEnum {
  GROWYX = 'growyx',
  HUNTERS_99 = '99hunters',
}

export type TemplatesConfig = {
  templateKey: TemplatesKeysEnum;
  head: {
    title: string;
    description: string;
    favIcon: string;
    favIconAppleTouch: string;
  };
};
