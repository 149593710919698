import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MY_COMPANY = '@educations/SET',
}

export interface MyCompanyData {
  name: string;
  razaoSocial: string;
  companyId: string;
  municipalRegistration: string;
  code: string;
  street: string;
  number: string;
  comp: string;
  neighborhood: string;
  country: string;
  city: string;
  rg: string;
  cpf: string;
  pixType: string;
  pix: string;
}

export type MyCompanyReducer = Reducer<MyCompanyData, Action<ActionTypes>>;
