import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';
import styled, { CSSObject, css } from 'styled-components';

interface Props {
  styles?: CSSObject;
  indicatorSide?: 'left' | 'right';
  indicatorPosition?: 'top' | 'right' | 'left' | 'bottom';
}

export const Container = styled(motion.div)<Props>`
  border: 1px solid ${colors.purple200};
  box-shadow: 0px 8px 22px ${colors.popUpShadow};
  border-radius: 8px;
  position: absolute;
  z-index: 99999;

  .inner-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: ${colors.background100};
    position: relative;

    // DEFAULT TEXTS STYLES
    h2 {
      color: ${colors.primary};
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 0px;
    }
    p {
      color: ${colors.text};
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      margin: 0px;
    }

    // ARROW
    ::after {
      position: absolute;
      top: -8px;
      left: 36px;
      content: '';
      width: 16px;
      height: 16px;
      background-color: ${colors.background100};
      border: 1px solid ${colors.purple200};
      border-bottom-color: transparent;
      border-right-color: transparent;
      transform: rotate(45deg);

      ${({ indicatorSide }) => {
        if (indicatorSide === 'right') {
          return css`
            left: initial;
            right: 36px;
          `;
        }

        if (indicatorSide === 'left') {
          return css`
            right: initial;
            left: 36px;
          `;
        }
      }}

      ${({ indicatorPosition, indicatorSide }) => {
        if (indicatorPosition === 'right') {
          return css`
            left: initial;
            right: -8px;
            top: 36px;
            transform: rotate(135deg);
          `;
        }

        if (indicatorPosition === 'left') {
          return css`
            right: initial;
            left: -8px;
            top: 36px;
            transform: rotate(-45deg);
          `;
        }

        if (indicatorPosition === 'bottom') {
          return css`
            top: initial;
            bottom: -9px;
            left: ${indicatorSide === 'left' ? '36px' : 'initial'};
            right: ${indicatorSide === 'left' ? 'initial' : '36px'};
            transform: rotate(225deg);
          `;
        }
      }}
    }
  }

  ${({ styles }) => styles}
`;

export const ClosePupUpButton = styled.button<Props>`
  position: absolute;
  top: -6px;
  right: -6px;

  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${colors.primary};
  color: ${colors.textLight};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    min-width: 10px;
    height: 10px;
  }

  transition: 0.3s;
  :hover {
    opacity: 0.9;
  }

  ${({ indicatorSide }) => {
    if (indicatorSide === 'right') {
      return css`
        left: -6px;
      `;
    }
  }}
`;
