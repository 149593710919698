/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useCallback } from 'react';

import { useQuery } from 'react-query';
import { t } from 'i18next';
import {
  Ammount,
  HeaderOwnerWalletContainer as Container,
  IconContent,
} from './style';
import MoneyIcon from 'AppV2/assets/icons/Money';
import { balanceServices } from 'AppV2/services/Balance';
import { getWalletQueryKey } from 'AppV2/services/Balance/GetWallet/types';
import { useAuth } from 'Contexts/AuthContext';

export const HeaderOwnerWallet = (): ReactElement => {
  // HOOKS
  const { currentUser } = useAuth();

  // VALUES
  const isMatchmakingTrial = !!currentUser?.claims?.matchmakingTrial;

  // HTTP FUNCTIONS
  const getWalletData = useCallback(async () => {
    if (!isMatchmakingTrial) {
      const response = await balanceServices.getWallet();
      const userWallet = response.data.userWallet;

      if (userWallet) {
        const formattedUserWalletValue = userWallet.toLocaleString(
          t('GENERAL:BROWSER_LANGUAGE'),
          {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          },
        );

        return formattedUserWalletValue;
      }

      return '';
    }
  }, [isMatchmakingTrial]);

  // CACHE DATA
  const { isFetching, data } = useQuery(getWalletQueryKey, getWalletData);

  if (isMatchmakingTrial) {
    return null;
  }

  return (
    <Container loadingWallet={isFetching}>
      <IconContent loadingWallet={isFetching}>
        <MoneyIcon />
      </IconContent>
      {data && (
        <Ammount loadingWallet={isFetching}>
          <label className="label-content">
            {/* {`${data} ${t('GENERAL:HEADER_OWNER_WALLET')}`} */}
            {data}
          </label>
        </Ammount>
      )}
    </Container>
  );
};
