/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ChartIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.5752C20 8.99779 19.6574 9.34037 19.2348 9.34037H19.226C19.0223 9.34042 18.8272 9.25832 18.6849 9.11265C18.5425 8.96697 18.4649 8.77004 18.4697 8.5664V6.2533C18.4697 3.2102 16.7898 1.53034 13.7467 1.53034H6.2533C3.20141 1.53034 1.53034 3.2102 1.53034 6.2533V13.7555C1.53034 16.7986 3.2102 18.4697 6.2533 18.4697H13.7467C16.7986 18.4697 18.4697 16.7898 18.4697 13.7555C18.4697 13.3329 18.8122 12.9903 19.2348 12.9903C19.6574 12.9903 20 13.3329 20 13.7555C20 17.6077 17.6077 20 13.7555 20H6.2533C2.39226 20 0 17.6077 0 13.7555V6.2533C0 2.39226 2.39226 0 6.2533 0H13.7467C17.5902 0 20 2.39226 20 6.2533V8.5752ZM4.96042 14.6878V8.34653V8.28496C4.96726 8.08273 5.05484 7.89166 5.20359 7.75448C5.35234 7.6173 5.54985 7.54544 5.75198 7.55497C5.95496 7.56185 6.14687 7.64914 6.28545 7.79761C6.42402 7.94608 6.49788 8.14356 6.49077 8.34653V14.7405C6.47619 15.1631 6.1218 15.4939 5.69921 15.4793C5.27662 15.4648 4.94585 15.1104 4.96042 14.6878ZM9.27001 5.32102V14.6966C9.27001 15.1192 9.61259 15.4617 10.0352 15.4617C10.4578 15.4617 10.8004 15.1192 10.8004 14.6966V5.32102C10.8004 4.89843 10.4578 4.55585 10.0352 4.55585C9.61259 4.55585 9.27001 4.89843 9.27001 5.32102ZM13.5092 14.6878V11.6974C13.5092 11.2749 13.8518 10.9323 14.2744 10.9323C14.697 10.9323 15.0396 11.2749 15.0396 11.6974V14.6878C15.0396 15.1104 14.697 15.4529 14.2744 15.4529C13.8518 15.4529 13.5092 15.1104 13.5092 14.6878Z"
        fill={iconColor}
      />
    </svg>
  );
}
