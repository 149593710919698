/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode, useMemo } from 'react';
import * as S from './styles';
import { ModalMain } from 'AppV2/components/Modal';
import CloseIcon from 'AppV2/assets/icons/Close';
import { CSSObject } from 'styled-components';
import { Styles } from 'react-modal';

interface FooterButtons {
  left?: ReactNode;
  right?: ReactNode;
  single?: ReactNode;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  headerTitle: string;
  headerIcon?: ReactNode;
  headerStyles?: CSSObject;
  childrenStyles?: CSSObject;
  styles?: Styles;
  footerButtons?: ReactNode | FooterButtons;
  footerButtonsStyles?: CSSObject;
}

export const ModalSecondary = ({
  childrenStyles = { padding: '0px' },
  ...props
}: Props): ReactElement => {
  const FOOTER_BUTTONS = useMemo(() => {
    const { single, left, right } = (props?.footerButtons ||
      {}) as FooterButtons;

    if (single) {
      return single;
    }

    if (left && right) {
      return (
        <>
          {left}
          {right}
        </>
      );
    }

    return props.footerButtons;
  }, [props.footerButtons]);

  return (
    <ModalMain
      outPadding
      styles={props.styles}
      showButtonClose={false}
      isOpen={props?.isOpen}
      keypressEscAction={props.onClose}
    >
      <S.Header styles={props?.headerStyles}>
        <h1>
          {props?.headerIcon && (
            <div className="icon-content">{props?.headerIcon}</div>
          )}

          {props.headerTitle}
        </h1>

        <button className="button-close-modal" onClick={props?.onClose}>
          <CloseIcon />
        </button>
      </S.Header>

      <S.ChildrenContainer styles={childrenStyles}>
        {props?.children}
      </S.ChildrenContainer>

      {props.footerButtons && (
        <S.FooterButtons styles={props?.footerButtonsStyles}>
          {FOOTER_BUTTONS as ReactNode}
        </S.FooterButtons>
      )}
    </ModalMain>
  );
};
