import styled, { CSSObject, css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

type AvatarSizesType = 'min' | 'small' | 'medium' | 'large';
type AvatarBorderType = 'default' | 'noBorder' | 'large';

interface AvatarTypes {
  size: AvatarSizesType;
  border: AvatarBorderType;
  borderColor: string;
  isClickable: boolean;
  grayscale: boolean;
  styles: CSSObject;
}

const SIZES = {
  min: '14px',
  small: '36px',
  medium: '54px',
  large: '72px',
};
const MOBILE_SIZES = {
  min: '14px',
  small: '27px',
  medium: '36px',
  large: '54px',
};
const BORDER_TYPES = {
  default: `2px solid ${colors.primary}`,
  large: `3px solid ${colors.primary}`,
  noBorder: '0px',
};
const BORDER_MIN_SIZE_TYPES = {
  default: `1px solid ${colors.primary}`,
  large: `2px solid ${colors.primary}`,
  noBorder: '0px',
};

export const ImgContainer = styled.img<AvatarTypes>`
  width: ${props => props.width || SIZES[props.size || 'small']};
  min-width: ${props => props.width || SIZES[props.size || 'small']};
  height: ${props => props.width || SIZES[props.size || 'small']};

  border-radius: 50%;
  border: ${props => BORDER_TYPES[props.border]};
  border-color: ${props => props.borderColor || colors.primary};

  object-fit: cover;

  ${({ size, border }) =>
    size === 'min' &&
    css`
      border: ${BORDER_MIN_SIZE_TYPES[border]};
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}

    ${({ grayscale }) =>
    grayscale &&
    css`
      filter: grayscale(1);
    `}


  @media screen and (max-width: 480px) {
    width: ${props => props.width || MOBILE_SIZES[props.size || 'small']};
    min-width: ${props => props.width || MOBILE_SIZES[props.size || 'small']};
    height: ${props => props.width || MOBILE_SIZES[props.size || 'small']};
  }

  ${({ styles }) => styles}
`;
