import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PatchAcceptWorkspaceInviteRequest } from './types';

export const patchAcceptInvite = async (
  request: PatchAcceptWorkspaceInviteRequest,
): Promise<AxiosResponse<Response>> => {
  try {
    const requestBody = {
      invitationId: request.invitationId,
      ownerWorkspaceId: request.workspaceId,
    };

    const response = await apiV2Instance.patch(
      `/workspace/acceptInvite`,
      requestBody,
    );
    console.log('[services][Workspace][PatchAcceptInvite][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log(
      '[services][Workspace][PatchAcceptInvite][ERROR]',
      errorResponse,
    );

    throw errorResponse;
  }
};
