/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function Default({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05836 7.35387C8.05836 5.19392 9.82642 3.43696 12 3.43696C14.1736 3.43696 15.9416 5.19392 15.9416 7.35387C15.9416 9.51281 14.1736 11.2698 12 11.2698C9.82642 11.2698 8.05836 9.51281 8.05836 7.35387ZM6.61233 7.35387C6.61233 10.3048 9.02943 12.7067 12 12.7067C14.9706 12.7067 17.3867 10.3048 17.3867 7.35387C17.3867 4.40194 14.9706 2 12 2C9.02943 2 6.61233 4.40194 6.61233 7.35387ZM10.9776 21.985C11.3127 21.995 11.6538 22 12 22C15.4345 22 20 21.619 20 18.3301C20 14.6392 13.9784 14.6392 12 14.6392C8.56553 14.6392 4 15.0202 4 18.3101C4 19.2951 4.46088 20.624 6.6566 21.363C7.03597 21.493 7.44453 21.288 7.57333 20.913C7.70113 20.538 7.49887 20.131 7.1205 20.004C5.61811 19.4981 5.44503 18.7951 5.44503 18.3101C5.44503 16.8271 7.65082 16.0751 12 16.0751C16.3492 16.0751 18.555 16.8341 18.555 18.3301C18.555 19.8121 16.3492 20.563 12 20.563C11.6679 20.563 11.3409 20.559 11.0189 20.55C10.6325 20.553 10.2883 20.851 10.2772 21.247C10.2652 21.644 10.5791 21.975 10.9776 21.985Z"
        fill={iconColor}
      />
    </svg>
  );
}
