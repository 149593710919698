import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_LANGUAGES = '@languages/ADD',
  SET_LANGUAGES = '@languages/SET',
  REMOVE_LANGUAGES = '@languages/REMOVE',
  RESET_LANGUAGES = '@languages/RESET',
}

export type Languages = any[];

export type LanguagesExpertise = {
  tag: string;
  expertise: number;
}[];

export type LanguagesReducer = Reducer<Languages, Action<ActionTypes>>;
