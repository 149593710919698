/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { memo, useCallback, useEffect, useState } from 'react';
import {
  Link,
  LinkIconContent,
  LogoContent,
  MenuContent,
  OpenCloseMenuButton,
  PopUpCustomStyles,
  PopupContent,
  PostProjectButton,
  PostProjectButtonContent,
  PostProjectText,
  ULinksList,
} from '../style';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';
import {
  OwnerLinksProps,
  OWNER_LINKS,
  MATCHMAKING_TRIAL_LINKS,
} from '../userRoleLinks';
import NavBarHoverIcon from 'AppV2/assets/icons/NavBarHover';
import ArrowLeftSolidIcon from 'AppV2/assets/icons/ArrowLeftSolid';
import { Logo } from 'AppV2/components/Logo';
import { translateText } from 'AppV2/common/translateText';
import { MenuNavBarProps } from '..';
import { Tooltip } from 'AppV2/components/MenuTooltip';
import PostProjectIcon from 'AppV2/assets/icons/PostProject';
import { WorkspacePathsEnum } from 'AppV2/assets/allWorkspacePaths';
import { setNavLinks } from './helpers';
import { useWorkspace } from 'AppV2/hooks/Workspace';
import { PopUp } from 'AppV2/components/PopUp';
import { ShouldShowPostProjectPopUpStorageKey } from 'Contexts/AuthContext/types';
import { useAuth } from 'Contexts/AuthContext';

export const OwnerViewMenuNavBar = memo(
  ({
    appVersionStyle,
    isMenuOpen,
    openTooltip,
    handleOpenTooltip,
    handleOpenCloseMenu,
    handleRedirect,
    isLinkSelected,
    openCloseMenuTooltipText,
    isAnotherWorkspaceSelected,
    workspaceSelectedPermissions,
  }: MenuNavBarProps) => {
    // HOOKS
    const { currentUser } = useAuth();
    const { hasWorkspacePathPermission } = useWorkspace();

    // STATES - VALUES
    const shouldShowPostProjectPopUp = !!localStorage.getItem(
      ShouldShowPostProjectPopUpStorageKey,
    );
    const [openPopup, setOpenPopup] = useState(false);
    const [isAllowedPostProject, setIsAllowedPostProject] = useState(
      isAnotherWorkspaceSelected
        ? hasWorkspacePathPermission(WorkspacePathsEnum.projectPost)
        : true,
    );
    const isMatchmakingTrial = !!currentUser?.claims?.matchmakingTrial;
    const NAV_LINKS = setNavLinks(
      hasWorkspacePathPermission,
      isAnotherWorkspaceSelected,
      isMatchmakingTrial ? MATCHMAKING_TRIAL_LINKS : OWNER_LINKS,
    ) as OwnerLinksProps;

    // FUNCTIONS
    const handleClosePopUp = useCallback(() => {
      localStorage.removeItem(ShouldShowPostProjectPopUpStorageKey);
      setOpenPopup(false);
    }, []);

    // EFFECTS
    useEffect(() => {
      if (!isMatchmakingTrial) {
        setIsAllowedPostProject(
          isAnotherWorkspaceSelected
            ? hasWorkspacePathPermission(WorkspacePathsEnum.projectPost)
            : true,
        );
      }
    }, [
      workspaceSelectedPermissions,
      isAnotherWorkspaceSelected,
      isMatchmakingTrial,
    ]);

    useEffect(() => {
      if (shouldShowPostProjectPopUp) {
        if (!isMatchmakingTrial) {
          setTimeout(() => setOpenPopup(true), 2000);
        }
      }
    }, [shouldShowPostProjectPopUp, isMatchmakingTrial]);

    return (
      <MenuContent appVersionStyle={appVersionStyle} isOpen={isMenuOpen}>
        <OpenCloseMenuButton
          isOpen={isMenuOpen}
          onClick={handleOpenCloseMenu}
          onMouseOver={() =>
            !openTooltip && handleOpenTooltip('openCloseButton')
          }
          onMouseLeave={() => openTooltip && handleOpenTooltip('')}
        >
          <ArrowLeftSolidIcon />
          <Tooltip
            isOpen={openTooltip === 'openCloseButton'}
            text={openCloseMenuTooltipText}
            positionX={isMenuOpen ? 67 : 64}
            initialPositionX={32}
          />
        </OpenCloseMenuButton>

        <LogoContent isOpen={isMenuOpen}>
          <Logo
            onlyIcon={!isMenuOpen}
            isWorkspaceSelected={isAnotherWorkspaceSelected}
          />
        </LogoContent>

        <ULinksList isMenuOpen={isMenuOpen}>
          <div className="subtitle-content">
            {!isMatchmakingTrial && (
              <h4 className="subtitle first-subtitle">
                {translateText('MENU_NAV_BAR_LINKS:OWNER_PROJECT_SUBTITLE')}
              </h4>
            )}
            {NAV_LINKS.projectLI.map(link => (
              <Link
                key={link.path}
                isMenuOpen={isMenuOpen}
                isSelected={isLinkSelected(link.path)}
                onClick={() =>
                  handleRedirect(link.path, link.text, UserRoleEnum.squadOwner)
                }
                onMouseEnter={() => !isMenuOpen && handleOpenTooltip(link.path)}
                onMouseLeave={() => !isMenuOpen && handleOpenTooltip('')}
              >
                <LinkIconContent
                  isSelected={isLinkSelected(link.path)}
                  iconSize={link?.iconSize}
                >
                  {link.icon}
                </LinkIconContent>
                <span className="li-span">
                  <>{translateText(link.text)}</>
                </span>
                <div className="nav-bar-hover-icon flexbox-start">
                  <NavBarHoverIcon />
                </div>

                {!isMenuOpen && (
                  <Tooltip
                    isOpen={openTooltip === link.path}
                    text={translateText(link.text)}
                  />
                )}
              </Link>
            ))}
          </div>

          <div className="subtitle-content">
            {!isMatchmakingTrial && (
              <h4 className="subtitle">
                {translateText('MENU_NAV_BAR_LINKS:OWNER_PLATFORM_SUBTITLE')}
              </h4>
            )}
            {NAV_LINKS.platformLI.map(link => (
              <Link
                isSelected={isLinkSelected(link.path)}
                isMenuOpen={isMenuOpen}
                key={link.path}
                onClick={() =>
                  handleRedirect(link.path, link.text, UserRoleEnum.squadOwner)
                }
                onMouseEnter={() => !isMenuOpen && handleOpenTooltip(link.path)}
                onMouseLeave={() => !isMenuOpen && handleOpenTooltip('')}
              >
                <LinkIconContent
                  isSelected={isLinkSelected(link.path)}
                  iconSize={link?.iconSize}
                >
                  {link.icon}
                </LinkIconContent>
                <span className="li-span">
                  <>{translateText(link.text)}</>
                </span>
                <div className="nav-bar-hover-icon flexbox-start">
                  <NavBarHoverIcon />
                </div>

                {!isMenuOpen && (
                  <Tooltip
                    isOpen={openTooltip === link.path}
                    text={translateText(link.text)}
                  />
                )}
              </Link>
            ))}
          </div>
        </ULinksList>

        {isAllowedPostProject && !isMatchmakingTrial && (
          <PostProjectButtonContent
            isOpen={isMenuOpen}
            onMouseEnter={() =>
              !isMenuOpen && handleOpenTooltip('project-brief-selection')
            }
            onMouseLeave={() => !isMenuOpen && handleOpenTooltip('')}
          >
            <PostProjectButton
              isOpen={isMenuOpen}
              onClick={() =>
                handleRedirect(
                  '/project-brief-selection',
                  translateText('MENU_NAV_BAR_LINKS:OWNER_POST_PROJECT'),
                  UserRoleEnum.squadOwner,
                )
              }
            >
              <PostProjectText isOpen={isMenuOpen}>
                <PostProjectIcon />
                <span className="span-text-content">
                  {translateText('MENU_NAV_BAR_LINKS:OWNER_POST_PROJECT')}
                </span>
                {!isMenuOpen && (
                  <Tooltip
                    isOpen={openTooltip === 'project-brief-selection'}
                    text={translateText(
                      'MENU_NAV_BAR_LINKS:OWNER_POST_PROJECT',
                    )}
                    positionX={75}
                  />
                )}
              </PostProjectText>

              <PopUp
                isOpen={openPopup}
                onClickButtonClose={handleClosePopUp}
                styles={PopUpCustomStyles}
                indicatorPosition="bottom"
                indicatorSide="left"
              >
                <PopupContent>
                  <h2>
                    {translateText(
                      'MENU_NAV_BAR_LINKS:OWNER_POPUP_POST_PROJECT_TITLE',
                    )}
                  </h2>
                  <p>
                    {translateText(
                      'MENU_NAV_BAR_LINKS:OWNER_POPUP_POST_PROJECT_DESCRIPTION',
                    )}
                  </p>
                </PopupContent>
              </PopUp>
            </PostProjectButton>
          </PostProjectButtonContent>
        )}
      </MenuContent>
    );
  },
);
