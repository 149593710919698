/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function ArrowsUpDownIconV2({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_1756_66093"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1756_66093)">
        <path
          d="M7.08662 10.7113V4.49819L4.68277 6.90205L3.91675 6.12803L7.62827 2.4165L11.3398 6.12803L10.5738 6.90205L8.16994 4.49819V10.7113H7.08662ZM12.3638 17.5831L8.65231 13.8716L9.41831 13.0976L11.8222 15.5014V9.2883H12.9055V15.5014L15.3094 13.0976L16.0754 13.8716L12.3638 17.5831Z"
          fill={iconColor}
        />
      </g>
    </svg>
  );
}
