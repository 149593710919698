/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import { WorkspaceCardContainer as Container } from './styles';
import { SkeletonLoader } from 'AppV2/components/SkeletonLoader';

export const WorkspaceCardSkeleton = (): ReactElement => {
  return (
    <Container isSkeleton>
      <div style={{ width: '45px', marginRight: '9px' }}>
        <SkeletonLoader type="avatar" avatarConfig={{ size: 45 }} />
      </div>
      <div style={{ width: '145px' }}>
        <SkeletonLoader type="text" textConfig={{ line: 2 }} />
      </div>
      <div style={{ width: '12px', marginLeft: 'auto' }}>
        <SkeletonLoader
          type="thumbnailBlock"
          thumbnailBlockConfig={{ width: 12, height: 36 }}
        />
      </div>
    </Container>
  );
};
