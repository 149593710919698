/* eslint-disable react/jsx-filename-extension */
import { ReactElement, Suspense, useLayoutEffect } from 'react';
import * as S from '../styles';
import Lottie from 'lottie-react';
import { LazyLoadingPage } from './Lazy';
import { useTemplatesConfig } from 'AppV2/hooks/TemplatesConfig';

export const LoadingBasic = (): ReactElement => {
  const { lottieLoading } = useTemplatesConfig();

  useLayoutEffect(() => {
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.style.overflow = 'hidden';
    }
  }, []);

  return (
    <Suspense fallback={<LazyLoadingPage />}>
      <S.BasicMotionDivWrapper {...S.MotionAnimation}>
        <Lottie
          loop={true}
          autoPlay={true}
          animationData={lottieLoading}
          className="lottie-player"
        />
      </S.BasicMotionDivWrapper>
    </Suspense>
  );
};
