import styled from 'styled-components';

import spinnerPNG from 'AppV2/assets/icons/spinner.png';

export const SpinnerContent = styled.img.attrs({
  src: spinnerPNG,
})`
  margin: 0 auto;
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  max-width: 16px !important;
  max-height: 16px !important;

  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
