/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function InfoCircleIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 8.28369C0.5 4.14744 3.8645 0.783691 8 0.783691C12.1355 0.783691 15.5 4.14744 15.5 8.28369C15.5 12.4192 12.1355 15.7837 8 15.7837C5.65475 15.7837 3.48725 14.7164 2.05475 12.8572C1.871 12.6179 1.91525 12.2752 2.1545 12.0922C2.39225 11.9077 2.73425 11.9534 2.91875 12.1912C4.1435 13.7812 5.99525 14.6932 8 14.6932C11.534 14.6932 14.4095 11.8184 14.4095 8.28369C14.4095 4.74969 11.534 1.87419 8 1.87419C4.466 1.87419 1.5905 4.74969 1.5905 8.28369C1.5905 8.61219 1.61525 8.93844 1.664 9.25794C1.709 9.55494 1.505 9.83319 1.20725 9.87894C0.908 9.92844 0.63125 9.72069 0.58625 9.42294C0.52925 9.04944 0.5 8.66619 0.5 8.28369ZM8 4.87427C7.6895 4.87427 7.4375 5.12627 7.4375 5.43677V8.75177C7.4375 9.06227 7.6895 9.31427 8 9.31427C8.3105 9.31427 8.5625 9.06227 8.5625 8.75177V5.43677C8.5625 5.12627 8.3105 4.87427 8 4.87427ZM8.00765 10.5681H8.00015C7.68965 10.5681 7.4414 10.8201 7.4414 11.1306C7.4414 11.4411 7.69715 11.6931 8.00765 11.6931C8.31815 11.6931 8.57015 11.4411 8.57015 11.1306C8.57015 10.8201 8.31815 10.5681 8.00765 10.5681Z"
        fill={iconColor}
      />
    </svg>
  );
}
