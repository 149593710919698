/* eslint-disable react/jsx-filename-extension */
import { IconProps } from 'AppV2/interfaces/iconProps';
import { ReactElement } from 'react';

export default function LightningIcon({ color }: IconProps): ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2989 0.31202C11.6044 0.444597 11.7858 0.762648 11.7445 1.09302L10.8499 8.25H17.9328C18.9926 8.25 19.5715 9.48606 18.8931 10.3002L9.57646 21.4801C9.36332 21.7359 9.00706 21.8205 8.70165 21.688C8.39624 21.5554 8.21479 21.2373 8.25609 20.907L9.15071 13.75H2.06782C1.00803 13.75 0.429075 12.5139 1.10754 11.6998L2.92413 9.51986C3.18931 9.20165 3.66223 9.15866 3.98044 9.42383C4.29864 9.689 4.34164 10.1619 4.07646 10.4801L2.60158 12.25H9.43391C10.1858 12.25 10.7675 12.909 10.6743 13.655L10.0618 18.5547L17.399 9.75H10.0003C9.78518 9.75 9.58041 9.65762 9.43804 9.49635C9.29567 9.33508 9.22941 9.12043 9.25609 8.90697L9.93879 3.44534L6.57646 7.48013C6.31129 7.79834 5.83837 7.84134 5.52016 7.57616C5.20195 7.31099 5.15896 6.83807 5.42413 6.51986L10.4241 0.519858C10.6373 0.264082 10.9935 0.179443 11.2989 0.31202Z"
        fill={iconColor}
      />
    </svg>
  );
}
