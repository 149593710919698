/* eslint-disable react/jsx-filename-extension */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'Contexts/AuthContext';

import Button from 'Components/Atoms/Generals/Button';
import InputIconLeft from 'Components/Atoms/Generals/InputIconLeft';
import { signupFormTexts } from 'Texts/LoginSignUp';
import CheckboxHandMade from 'Components/Atoms/Generals/CheckboxHandMade';
import Spinner from 'Components/Atoms/Generals/Spinner';
import { Form, Formik } from 'formik';
import * as Y from 'yup';
import { translateText } from 'AppV2/common/translateText';
import { colors } from 'AppV2/styles/colors';
import styled from 'styled-components';

const InputWrapper = styled.div`
  position: relative;
`;
const ErrorMessage = styled.span`
  position: absolute;
  left: 0px;
  bottom: -24px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: ${colors.red};
`;

const SignUpForm = () => {
  // HOOKS
  const { emailSignup, setIsLoginError } = useAuth();
  const { accountType, browserLanguage } = useSelector(state => state);
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isTypePassword, setIsPassword] = useState({
    password: true,
    confirmPassword: true,
  });

  // VALUES
  const { emailInput, passwordInput, confirmPasswordInput, btn, useTerms } =
    signupFormTexts;

  const isRedirectFromSelfAdmission = !!sessionStorage.getItem(
    'isLogOffUserSelfAdmission',
  );

  const isRedirectFromWorkspaceInvite =
    !!localStorage.getItem('WORKSPACE_INVITE');

  const validationSchema = Y.object().shape({
    email: Y.string()
      .email(translateText('VALIDATIONS:EMAIL'))
      .required(translateText('VALIDATIONS:EMAIL')),
    password: Y.string()
      .min(6, translateText('VALIDATIONS:PASSWORD_MIN_CHARS'))
      .matches(/[0-9]/, translateText('VALIDATIONS:PASSWORD_MACTH_NUMBER'))
      .matches(/[a-z]/, translateText('VALIDATIONS:PASSWORD_MACTH_LOWERCASE'))
      .matches(/[A-Z]/, translateText('VALIDATIONS:PASSWORD_MACTH_UPPERCASE'))
      .matches(/[^\w]/, translateText('VALIDATIONS:PASSWORD_MACTH_SYMBOL')),
    confirmPassword: Y.string().oneOf(
      [Y.ref('password')],
      translateText('VALIDATIONS:CONFIRM_PASSWORD'),
    ),
  });

  const initFormValue = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  // FUNCTIONS
  const handleRegister = async data => {
    setIsLoginError(false);

    if (!isChecked) {
      return alert(signupFormTexts.useTerms.alertText[browserLanguage]);
    }

    if (isRedirectFromSelfAdmission) {
      localStorage.setItem('isLogOffUserSelfAdmission', 'true');
      localStorage.setItem(
        'selfAdmissionProjectUrl',
        sessionStorage.getItem('selfAdmissionProjectUrl'),
      );
    }

    const userRole = isRedirectFromWorkspaceInvite ? 'squadOwner' : accountType;
    setLoading(true);
    await emailSignup(data.email, data.password, userRole);
  };

  return (
    <Formik
      onSubmit={handleRegister}
      initialValues={initFormValue}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleBlur, values, errors, touched }) => (
        <Form id="register-account-form">
          <InputWrapper>
            <InputIconLeft
              thisIconClass={'icon-material-baseline-mail-outline'}
              thisName={'email'}
              thisType={'text'}
              thisId={'emailaddress-register'}
              thisPlaceholder={emailInput[browserLanguage]}
              thisContainerId={
                isEmailAlreadyRegistered || !!(errors.email && touched.email)
                  ? 'error-input'
                  : null
              }
              thisTestId="testid-email-address-register"
              thisOnChange={handleChange}
              thisValue={values.email}
              thisOnBlur={handleBlur}
              thisOnFocus={() => setIsEmailAlreadyRegistered(false)}
            ></InputIconLeft>
            {!!(errors.email && touched.email) && (
              <ErrorMessage>{errors.email}</ErrorMessage>
            )}
          </InputWrapper>

          <InputWrapper>
            <InputIconLeft
              isPasswordInput
              thisIconClass={'icon-material-outline-lock'}
              thisEyeIcon={
                isTypePassword.password ? 'fas fa-eye' : 'fas fa-eye-slash'
              }
              thisSetEyeIcon={() =>
                setIsPassword({
                  ...isTypePassword,
                  password: !isTypePassword.password,
                })
              }
              thisContainerId={
                !!errors.password && !!touched.password ? 'error-input' : ''
              }
              thisName={'password'}
              thisType={isTypePassword.password ? 'password' : 'text'}
              thisId={'password-register'}
              thisPlaceholder={passwordInput[browserLanguage]}
              thisOnChange={handleChange}
              thisOnBlur={handleBlur}
              thisValue={values.password}
              thisTestId="testid-password-register"
            />
            {!!(errors.password && touched.password) && (
              <ErrorMessage>{errors.password}</ErrorMessage>
            )}
          </InputWrapper>

          <InputWrapper>
            <InputIconLeft
              isPasswordInput
              thisIconClass={'icon-material-outline-lock'}
              thisEyeIcon={
                isTypePassword.confirmPassword
                  ? 'fas fa-eye'
                  : 'fas fa-eye-slash'
              }
              thisSetEyeIcon={() =>
                setIsPassword({
                  ...isTypePassword,
                  confirmPassword: !isTypePassword.confirmPassword,
                })
              }
              thisName={'confirmPassword'}
              thisType={isTypePassword.confirmPassword ? 'password' : 'text'}
              thisId={'password-repeat-register'}
              thisPlaceholder={confirmPasswordInput[browserLanguage]}
              thisOnChange={handleChange}
              thisOnBlur={handleBlur}
              thisValue={values.confirmPassword}
              thisContainerId={
                !!errors.confirmPassword && !!touched.confirmPassword
                  ? 'error-input'
                  : ''
              }
              thisTestId="testid-confirm-password-register"
            />
            {!!(errors.confirmPassword && touched.confirmPassword) && (
              <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
            )}
          </InputWrapper>

          <div className="user-terms-container">
            <CheckboxHandMade
              thisChecked={isChecked}
              thisOnClick={() => setIsChecked(!isChecked)}
              isWhitOutLabel
            />
            <p>
              {useTerms.p[browserLanguage]}
              <a
                href="https://www.growyx.com/pt-br/termos-de-uso/"
                rel="noreferrer"
                target="_blank"
              >
                {useTerms.a1[browserLanguage]}
              </a>
              {useTerms.and[browserLanguage]}
              <a
                href="https://www.growyx.com/pt-br/politica-de-privacidade/"
                rel="noreferrer"
                target="_blank"
              >
                {useTerms.a2[browserLanguage]}
              </a>
            </p>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <Button
              thisText={btn[browserLanguage]}
              thisId="btn-register"
              thisDisabled={
                loading || Object.values(errors).some(error => !!error)
              }
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
