/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useState } from 'react';
import {
  ModalSelectWorkspaceContainer as SelectWorkspaceContainer,
  ModalSelectWorkspaceHeaderContent as HeaderContent,
  ModalSelectWorkspaceTitle as Title,
  ModalSelectWorkspaceIconButton as IconButton,
  WorkspacesWrapper,
  ModalWorkspaceHelperContainer as WorkspaceHelperContainer,
  WorkspaceHelperDescriptionWrapper as DescriptionHelperWrapper,
  WorkspaceHelperButtonsWrapper as ButtonsWrapper,
  ModalWorkspaceContainer,
  WorkspaceHelperContent,
} from './styles';
import { useAuth } from 'Contexts/AuthContext';
import { useModal } from 'AppV2/providers/ModalProvider';
import HelperIcon from 'AppV2/assets/icons/HelperIcon';
import { Button } from 'AppV2/components/Button';
import ExitIcon from 'AppV2/assets/icons/Exit';
import { ModalMain } from 'AppV2/components/Modal';
import ArrowLeftLongIcon from 'AppV2/assets/icons/ArrowLeftLong';
import ArrowLeftSolidIcon from 'AppV2/assets/icons/ArrowLeftSolid';
import ArrowRightSolidIcon from 'AppV2/assets/icons/ArrowRightSolid';
import CloseIcon from 'AppV2/assets/icons/Close';
import WorkspaceSVG from 'AppV2/assets/images/workspace.svg';
import EmailSendedSVG from 'AppV2/assets/images/emailSended.svg';
import TasksListSVG from 'AppV2/assets/images/tasksList.svg';
import CheckIcon from 'AppV2/assets/icons/CheckIcon';
import { translateText } from 'AppV2/common/translateText';
import { ProgressBulletPoints } from 'AppV2/components/ProgressBulletPoints';
import { WorkspaceCard } from './WorkspaceCard';
import { WorkspaceCardSkeleton } from './WorkspaceCard/WokspaceCardSkeleton';
import { WorkspacesProps } from '../../index';
import { SelectWorkspaceParams, useWorkspace } from 'AppV2/hooks/Workspace';

interface ModalProps {
  isOpen: boolean;
  headersWorkspaceId: string;
  isLoading: boolean;
  workspaces: WorkspacesProps[];
  onSelectWorkspace: (params: SelectWorkspaceParams) => void;
}

export const ModalSelectWorkspace = ({
  isOpen,
  headersWorkspaceId,
  isLoading,
  workspaces,
  onSelectWorkspace,
}: ModalProps): ReactElement => {
  // HOOKS
  const { closeModal } = useModal();
  const { logout } = useAuth();

  // STATES
  const [helperProgress, setHelperProgress] = useState(0);
  const [showHelper, setShowHelper] = useState(false);

  // VALUES
  const HelperNextButtonText =
    helperProgress === 2
      ? translateText('HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_FINISH_BUTTON')
      : translateText('HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_NEXT_BUTTON');
  const workspaceHelperDescriptions = [
    {
      image: WorkspaceSVG,
      description: (
        <p>
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_FIRST_DESCRIPTION_PART1',
          )}{' '}
          <strong>
            {translateText(
              'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_FIRST_DESCRIPTION_STRONG',
            )}
          </strong>
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_FIRST_DESCRIPTION_PART2',
          )}
        </p>
      ),
    },
    {
      image: EmailSendedSVG,
      description: (
        <p>
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_SECOND_DESCRIPTION',
          )}
        </p>
      ),
    },
    {
      image: TasksListSVG,
      description: (
        <p>
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_THIRD_DESCRIPTION_PART1',
          )}{' '}
          <strong>
            {translateText(
              'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_THIRD_DESCRIPTION_STRONG1',
            )}
          </strong>{' '}
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_THIRD_DESCRIPTION_PART2',
          )}{' '}
          <strong>
            {translateText(
              'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_THIRD_DESCRIPTION_STRONG2',
            )}
          </strong>{' '}
          {translateText(
            'HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_THIRD_DESCRIPTION_PART3',
          )}
        </p>
      ),
    },
  ];

  // FUNCTIONS
  const isCardSelected = (workspaceId: string) =>
    workspaceId === headersWorkspaceId;

  const handleSetHelperProgress = (operation: 'prev' | 'next') => {
    if (operation === 'prev') {
      return setHelperProgress(helperProgress - 1);
    }

    if (operation === 'next') {
      return setHelperProgress(helperProgress + 1);
    }
  };

  const handleShowHelper = () => setShowHelper(!showHelper);

  return (
    <ModalMain outPadding showButtonClose={false} isOpen={isOpen}>
      <ModalWorkspaceContainer>
        <SelectWorkspaceContainer isVisible={!showHelper}>
          <HeaderContent>
            <IconButton iconSize="21px" onClick={handleShowHelper}>
              <HelperIcon />
            </IconButton>

            <Title>
              {translateText(
                'HEADER_USER_MENU:MODAL_WORKSPACE_SELECTION_TITLE',
              )}
            </Title>

            <IconButton iconSize="18px" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </HeaderContent>

          {isLoading ? (
            <WorkspacesWrapper>
              <WorkspaceCardSkeleton />
              <WorkspaceCardSkeleton />
            </WorkspacesWrapper>
          ) : (
            <WorkspacesWrapper key={String(isLoading)}>
              {workspaces.map(workspace => (
                <WorkspaceCard
                  key={workspace.workspaceId}
                  workspaceId={workspace.workspaceId}
                  userName={workspace.userName}
                  userPhoto={workspace.userPhoto}
                  isOwnerCard={workspace.isOwner}
                  isSelected={isCardSelected(workspace.workspaceId)}
                  onSelectWorkspace={onSelectWorkspace}
                />
              ))}
            </WorkspacesWrapper>
          )}

          <Button full bordered onClick={() => logout()}>
            <ExitIcon />
            {translateText('HEADER_USER_MENU:MODAL_WORKSPACE_SELECTION_LOGOFF')}
          </Button>
        </SelectWorkspaceContainer>

        <WorkspaceHelperContainer isVisible={showHelper}>
          <HeaderContent>
            <IconButton iconSize="18px" onClick={handleShowHelper}>
              <ArrowLeftLongIcon />
            </IconButton>

            <Title>
              {translateText('HEADER_USER_MENU:MODAL_WORKSPACE_HELPER_TITLE')}
            </Title>

            <IconButton iconSize="18px" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </HeaderContent>

          <DescriptionHelperWrapper positionX={helperProgress}>
            <div className="carousel-content">
              {workspaceHelperDescriptions.map(helper => (
                <WorkspaceHelperContent key={String(helper.image)}>
                  <div className="illustration-content">
                    <img src={helper.image} />
                  </div>
                  <div className="description-content">
                    {helper.description}
                  </div>
                </WorkspaceHelperContent>
              ))}
            </div>
          </DescriptionHelperWrapper>

          <ButtonsWrapper>
            <Button
              bordered
              onClick={() => handleSetHelperProgress('prev')}
              disabled={helperProgress === 0}
            >
              <ArrowLeftSolidIcon />
            </Button>

            <ProgressBulletPoints totalSteps={3} currentStep={helperProgress} />

            <Button
              onClick={() =>
                helperProgress === 2
                  ? closeModal()
                  : handleSetHelperProgress('next')
              }
            >
              {HelperNextButtonText}
              {helperProgress === 2 ? <CheckIcon /> : <ArrowRightSolidIcon />}
            </Button>
          </ButtonsWrapper>
        </WorkspaceHelperContainer>
      </ModalWorkspaceContainer>
    </ModalMain>
  );
};
