/* eslint-disable no-case-declarations */
import { ActionTypes, ModalResultsReducer } from './types';

const modalResultInit = {
  isOpen: false,
  modalIndex: null,
};

const modalResultsReducer: ModalResultsReducer = (
  state = modalResultInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL_RESULTS:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default modalResultsReducer;
