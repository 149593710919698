/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function CheckIconV2({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Material/Check Tick OK">
        <path
          id="check"
          d="M7.16245 13.5L2.88745 9.22501L3.9562 8.15626L7.16245 11.3625L14.0437 4.48126L15.1125 5.55001L7.16245 13.5Z"
          fill={iconColor}
        />
      </g>
    </svg>
  );
}
