/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function MoneyIconOldVersion({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2305 6.44727C12.5066 6.44727 12.7305 6.67112 12.7305 6.94727V17.0525C12.7305 17.3287 12.5066 17.5525 12.2305 17.5525C11.9543 17.5525 11.7305 17.3287 11.7305 17.0525V6.94727C11.7305 6.67112 11.9543 6.44727 12.2305 6.44727Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59095 8.90199C9.98621 8.50672 10.5223 8.28467 11.0813 8.28467H14.5263C14.8024 8.28467 15.0263 8.50853 15.0263 8.78467C15.0263 9.06081 14.8024 9.28467 14.5263 9.28467H11.0813C10.7875 9.28467 10.5058 9.40137 10.2981 9.60909C10.0903 9.81682 9.97363 10.0986 9.97363 10.3923C9.97363 10.6861 10.0903 10.9678 10.2981 11.1756C10.5058 11.3833 10.7875 11.5 11.0813 11.5H13.3779C13.9369 11.5 14.473 11.722 14.8683 12.1173C15.2635 12.5126 15.4856 13.0486 15.4856 13.6076C15.4856 14.1666 15.2635 14.7027 14.8683 15.098C14.473 15.4932 13.9369 15.7153 13.3779 15.7153H9.47363C9.19749 15.7153 8.97363 15.4914 8.97363 15.2153C8.97363 14.9391 9.19749 14.7153 9.47363 14.7153H13.3779C13.6717 14.7153 13.9534 14.5986 14.1612 14.3909C14.3689 14.1831 14.4856 13.9014 14.4856 13.6076C14.4856 13.3139 14.3689 13.0321 14.1612 12.8244C13.9534 12.6167 13.6717 12.5 13.3779 12.5H11.0813C10.5223 12.5 9.98621 12.2779 9.59095 11.8827C9.19569 11.4874 8.97363 10.9513 8.97363 10.3923C8.97363 9.83334 9.19569 9.29725 9.59095 8.90199Z"
        fill={iconColor}
      />
      <path
        d="M12 2C6.486 2 2 6.485 2 12C2 12.51 2.039 13.021 2.115 13.519C2.175 13.916 2.544 14.193 2.943 14.127C3.34 14.066 3.612 13.695 3.552 13.299C3.487 12.873 3.455 12.438 3.455 12C3.455 7.288 7.288 3.454 12 3.454C16.712 3.454 20.546 7.288 20.546 12C20.546 16.713 16.712 20.546 12 20.546C9.327 20.546 6.858 19.33 5.225 17.21C4.979 16.893 4.524 16.832 4.206 17.078C3.888 17.322 3.828 17.779 4.073 18.098C5.983 20.577 8.873 22 12 22C17.514 22 22 17.514 22 12C22 6.485 17.514 2 12 2Z"
        fill={iconColor}
      />
    </svg>
  );
}
