import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_LOADING_PAGE = '@loading/SET',
}

export type LoadingPage = boolean;

export type LoadingPageReducer = Reducer<LoadingPage, Action<ActionTypes>>;
