import { Reducer } from 'redux';
import { Action } from 'Store/types';
export enum ActionTypes {
  SET_ACCOUNT_TYPE = '@accountType/SET',
}

export type AccountType = string;

export type AccountTypeReducer = Reducer<
  AccountType,
  Action<ActionTypes, AccountType>
>;
