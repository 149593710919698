import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { GetInvitedWorkspacesData } from './types';

export const getInvitedWorkspaces = async (): Promise<
  AxiosResponse<GetInvitedWorkspacesData>
> => {
  try {
    const response = await apiV2Instance.get('/workspace/invitedWorkspaces');

    console.log(
      '[services][Workspace][GetInvitedWorkspaces][RESPONSE]',
      response,
    );

    return response;
  } catch (error) {
    console.log('[services][Workspace][GetInvitedWorkspaces][ERROR]', error);
    throw error;
  }
};
