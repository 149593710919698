/* eslint-disable no-case-declarations */
import { ActionTypes, MemberDataReducer } from './types';

const memberDataReducer: MemberDataReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_MEMBER_DATA:
      const { payload } = action;

      return payload !== undefined ? payload : {};

    default:
      return state;
  }
};

export default memberDataReducer;
