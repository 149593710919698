import styled, { CSSObject, css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

export type Variation = 'primary' | 'secondary' | 'green';

interface Props {
  iconStyle?: CSSObject;
  styles?: CSSObject;
  variation?: Variation;
}

export const MessageBoxContent = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 100%;
  height: auto;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 6px;

  .icon-content {
    display: grid;
    place-items: center;
    height: 100%;
    svg {
      color: ${colors.purple};
      width: 24px;
      min-width: 24px;
      height: 24px;
      ${({ iconStyle }) => iconStyle};
    }
  }

  .message-content {
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0px;
      text-align: left;
    }
  }

  ${({ variation, iconStyle }) => {
    if (variation === 'primary') {
      return css`
        border-color: ${colors.purple};
        background-color: ${colors.lightPurple};
        .icon-content {
          svg {
            color: ${colors.purple};
            ${iconStyle};
          }
        }
        .message-content {
          h6 {
            color: ${colors.purple};
          }

          p {
            color: ${colors.purple};
          }
        }
      `;
    }

    if (variation === 'secondary') {
      return css`
        border-color: ${colors.faded};
        background-color: ${colors.background200};
        .icon-content {
          svg {
            color: ${colors.text300};
            ${iconStyle};
          }
        }
        .message-content {
          h6 {
            color: ${colors.text300};
          }

          p {
            color: ${colors.text300};
          }
        }
      `;
    }

    if (variation === 'green') {
      return css`
        border-color: ${colors.greenDark};
        background-color: ${colors.green100};
        .icon-content {
          svg {
            color: ${colors.greenDark};
            ${iconStyle};
          }
        }
        .message-content {
          h6 {
            color: ${colors.greenDark};
          }

          p {
            color: ${colors.greenDark};
          }
        }
      `;
    }
  }}

  ${({ styles }) => styles};
`;
