import { ActionTypes, FieldsFilledReducer } from './types';

/* eslint-disable no-case-declarations */
const fieldsFilledReducer: FieldsFilledReducer = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.SET_FIELDS_FILLED:
      return action.payload;

    default:
      return state;
  }
};

export default fieldsFilledReducer;
