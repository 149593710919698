/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function CloseCircleIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12.2842C23 18.3593 18.0751 23.2842 12 23.2842C5.92487 23.2842 1 18.3593 1 12.2842C1 6.20905 5.92487 1.28418 12 1.28418C18.0751 1.28418 23 6.20905 23 12.2842Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 8.57707C16.0976 8.9676 16.0976 9.60076 15.7071 9.99129L9.70711 15.9913C9.31658 16.3818 8.68342 16.3818 8.29289 15.9913C7.90237 15.6008 7.90237 14.9676 8.29289 14.5771L14.2929 8.57707C14.6834 8.18655 15.3166 8.18655 15.7071 8.57707Z"
        fill="#EAE8F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 8.57707C8.68342 8.18655 9.31658 8.18655 9.70711 8.57707L15.7071 14.5771C16.0976 14.9676 16.0976 15.6008 15.7071 15.9913C15.3166 16.3818 14.6834 16.3818 14.2929 15.9913L8.29289 9.99129C7.90237 9.60076 7.90237 8.9676 8.29289 8.57707Z"
        fill="#EAE8F4"
      />
    </svg>
  );
}
