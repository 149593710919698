import axios from 'axios';
import { setRefreshHeadersToken } from './helpers';

const apiV2Instance = axios.create({
  baseURL: process.env.REACT_APP_API_V2,
  headers: {
    Accept: 'application/json',
  },
});

apiV2Instance.interceptors.request.use(config =>
  setRefreshHeadersToken(config, apiV2Instance),
);

apiV2Instance.interceptors.response.use(value => {
  const workspaceId = value?.headers?.workspaceid;

  if (workspaceId) {
    sessionStorage.setItem('RESPONSE_HEADER_WORKSPACE_ID', workspaceId);
  }

  return value;
});

export { apiV2Instance };
