/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ScanIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3467 8.76204C4.3467 9.17389 4.0047 9.50926 3.5847 9.50926C3.1647 9.50926 2.8237 9.17389 2.8237 8.76204V6.90281C2.8237 4.75332 4.6067 3.00294 6.7977 3.00098L8.4267 3H8.4277C8.8477 3 9.1887 3.33439 9.1887 3.74722C9.1887 4.15908 8.8487 4.49346 8.4277 4.49346L6.7997 4.49444C5.4467 4.4964 4.3467 5.57605 4.3467 6.90281V8.76204ZM15.6008 4.49317H17.1938C18.5508 4.49317 19.6548 5.57576 19.6548 6.90644V8.76273C19.6548 9.1736 19.9958 9.50897 20.4158 9.50897C20.8358 9.50897 21.1768 9.1736 21.1768 8.76273V6.90644C21.1768 4.75205 19.3908 3.00069 17.1938 3.00069H15.6008C15.1808 3.00069 14.8398 3.33409 14.8398 3.74693C14.8398 4.15878 15.1808 4.49317 15.6008 4.49317ZM22.239 12.0412H1.761C1.342 12.0412 1 12.3756 1 12.7884C1 13.2003 1.342 13.5346 1.761 13.5346H2.823V17.0962C2.823 19.2467 4.607 20.9971 6.799 20.999L8.427 21C8.848 21 9.188 20.6656 9.189 20.2528C9.189 19.8409 8.848 19.5065 8.428 19.5065L6.8 19.5056C5.447 19.5036 4.346 18.4239 4.346 17.0962V13.5346H19.655V17.0933C19.655 18.4249 18.551 19.5065 17.194 19.5065H15.601C15.181 19.5065 14.84 19.8409 14.84 20.2528C14.84 20.6656 15.181 21 15.601 21H17.194C19.39 21 21.177 19.2486 21.177 17.0933V13.5346H22.239C22.659 13.5346 23 13.2003 23 12.7884C23 12.3756 22.659 12.0412 22.239 12.0412ZM10.1418 9.50926H13.8588C14.2798 9.50926 14.6208 9.17389 14.6208 8.76204C14.6208 8.35019 14.2798 8.0158 13.8588 8.0158H10.1418C9.7218 8.0158 9.3808 8.35019 9.3808 8.76204C9.3808 9.17389 9.7218 9.50926 10.1418 9.50926ZM10.1421 15.774H13.8591C14.2791 15.774 14.6201 16.1083 14.6201 16.5202C14.6201 16.933 14.2791 17.2664 13.8591 17.2664H10.1421C9.7211 17.2664 9.3801 16.933 9.3801 16.5202C9.3801 16.1083 9.7211 15.774 10.1421 15.774Z"
        fill={iconColor}
      />
    </svg>
  );
}
