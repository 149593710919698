import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_BACK_BUTTON_TEST_TO_PROFILE_PAGE = '@backButtonTestToProfilePage/SET',
}
export type BackButtonTestToProfilePage = any;

export type BackButtonTestToProfilePageReducer = Reducer<
  BackButtonTestToProfilePage,
  Action<ActionTypes>
>;
