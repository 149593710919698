import CheckIconCircle from './variations/circle';
import CheckIconDefault from './variations/default';
import CheckIconV2 from './variations/v2';

type CheckIconDefaultType = typeof CheckIconDefault;
type CheckIconV2Type = typeof CheckIconV2;
type CheckIconCircleType = typeof CheckIconCircle;

interface CompoundedComponent extends CheckIconDefaultType {
  V2: CheckIconV2Type;
  Circle: CheckIconCircleType;
}

const CheckIcon = CheckIconDefault as CompoundedComponent;
CheckIcon.V2 = CheckIconV2;
CheckIcon.Circle = CheckIconCircle;

export default CheckIcon;
