import styled, { css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';
import { UlColumn } from 'AppV2/styles/containers/ULColumn';
import { HeaderHeight } from '../Header/styles';

interface MenuProps {
  appVersionStyle?: 'V1' | 'V2';
  isOpen?: boolean;
}

interface LinkProps {
  iconSize?: number;
  isSelected?: boolean;
}

interface ULProps {
  appVersionStyle?: 'V1' | 'V2';
  isMenuOpen?: boolean;
  isSelected?: boolean;
}

export const MenuNavBarClosedWidth = '62px';
export const MenuNavBarOpenWidth = '202px';

export const MenuContent = styled.nav.attrs({
  className: 'cubicBezierAnimation nav-bar-menu',
})<MenuProps>`
  position: inherit;
  z-index: 9999;
  transition: 0.2s;

  background-color: ${colors.gray100};
  font-family: 'Mulish' !important;
  height: 100vh;
  width: ${({ isOpen }) =>
    isOpen ? MenuNavBarOpenWidth : MenuNavBarClosedWidth};
  min-width: ${({ isOpen }) =>
    isOpen ? MenuNavBarOpenWidth : MenuNavBarClosedWidth};
  overflow: initial;
  padding: 0px 0px 22px 0px;
  border-right: 1px solid ${colors.faded};

  .openclose-menu-button {
    display: none;
    opacity: 0;
  }
  :hover {
    .openclose-menu-button {
      display: flex;
      opacity: 0;
      scale: 0.1;
      animation: collapseButton 0.2s ease forwards;
      @keyframes collapseButton {
        to {
          opacity: 1;
          scale: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const OpenCloseMenuButton = styled.button.attrs({
  className: 'openclose-menu-button flexbox-center',
})<MenuProps>`
  position: absolute;
  z-index: 99999;
  top: 22px;
  right: -15px;

  line-height: initial;
  padding: 0px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${colors.background100};
  border: 1px solid ${colors.faded};
  box-shadow: 0px 18px 40px rgba(142, 121, 235, 0.35);
  transition: 0.3s;

  svg {
    width: 8px;
    min-width: 8px;
    height: 8px;
    color: ${colors.primary};
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(-1px)' : 'translateX(0px) rotate(180deg)'};
    transition: 0.3s;
  }

  :hover {
    background-color: ${colors.primary};
    border-color: transparent;
    svg {
      color: ${colors.textLight};
    }
  }
`;

export const LogoContent = styled.div.attrs({
  className: 'flexbox-center',
})<MenuProps>`
  height: ${HeaderHeight};
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  /* padding-left: ${({ isOpen }) => (isOpen ? '0px' : '16px')}; */
  padding: ${({ isOpen }) => (isOpen ? '0px' : '0px 8px')};
`;

export const ULinksList = styled(UlColumn)<ULProps>`
  .subtitle-content {
    padding-bottom: ${({ isMenuOpen }) => (isMenuOpen ? '16px' : '0px')};
    line-height: 12px;
    margin-bottom: 0px;
    .subtitle {
      position: relative;
      transition: 0.15s;
      font-weight: 500;
      font-size: 12px;
      color: ${({ isMenuOpen }) => (isMenuOpen ? colors.faded : 'transparent')};
      line-height: initial;
      padding-left: 24px;
      margin-bottom: ${({ isMenuOpen }) => (isMenuOpen ? '12px' : '6px')};
      margin-top: ${({ isMenuOpen }) => (isMenuOpen ? '0px' : '6px')};

      ::after {
        transition: 0.15s;
        content: '';
        position: absolute;
        bottom: 0px;
        left: 13px;
        height: 2px;
        width: ${({ isMenuOpen }) => (isMenuOpen ? '0px' : '38px')};
        background-color: ${({ isMenuOpen }) =>
          isMenuOpen ? 'transparent' : colors.faded};
      }
    }

    .first-subtitle {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
      margin-top: ${({ isMenuOpen }) => (isMenuOpen ? '12px' : '24px')};
      margin-bottom: ${({ isMenuOpen }) => (isMenuOpen ? '12px' : '0px')};
    }
  }
`;

export const Link = styled.li<ULProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  font-family: 'Mulish' !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.purple600};
  max-width: 100%;
  white-space: nowrap;
  padding: 16px 20.3px;
  padding-right: 4px;

  cursor: pointer;
  transition: 0.15s;
  .li-span {
    transition: 0.15s;
    padding-right: ${({ isMenuOpen }) => (isMenuOpen ? '16px' : 0)};
    width: ${({ isMenuOpen }) => (isMenuOpen ? 'auto' : 0)};
    opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
    overflow: ${({ isMenuOpen }) => (isMenuOpen ? 'inherit' : 'hidden')};
    white-space: ${({ isMenuOpen }) =>
      isMenuOpen ? 'break-spaces' : 'inherit'};
  }
  .nav-bar-hover-icon {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 4px;
    left: -4px;
    width: 4px;
    height: 81%;
    transition: 0.15s;
    svg {
      color: ${colors.purple600};
    }
  }
  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0px;
    height: 100%;
    max-width: 190px;
    border-top-right-radius: ${({ isMenuOpen }) => (isMenuOpen ? 50 : 0)}px;
    border-bottom-right-radius: ${({ isMenuOpen }) => (isMenuOpen ? 50 : 0)}px;
    background: linear-gradient(
      94.39deg,
      rgba(65, 37, 194, 0.1) 0%,
      rgba(65, 37, 194, 0.03) 71.05%,
      rgba(65, 37, 194, 0.1) 100%
    );
    transition: 0.3s;
  }
  :hover {
    .nav-bar-hover-icon {
      left: 0px;
    }
  }

  ${({ isSelected, isMenuOpen }) =>
    isSelected &&
    css`
      color: ${colors.primary};
      .nav-bar-hover-icon {
        left: 0px;
        svg {
          color: ${colors.primary};
        }
      }
      ::after {
        width: ${isMenuOpen ? '190px' : '100%'};
        background: linear-gradient(
          90deg,
          #e9e6f9,
          #e9e6f9,
          #f4f2fc,
          #e9e6f9,
          #e9e6f9,
          #e9e6f9
        );
        background-size: 1200% 1200%;
        animation: animGradienteScroll 5s linear infinite;
        @keyframes animGradienteScroll {
          0% {
            background-position: 0% 51%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
      }
      :hover {
        ::after {
          width: ${isMenuOpen ? '190px' : '100%'};
        }
      }
    `}

  @media screen and (max-height: 680px) {
    padding: 12px 20.3px;
  }
`;

export const LinkIconContent = styled.div<LinkProps>`
  width: ${({ iconSize }) => iconSize || '24'}px;
  min-width: ${({ iconSize }) => iconSize || '24'}px;
  height: ${({ iconSize }) => iconSize || '24'}px;
  margin-right: ${({ iconSize }) => 24 - iconSize}px;

  svg {
    color: ${colors.faded};
    width: 100%;
    min-width: 100%;
    height: 100%;
    ${({ iconSize }) =>
      iconSize &&
      css`
        transform: translateX(${22 - iconSize}px);
      `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      svg {
        color: ${colors.primary};
      }
    `}
`;

export const PostProjectButtonContent = styled.div<MenuProps>`
  position: absolute;
  display: flex;
  width: 100%;

  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        transition: bottom 0.6s ease-out;
        bottom: 12px;
      `;
    }

    return css`
      transition: bottom 0.3s;
      bottom: 0px;
    `;
  }}
`;

export const PostProjectButton = styled.button<MenuProps>`
  width: ${({ isOpen }) => (isOpen ? '178px' : '100%')};
  height: auto;
  padding: 12px;
  margin: 0 auto;
  color: ${colors.textLight} !important;
  border-radius: ${({ isOpen }) => (isOpen ? '6px' : '0px')};
  background: linear-gradient(146.16deg, #4886d9 35.79%, #927aff 117.07%);
  box-shadow: 0px 7px 13px rgba(50, 112, 194, 0.25);

  transition: 0.2s;
  position: relative;
  :hover {
    opacity: 0.9;
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const PopupContent = styled.div`
  width: 300px;
  padding: 12px;
  padding-top: 8px;
`;

export const PopUpCustomStyles = {
  top: '-124px',
  left: '12px',
};

export const PostProjectText = styled.span<MenuProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 100%;
  margin: 0px;
  color: ${colors.textLight};
  font-family: 'Mulish';
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  svg {
    width: 22px;
    min-width: 22px;
    height: 22px;
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      .span-text-content {
        display: none;
      }
    `}
`;
