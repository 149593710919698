import styled from 'styled-components';
import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';

export const HeaderNotificationHeight = '39px';

export const MotionProps = {
  initial: { height: 0, padding: 0 },
  animate: { height: HeaderNotificationHeight, padding: '8px 12px' },
  transition: { delay: 2 },
};

export const Container = styled(motion.div).attrs({ ...MotionProps })`
  width: 100%;
  height: ${HeaderNotificationHeight};
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  background-color: ${colors.yellow};
  overflow: hidden;

  .icon-wrapper {
    color: ${colors.textBlack};
    display: grid;
    place-items: center;
  }

  .header-notification-paragraph {
    color: ${colors.textBlack};
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0px;
  }
`;
