import { GrossNetMonthValuesReducer, ActionTypes } from './types';
/* eslint-disable no-case-declarations */

const INITIAL_VALUES = {
  grossValue: null,
  netValue: null,
};

const grossNetMonthValuesReducer: GrossNetMonthValuesReducer = (
  state = INITIAL_VALUES,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_GROSS_NET_MONTH_VALUES:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default grossNetMonthValuesReducer;
