import { ActionTypes, LanguagesReducer } from './types';

/* eslint-disable no-case-declarations */
const storagedLanguages = localStorage.getItem('userLanguages');

const userLanguagesInit: any[] = [];

const userLanguages = storagedLanguages
  ? JSON.parse(storagedLanguages)
  : userLanguagesInit;

const languagesReducer: LanguagesReducer = (state = userLanguages, action) => {
  switch (action.type) {
    case ActionTypes.ADD_LANGUAGES:
      return [...state, action.payload];

    case ActionTypes.SET_LANGUAGES:
      return action.payload;

    case ActionTypes.REMOVE_LANGUAGES:
      return action.payload;

    case ActionTypes.RESET_LANGUAGES:
      return action.payload;

    default:
      return state;
  }
};

export default languagesReducer;
