/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function PhoneIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="23"
      viewBox="0 0 15 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10424 19.7722C1.3404 20.0074 1.61592 20.125 1.9308 20.125H8.05884C7.77767 19.7688 7.54364 19.3736 7.36616 18.9489H1.9308V18.3608H7.16245C7.0579 17.9846 6.99595 17.5908 6.98213 17.1847H1.9308V5.81534H10.59V12.1825C10.9671 12.0717 11.3626 12.0039 11.7708 11.9848V4.05114C11.7708 3.7375 11.6528 3.46307 11.4166 3.22784C11.1804 2.99261 10.9049 2.875 10.59 2.875H1.9308C1.61592 2.875 1.3404 2.99261 1.10424 3.22784C0.86808 3.46307 0.75 3.7375 0.75 4.05114V18.9489C0.75 19.2625 0.86808 19.5369 1.10424 19.7722ZM10.59 4.6392H1.9308V4.05114H10.59V4.6392Z"
        fill={iconColor}
      />
      <path
        d="M8.89584 18.9271L9.90209 19.9334L11.9146 17.9448L13.9271 19.9334L14.9333 18.9271L12.9448 16.9146L14.9333 14.9021L13.9271 13.8959L11.9146 15.9084L9.90209 13.8959L8.89584 14.9021L10.9083 16.9146L8.89584 18.9271Z"
        fill={iconColor}
      />
    </svg>
  );
}
