import { ActionTypes, DatabasesReducer } from './types';

/* eslint-disable no-case-declarations */
const storagedDatabases = localStorage.getItem('userDatabases');

const userDatabasesInit: any[] = [];

const userDatabases = storagedDatabases
  ? JSON.parse(storagedDatabases)
  : userDatabasesInit;

const databasesReducer: DatabasesReducer = (state = userDatabases, action) => {
  switch (action.type) {
    case ActionTypes.ADD_DATABASES:
      return [...state, action.payload];

    case ActionTypes.SET_DATABASES:
      return action.payload;

    case ActionTypes.REMOVE_DATABASES:
      return action.payload;

    case ActionTypes.RESET_DATABASES:
      return action.payload;

    default:
      return state;
  }
};

export default databasesReducer;
