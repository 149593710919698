/* eslint-disable no-case-declarations */
import { ActionTypes, ModalFormReducer } from './types';

const modalFormInit = {
  isOpen: false,
  isEditForm: false,
  thisFormIndex: null,
  thisJobOrEducationIndex: null,
};

const modalFormReducer: ModalFormReducer = (state = modalFormInit, action) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL_FORM:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default modalFormReducer;
