enum WorkspacePathsEnum {
  balance = '/balance',
  dashboard = '/dashboard',
  dashboardSpentHoursAndCredits = '/dashboard/spentHoursAndCredits',
  dashboardSpentHoursGraph = '/dashboard/spentHoursGraph',
  dashboardSpentCreditsGraph = '/dashboard/spentCreditsGraph',
  dashboardFreelancersNotesData = '/dashboard/freelancersNotesData',
  dashboardProjectsNotesData = '/dashboard/projectsNotesData',
  dashboardFreelancersTasks = '/dashboard/freelancersTasks',
  dashboardSupportTickets = '/dashboard/supportTickets',
  grants = '/grants',
  myFreelancersReport = 'myFreelancers/:projectId/report/:userId',
  myProjectsGetHourConsumptioGraphData = '/myProjects/:projectId',
  myProjectsGetProjects = 'myProjectsGetProjects',
  onboardingGetAll = '/onboardingAll',
  onboarding = '/onboarding/:onboardId',
  onboardingFileupload = '/onboarding/:onboardId/fileupload',
  onboardingCancelSchedule = '/onboarding/cancelSchedule',
  opportunities = '/opportunities',
  projectBudgetNegotiationAddBudgetNegotiationHistoryItemSquadMember = '/:projectId/squadMemberInteraction',
  projectBudgetNegotiationAddBudgetNegotiationHistoryItemSquadOwner = '/:projectId/:positionIndex/squadOwnerInteraction',
  projectBudgetNegotiationGetProjectNecessaryInfosForNegotiation = '/:projectId/necessaryInfos',
  projectBudgetNegotiationGetLastSquadMemberInteraction = '/:projectId/:positionIndex/lastSquadMemberInteraction',
  projectBudgetNegotiationGetLastSquadOwnerInteraction = '/:projectId/lastSquadOwnerInteraction',
  projectChangeProjectOwner = '/:projectId/changeProjectOwner/:squadOwnerId',
  projectIntegrateGrowyxProjectWithClockifyProject = '/:projectId/integrateWithClockifyProject',
  projectUpdateClockifyProjectMemberships = '/:projectId/updateClockifyProjectMemberships',
  projectTimeControlReport = '/:projectId/timeControlReport',
  projectTaskReportData = '/:projectId/timeControlData',
  projectHourConsumption = '/:projectId/hourConsumption/:memberId/:month',
  projectBlockMemberPayment = '/:projectId/blockPayment',
  projectMySquads = '/mySquads',
  projectUpdate = '/projectUpdate/:projectId',
  projectDeactiveSquadMember = '/:projectId/deactiveSquadMember',
  support = '/support',
  supportGetSupport = '/support/:supportId',
  supportGetSquad = '/supports/getSquad',
  upsell = 'upsell/:projectId',
  userFindOwnerByEmail = '/findowner/:mail',
  workspaceGetWorkspace = '/workspace/:workspaceId',
  workspaceCreateWorkspace = '/workspace/createWorkspace',
  workspaceGetUsers = '/workspace/getUsers',
  workspaceAddUser = '/workspace/addUser',
  workspaceRemoveUser = '/workspace/removeUser',
  workspaceUpdateUserPermission = '/workspace/updateUserPermission',
  workspaceRemoveResource = '/workspace/:workspaceId/removeResource',
  workspaceInvitedWorkspaces = '/workspace/invitedWorkspaces',
  workspaceSelect = '/workspace/:workspaceId/select',
  projectPost = '/project/post',
  projectPut = '/project/put',
  suggestedProfessionalsDashboard = '/dashboard/suggestedProfessionals',
  suggestedProfessionalsSelect = '/myProjects/selectFreelancer/:projectId/:positionIndex/:freelancerId',
  suggestedProfessionalsDeselect = '/myProjects/removeFreelancer/:projectId/:positionIndex/:freelancerId',
  suggestedProfessionalUserInfo = '/suggestedProfessionalsUserInfo/:projectId/:positionIndex/:professionalId',

  createTaskManagement = '/createTaskManagement',
  postTask = '/:projectId/postTask',
  deleteTask = '/deleteTask/:projectId/:taskId',
  updateTaskTitle = '/:projectId/:taskId/title',
  updateTaskDescription = '/:projectId/:taskId/description',

  addUserToTask = '/:projectId/:taskId/assigned',
  updateTaskEstimatedHours = '/:projectId/:taskId/estimatedHours',
  updateTaskMoveCard = '/:projectId/:taskId/status',
  updateTaskTags = '/:projectId/:taskId/tags',
  addAttachmentToTask = '/:projectId/:taskId/attachments',
  deleteTaskAttachment = '/:projectId/:taskId/attachments/:attachmentId',

  acceptTask = '/:projectId/:taskId/accept',
  adjustTask = '/:projectId/:taskId/adjust',
  rejectTask = '/:projectId/:taskId/reject',

  findTaskById = '/:projectId/:taskId',
  getTaskManagement = '/getAllProjects',
  getTasksFinishedProjects = '/getAllFinishedProjects',
  getTasksByProjectId = '/:projectId',

  getProjectTimeEntries = 'timeEntries/project',
  getTimeEntriesReport = '/timeEntrieReport/:projectId/:month/:exportFormat',
  patchContestTimeEntry = 'timeEntries/contestTimeEntry',

  getMatchmakings = '/matchmaking',
  getMatchmakingResultsView = '/userDetailMatch/:matchmakingId/:userId',
}

const allowedWorkspaceRoutes = [
  // default allowed paths
  WorkspacePathsEnum.balance,
  WorkspacePathsEnum.upsell,
  WorkspacePathsEnum.myProjectsGetProjects,
  WorkspacePathsEnum.myProjectsGetHourConsumptioGraphData,
  WorkspacePathsEnum.projectTimeControlReport,
  WorkspacePathsEnum.myFreelancersReport,
  WorkspacePathsEnum.suggestedProfessionalUserInfo,
  WorkspacePathsEnum.getTimeEntriesReport,
];

export { WorkspacePathsEnum, allowedWorkspaceRoutes };
