import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_TOOL_TIP = '@toolTip/SET',
}

export type ToolTip = any;

export type ToolTipReducer = Reducer<ToolTip, Action<ActionTypes, ToolTip>>;
