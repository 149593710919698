import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_GROSS_NET_MONTH_VALUES = '@grossNetMonthValues/SET',
}

export type GrossNetMonthValues = {
  grossValue: null;
  netValue: null;
};

export type GrossNetMonthValuesReducer = Reducer<
  GrossNetMonthValues,
  Action<ActionTypes, GrossNetMonthValues>
>;
