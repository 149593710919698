import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_METHODOLOGIES = '@methodologies/ADD',
  SET_METHODOLOGIES = '@methodologies/SET',
  REMOVE_METHODOLOGIES = '@methodologies/REMOVE',
  RESET_METHODOLOGIES = '@methodologies/RESET',
}

export type Methodologies = any[];

export type MethodologiesExpertise = {
  tag: string;
  expertise: number;
}[];

export type MethodologiesReducer = Reducer<Methodologies, Action<ActionTypes>>;
