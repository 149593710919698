import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { UserWalletData } from './types';

export const getWallet = async (): Promise<AxiosResponse<UserWalletData>> => {
  try {
    const res = await apiV2Instance.get('balance?wallet=true');

    // console.log('[services][balance][getwalletData.res]', res);
    return res;
  } catch (error) {
    console.error('[services][balance][getwalletData.ERROR]', error);
    throw error;
  }
};
