/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';

export const IllustrationSVG = (): ReactElement => {
  return (
    <svg
      width="313"
      height="48"
      viewBox="0 0 313 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7393 41.2636C79.2321 41.7545 79.8071 42 80.4643 42H93.2532C92.6664 41.2565 92.178 40.4318 91.8076 39.5455H80.4643V38.3182H91.3825C91.1643 37.5332 91.035 36.7111 91.0062 35.8636H80.4643V12.1364H98.5357V25.4243C99.3227 25.1931 100.148 25.0516 101 25.0117V8.45455C101 7.8 100.754 7.22727 100.261 6.73636C99.7679 6.24545 99.1929 6 98.5357 6H80.4643C79.8071 6 79.2321 6.24545 78.7393 6.73636C78.2464 7.22727 78 7.8 78 8.45455V39.5455C78 40.2 78.2464 40.7727 78.7393 41.2636ZM98.5357 9.68182H80.4643V8.45455H98.5357V9.68182Z"
        fill="#F0A6A6"
      />
      <path
        d="M95 39.5L97.1 41.6L101.3 37.45L105.5 41.6L107.6 39.5L103.45 35.3L107.6 31.1L105.5 29L101.3 33.2L97.1 29L95 31.1L99.2 35.3L95 39.5Z"
        fill="#F94D4D"
      />
      <rect
        opacity="0.25"
        x="114"
        y="20"
        width="8"
        height="8"
        rx="4"
        fill="#7A6EB3"
      />
      <rect
        opacity="0.5"
        x="134"
        y="20"
        width="8"
        height="8"
        rx="4"
        fill="#7A6EB3"
      />
      <rect
        opacity="0.75"
        x="154"
        y="20"
        width="8"
        height="8"
        rx="4"
        fill="#7A6EB3"
      />
      <rect x="174" y="20" width="8" height="8" rx="4" fill="#7A6EB3" />
      <path
        d="M203 42V39H213V32H197.5C196.7 32 196 31.7 195.4 31.1C194.8 30.5 194.5 29.8 194.5 29V9C194.5 8.2 194.8 7.5 195.4 6.9C196 6.3 196.7 6 197.5 6H231.5C232.3 6 233 6.3 233.6 6.9C234.2 7.5 234.5 8.2 234.5 9V29C234.5 29.8 234.2 30.5 233.6 31.1C233 31.7 232.3 32 231.5 32H216V39H226V42H203ZM197.5 29H231.5V9H197.5V29Z"
        fill="#7A6EB3"
      />
    </svg>
  );
};
