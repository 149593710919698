import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypesProfilePicture {
  SET_PROFILE_PICTURE = '@profilePicture/SET',
}

export enum ActionTypesReloadProfilePicture {
  RELOAD_PROFILE_PICTURE = '@profilePicture/RELOAD',
}

export type ProfilePicture = {
  isOpenCropModal: boolean;
  url: string;
  file: string;
};

export type ProfilePictureReducer = Reducer<
  ProfilePicture,
  Action<ActionTypesProfilePicture>
>;
export type ReloadProfilePicture = boolean;

export type ReloadProfilePictureReducer = Reducer<
  ReloadProfilePicture,
  Action<ActionTypesReloadProfilePicture>
>;
