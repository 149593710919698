import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_PROFILE_LEVEL = '@profileLevel/SET',
}

export type ProfileLevel = {
  myProfile: number;
  experiences: number;
  educations: number;
  skills: number;
  languages: number;
  values: number;
};

export type ProfileLevelReducer = Reducer<ProfileLevel, Action<ActionTypes>>;
