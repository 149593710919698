import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_ADMIN_DATA = '@adminData/SET',
}
type Personal = {
  firstName: string;
  lastName: string;
  displayName: string;
  dob: string;
};

export type AdminData = null | {
  personal: Personal;
};

export type AdminReducer = Reducer<AdminData, Action<ActionTypes, AdminData>>;
