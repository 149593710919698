import { ThunkActionReturn } from 'Store/types';
import setLoginOrSignupForm from './action';

const setLoginOrSignupFormThunk = (isLoginForm: boolean): ThunkActionReturn => {
  return dispatch => {
    dispatch(setLoginOrSignupForm(isLoginForm));
  };
};

export default setLoginOrSignupFormThunk;
