import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const useGaTracker = () => {
  const location = useLocation();
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [location]);
};

export default useGaTracker;
