import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_IS_AVAILABLE = '@userIsAvailable/SET',
}
export type UserIsAvailable = boolean;

export type UserIsAvailableReducer = Reducer<
  UserIsAvailable,
  Action<ActionTypes>
>;
