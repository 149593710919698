/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function ArrowsUpDownIconDefault({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9876 13.2816C3.9876 13.7033 4.32598 14.0456 4.74292 14.0456C5.15986 14.0456 5.49824 13.7033 5.49824 13.2816V2.6483L7.9042 5.09116C8.19828 5.39066 8.67564 5.39066 8.97173 5.0932C9.26781 4.79574 9.26882 4.31185 8.97374 4.01235L5.27769 0.258414C5.06217 0.0383735 4.73587 -0.0258051 4.45489 0.0913463C4.1719 0.209516 3.9876 0.487623 3.9876 0.79731V13.2816ZM1.04647 5.31589C0.854111 5.31589 0.660748 5.24152 0.513712 5.09279C0.217625 4.79533 0.216618 4.31246 0.511697 4.01296L1.98206 2.51852C2.27714 2.21902 2.75451 2.21902 3.05059 2.51648C3.34567 2.81394 3.34668 3.29783 3.05261 3.59733L1.58124 5.09075C1.4342 5.24152 1.23983 5.31589 1.04647 5.31589ZM13.4534 16.4756C13.5461 16.5143 13.6438 16.5337 13.7414 16.5337C13.9388 16.5337 14.1322 16.4563 14.2762 16.3086L17.4395 13.0945C17.7336 12.795 17.7326 12.3111 17.4375 12.0137C17.1414 11.7162 16.663 11.7172 16.369 12.0157L14.4968 13.9197V3.28438C14.4968 2.86264 14.1594 2.52035 13.7414 2.52035C13.3245 2.52035 12.9861 2.86264 12.9861 3.28438V15.7697C12.9861 16.0793 13.1704 16.3575 13.4534 16.4756ZM10.98 14.0472C11.128 14.198 11.3224 14.2724 11.5158 14.2724C11.7081 14.2724 11.9015 14.198 12.0485 14.0493C12.3446 13.7518 12.3456 13.2689 12.0515 12.9694L10.5812 11.475C10.2851 11.1755 9.80772 11.1755 9.51264 11.473C9.21655 11.7704 9.21554 12.2543 9.50962 12.5538L10.98 14.0472Z"
        fill={iconColor}
      />
    </svg>
  );
}
