/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useEffect, useState } from 'react';
import { UserMenuContainer } from './styles';
import { Avatar } from 'AppV2/components/Avatar';
import { HeaderOwnerWallet } from './HeaderOwnerWallet';
import { colors } from 'AppV2/styles/colors';
import { UserRoleEnum, UserRoleType } from 'AppV2/interfaces/userRole';
import { PopUpWorkspacePresentation } from './PopUpWorkspacePresentation';
import { useModal } from 'AppV2/providers/ModalProvider';
import { useCookies } from 'react-cookie';
import { useAuth } from 'Contexts/AuthContext';
import { translateText } from 'AppV2/common/translateText';
import { WidjetDropDownOptions } from './WidjetDropDownOptions';
import moment from 'moment-timezone';

export const AppLanguageModalKey = 'AppLanguageModal';
export const WorkspaceModalKey = 'WorkspaceModal';

const WorkspacePopupWatchedByThisSquadOwnerCookieKey =
  'workspacePopupWatchedByThisSquadOwner:userId:';

interface Props {
  userRole: UserRoleType;
  userName: string;
  userPhoto: string;
  isAnotherWorkspaceSelected: boolean;
}

export const UserMenu = ({
  userRole,
  userName,
  userPhoto,
  isAnotherWorkspaceSelected,
}: Props): ReactElement => {
  // HOOKS
  const { setOpenModal } = useModal();
  const { currentUser } = useAuth();

  // COOKIES
  const WorkspacePopUpWatchedCookieKey = `${WorkspacePopupWatchedByThisSquadOwnerCookieKey}${currentUser?.uid}`;
  const [cookie, setCookie] = useCookies([WorkspacePopUpWatchedCookieKey]);

  // STATES
  const [openPopUpWorkspacePresentation, setOpenPopUpWorkspacePresentation] =
    useState(false);

  // VALUES
  const isSquadOwner = userRole === UserRoleEnum.squadOwner;
  const isMatchmakingTrial = !!currentUser?.claims?.matchmakingTrial;

  const shouldShowHeaderOwnerWallet =
    isSquadOwner && !isAnotherWorkspaceSelected;

  const shouldShowAnotherWorkspaceLabel =
    isSquadOwner && isAnotherWorkspaceSelected;

  // FUNCTIONS
  const handleCloseWorskpacePopUp = () =>
    setOpenPopUpWorkspacePresentation(false);

  const handleOpenWorkspaceModal = () =>
    isSquadOwner &&
    setOpenModal({
      key: WorkspaceModalKey,
    });

  // EFFECTS
  useEffect(() => {
    if (!cookie[WorkspacePopUpWatchedCookieKey]) {
      setCookie(WorkspacePopUpWatchedCookieKey, 'true', {
        expires: new Date(moment.utc(new Date()).add(1, 'year').valueOf()),
      });
    }
  }, [WorkspacePopUpWatchedCookieKey, cookie, setCookie]);

  useEffect(() => {
    if (isSquadOwner && !cookie[WorkspacePopUpWatchedCookieKey]) {
      if (!isMatchmakingTrial) {
        setTimeout(() => {
          setOpenPopUpWorkspacePresentation(true);
        }, 3000);
      }
    }
  }, [
    WorkspacePopUpWatchedCookieKey,
    cookie,
    isSquadOwner,
    isMatchmakingTrial,
  ]);

  return (
    <UserMenuContainer
      isWorkspaceSelected={isAnotherWorkspaceSelected}
      isSquadOwner={isSquadOwner}
    >
      <div
        className="avatar-username-content"
        onClick={handleOpenWorkspaceModal}
      >
        <Avatar
          width="32px"
          url={userPhoto}
          borderColor={!userPhoto && colors.darkGray}
        />
        <div className="workspace-name-content">
          <h3 className="username">{userName || 'Grower'}</h3>
          {shouldShowHeaderOwnerWallet && <HeaderOwnerWallet />}
          {shouldShowAnotherWorkspaceLabel && (
            <label className="anotherworkspace-label">
              {translateText('HEADER_USER_MENU:SPACE_OF')}
            </label>
          )}
        </div>
      </div>

      <WidjetDropDownOptions userRole={userRole} />

      {openPopUpWorkspacePresentation && (
        <PopUpWorkspacePresentation handleClose={handleCloseWorskpacePopUp} />
      )}
    </UserMenuContainer>
  );
};
