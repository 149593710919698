import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { WorkspaceIdCookieKey } from 'AppV2/services/Workspace';
import { useAuth } from 'Contexts/AuthContext';
import { useWorkspace } from 'AppV2/hooks/Workspace';
import { useAppLanguage } from 'AppV2/hooks/AppLanguage';
import { AppLanguageCookieKey } from 'AppV2/hooks/AppLanguage/types';
import { useScreenDetect } from 'AppV2/hooks/ScreenDetect';
import { useProfessionsGroup } from 'AppV2/hooks/ProfessionsGroup';
import useGaTracker from 'Analytics/useGaTracker';
import useGoogleTagManager from 'Analytics/useGoogleTagManager';
import { useTemplatesConfig } from 'AppV2/hooks/TemplatesConfig';

export const useAppHelper = () => {
  // HOOKS
  const {
    shouldShowHeader,
    shouldShowNavBar,
    defaultAxiosHeaders,
    isAppV2Paths,
    currentUser,
  } = useAuth();
  const { isMobile } = useScreenDetect();
  const templatesConfig = useTemplatesConfig();
  const { isFetching } = useProfessionsGroup();
  const { changeAppLanguage } = useAppLanguage();
  const { isAnotherWorkspaceSelected } = useWorkspace();

  // STATES - VALUES - COOKIES
  const appFragmentKey = `growyxApp@templateKey:${templatesConfig.templateKey}_isAppV2Path:${isAppV2Paths}_isMobile:${isMobile}_showNavBar:${shouldShowNavBar}_showHeader:${shouldShowHeader}`;
  const [cookie] = useCookies([AppLanguageCookieKey, WorkspaceIdCookieKey]);
  const isCurrentUserInAnotherWorkspace =
    isAnotherWorkspaceSelected(currentUser);

  // GOOGLE ANALYTICS
  useGaTracker();
  useGoogleTagManager();

  // EFFECTS
  useEffect(() => {
    const appLanguageCookie = cookie[AppLanguageCookieKey];
    if (appLanguageCookie) {
      changeAppLanguage(appLanguageCookie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie]);

  return {
    isFetching,
    isMobile,
    templatesConfig,
    appFragmentKey,
    isCurrentUserInAnotherWorkspace,
    shouldShowHeader,
    shouldShowNavBar,
    defaultAxiosHeaders,
    isAppV2Paths,
  };
};
