/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function EditSquareIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35305 10.1942L5.2599 13.9042C5.25589 14.1022 5.33102 14.2932 5.46924 14.4352C5.60847 14.5782 5.79878 14.6582 5.99711 14.6582H9.68313C10.4494 14.6582 11.1706 14.3602 11.7135 13.8182L19.0975 6.4461C19.6805 5.86509 20 5.09208 20 4.27006C20 3.45005 19.6805 2.67604 19.0975 2.09503L17.9006 0.900014C16.6986 -0.300005 14.7444 -0.300005 13.5434 0.900014L6.19343 8.23812C5.67057 8.76013 5.37208 9.45514 5.35305 10.1942ZM6.82746 10.2312C6.83748 9.87215 6.98172 9.53314 7.23713 9.27914L12.9385 3.58605L14.8115 5.45808C14.9558 5.60108 15.1441 5.67308 15.3334 5.67308C15.5217 5.67308 15.711 5.60108 15.8552 5.45808C16.1427 5.16908 16.1427 4.70407 15.8552 4.41607L13.9812 2.54504L14.5861 1.94103C15.2122 1.31602 16.2308 1.31602 16.8579 1.94103L18.0548 3.13705C18.6808 3.76206 18.6808 4.77907 18.0548 5.40508L10.6707 12.7772C10.4063 13.0412 10.0557 13.1852 9.68313 13.1852H6.75434L6.82746 10.2312ZM0.738407 10.4431C1.14507 10.4431 1.47561 10.1131 1.47561 9.70705V5.93799C1.47561 3.24795 3.1173 1.50992 5.65845 1.50992H9.33546C9.74313 1.50992 10.0737 1.18092 10.0737 0.773912C10.0737 0.367905 9.74313 0.0379006 9.33546 0.0379006H5.65845C2.27392 0.0379006 0.000200328 2.40894 0.000200328 5.93799V9.70705C0.000200328 10.1131 0.330741 10.4431 0.738407 10.4431ZM19.2585 9.40984C18.8508 9.40984 18.5213 9.73885 18.5213 10.1459V14.0999C18.5213 16.79 16.8786 18.527 14.3364 18.527H5.65825C3.1171 18.527 1.47641 16.79 1.47641 14.0999C1.47641 13.6939 1.14587 13.3639 0.738207 13.3639C0.331542 13.3639 0 13.6939 0 14.0999C0 17.629 2.27372 20 5.65825 20H14.3364C17.722 20 19.9957 17.629 19.9957 14.0999V10.1459C19.9957 9.73885 19.6652 9.40984 19.2585 9.40984Z"
        fill={iconColor}
      />
    </svg>
  );
}
