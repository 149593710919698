import { UserForbiddens } from '../GetUsers/types';

export const GetInvitedWorkspacesQueryCacheKey = 'GetInvitedWorkspaces';

export type InvitedRepository = {
  workspaceId: string;
  companyName: string;
  uid: string;
  displayName: string;
  userPhoto: string;
  forbiddens: UserForbiddens[];
};

export interface GetInvitedWorkspacesData {
  invitedRepository: InvitedRepository[];
  isSuccess: boolean;
  message: string;
  userId: string;
}
