/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../../interfaces/iconProps';

export default function CheckIconDefault({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6673 8.91984C18.6673 9.30209 18.3566 9.61284 17.9743 9.61284H17.9661V9.59634C17.5792 9.59634 17.2657 9.28376 17.2648 8.89692V8.89509V6.56492C17.2648 3.76634 15.734 2.23551 12.9437 2.23551H6.06865C3.27648 2.23551 1.73648 3.77551 1.73648 6.56492V13.4399C1.73648 16.2211 3.27648 17.7611 6.0659 17.7611H12.9409C15.7303 17.7611 17.2621 16.2211 17.2621 13.4399C17.2621 13.0531 17.5756 12.7387 17.9633 12.7387C18.3511 12.7387 18.6646 13.0531 18.6646 13.4399C18.6673 16.9737 16.4746 19.1663 12.9437 19.1663H6.0659C2.52665 19.1663 0.333984 16.9737 0.333984 13.4427V6.56767C0.333984 3.02567 2.52665 0.833008 6.0659 0.833008H12.9409C16.4582 0.833008 18.6673 3.02567 18.6673 6.56492V8.91984ZM8.41312 11.2037L12.2778 7.33813C12.5464 7.06955 12.9818 7.06955 13.2504 7.33813C13.519 7.60672 13.519 8.04213 13.2504 8.31072L8.89895 12.6621C8.7697 12.7905 8.59462 12.8629 8.41312 12.8629C8.22978 12.8629 8.05562 12.7905 7.92637 12.6621L5.75112 10.486C5.48253 10.2174 5.48253 9.78197 5.75112 9.51338C6.0197 9.2448 6.45512 9.2448 6.7237 9.51338L8.41312 11.2037Z"
        fill={iconColor}
      />
    </svg>
  );
}
