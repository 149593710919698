import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_CMS_SQUAD_SELECTION_SET = '@CMSSquadSelection/SET',
}

export type CMSSquadSelection = {
  isFilter: false;
  searchJSON: null;
};

export type CMSSquadSelectionReducer = Reducer<
  CMSSquadSelection,
  Action<ActionTypes, CMSSquadSelection>
>;
