import styled from 'styled-components';
import { colors } from 'AppV2/styles/colors';
import spinnerPNG from 'AppV2/assets/icons/spinner.png';

interface Props {
  color: string;
}

export const Container = styled.div.attrs({
  className: 'spinner',
})<Props>`
  border-radius: 50% !important;
  border: ${({ color }) => `4px solid ${color || colors.primary}`};
  border-top-color: ${colors.faded};

  margin: 0 auto;
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;

  transition: opacity 200ms;
  animation: spin 1s infinite linear;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerSVG = styled.img.attrs({
  src: spinnerPNG,
  className: 'spinner',
})`
  margin: 0 auto;
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  max-width: 16px !important;
  max-height: 16px !important;

  animation: spin 1s infinite linear;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
