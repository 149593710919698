/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ArrowLeftLongIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.594 7.75538C16.9637 7.70838 17.25 7.38799 17.25 7.00015C17.25 6.57999 16.914 6.239 16.5 6.239L3.316 6.239L8.079 1.42544L8.14638 1.3477C8.37097 1.05003 8.34867 0.622165 8.081 0.348656C7.789 0.0512972 7.314 0.0502825 7.021 0.346626L0.971 6.46125L0.904282 6.53782C0.802778 6.67173 0.75 6.83524 0.75 7.00015C0.75 7.0986 0.769 7.19704 0.807 7.29041C0.923 7.57559 1.196 7.76131 1.5 7.76131L16.5 7.76131L16.594 7.75538ZM8.0811 13.6513C8.3731 13.353 8.3731 12.8709 8.0791 12.5746L4.7421 9.20213L4.66506 9.13401C4.37036 8.907 3.94977 8.93065 3.6821 9.20416C3.5361 9.35335 3.4631 9.54719 3.4631 9.74103C3.4631 9.9369 3.5361 10.1318 3.6841 10.2809L7.0201 13.6534L7.09716 13.7213C7.392 13.9475 7.81343 13.9239 8.0811 13.6513Z"
        fill={iconColor}
      />
    </svg>
  );
}
