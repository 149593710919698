/* eslint-disable no-case-declarations */
import { ActionTypes, ScorePerfilCategoryReducer } from './types';

const scorePerfilCategoryInit = 'Básico';

const scorePerfilCategoryReducer: ScorePerfilCategoryReducer = (
  state = scorePerfilCategoryInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_SCORE_PERFIL_CATEGORY:
      const { payload } = action;
      return payload;
    default:
      return state;
  }
};

export default scorePerfilCategoryReducer;
