/* eslint-disable no-case-declarations */
import { ActionTypes, SkipFormsReducer } from './types';

const skipFormReducer: SkipFormsReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_SKIP_FORM:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default skipFormReducer;
