/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { memo, useEffect, useState } from 'react';
import { UserRoleEnum, UserRoleType } from 'AppV2/interfaces/userRole';
import { OwnerViewMenuNavBar } from './ViewOwner';
import { MemberViewMenuNavBar } from './ViewMember';
import { AdminViewMenuNavBar } from './ViewAdmin';
import { AnonymousViewMenuNavBar } from './ViewAnonymous';
import { useLocation, useHistory } from 'react-router-dom';
import { translateText } from 'AppV2/common/translateText';
import eventGaTracker from 'Analytics/eventGaTracker';
import { useAuth } from 'Contexts/AuthContext';
import { WorkspacePathsEnum } from 'AppV2/assets/allWorkspacePaths';
import { useModal } from 'AppV2/providers/ModalProvider';
import { useMenuNavProps } from 'AppV2/providers/MenuNavProvider';

export interface MenuNavBarProps {
  userRole?: UserRoleType;
  appVersionStyle?: 'V1' | 'V2';
  isMenuOpen?: boolean;
  openTooltip?: string;
  openCloseMenuTooltipText?: string;
  isLinkSelected?: (path: string) => boolean;
  handleOpenTooltip?: (toolTipKey: string) => void;
  handleOpenCloseMenu?: () => void;
  handleRedirect?: (
    path: string,
    linkName: string,
    userRole: UserRoleType | 'anonymous',
  ) => void;
  isAnotherWorkspaceSelected?: boolean;
  workspaceSelectedPermissions?: WorkspacePathsEnum[];
}

export const MenuNavBar = memo(
  ({
    userRole,
    appVersionStyle = 'V2',
    isAnotherWorkspaceSelected,
  }: MenuNavBarProps) => {
    // HOOKS
    const { isMenuOpen, handleOpenCloseMenu, setMenuPositionCookie } =
      useMenuNavProps();
    const location = useLocation();
    const history = useHistory();
    const { closeAlert } = useModal();
    const { workspaceSelectedForbiddens } = useAuth();

    // STATES
    const [openTooltip, setOpenTooltip] = useState('');

    const openCloseMenuTooltipText = translateText(
      `MENU_NAV_BAR_LINKS:${
        isMenuOpen ? 'CLOSE_MENU_TOOLTIP' : 'OPEN_MENU_TOOLTIP'
      }`,
    );

    // FUNCTIONS
    const handleOpenTooltip = (toolTipKey: string) =>
      setOpenTooltip(toolTipKey);

    const handleRedirect = async (
      path: string,
      linkName: string,
      userRole: UserRoleEnum | 'anonymous',
    ) => {
      eventGaTracker(
        'Menu Lateral',
        `Click button: Menu ${translateText(linkName)} - ${userRole}`,
      );
      closeAlert();

      return history.push(path);
    };

    const isLinkSelected = (path: string) => {
      const locationPathName = location.pathname;

      if (
        locationPathName === '/tests-resumes' &&
        path === '/tests-selection'
      ) {
        return true;
      }

      return locationPathName.startsWith(path);
    };

    const menuProps = {
      appVersionStyle: appVersionStyle,
      isMenuOpen: isMenuOpen,
      openTooltip: openTooltip,
      openCloseMenuTooltipText: openCloseMenuTooltipText,
      isLinkSelected: isLinkSelected,
      handleOpenTooltip: handleOpenTooltip,
      handleOpenCloseMenu: handleOpenCloseMenu,
      handleRedirect: handleRedirect,
      isAnotherWorkspaceSelected: isAnotherWorkspaceSelected,
      workspaceSelectedPermissions: workspaceSelectedForbiddens,
    };

    const MENU_NAV_BAR = {
      [UserRoleEnum.squadOwner]: <OwnerViewMenuNavBar {...menuProps} />,
      [UserRoleEnum.squadMember]: <MemberViewMenuNavBar {...menuProps} />,
      [UserRoleEnum.admin]: <AdminViewMenuNavBar {...menuProps} />,
    };

    useEffect(() => {
      setMenuPositionCookie();
    }, [isMenuOpen]);

    return MENU_NAV_BAR[userRole] || <AnonymousViewMenuNavBar {...menuProps} />;
  },
);
