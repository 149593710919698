import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_ONBOARDING_INDEX = '@onboardingIndex/SET',
}

export type Onboarding = number;

export type OnboardingIndexReducer = Reducer<Onboarding, Action<ActionTypes>>;
