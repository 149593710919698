import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_DATA = '@userData/SET',
}

export type UserData = {
  about: string;
  country: string;
  city: string;
  nativeLanguage: string;
  englishLevel: string;
  pages: {
    linkedin: string;
    behance: string;
    github: string;
    webPage: string;
  };
  dailyTime: [];
  newLanguagesList: [];
  newLanguagesListForModal: [];
  photoUrl: string;
};

export type UserDataReducer = Reducer<UserData, Action<ActionTypes>>;
