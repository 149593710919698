/* eslint-disable no-case-declarations */
import { ActionTypes, QuestionsReducer } from './types';

const questionsListReducer: QuestionsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.GET_QUESTIONS:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default questionsListReducer;
