/* eslint-disable no-case-declarations */
import { ActionTypes, UserAboutReducer } from './types';

const userAboutInit = {
  jobTitle: '',
  gender: '',
  email: '',
  dob: '',
  phoneNumber: {
    countryCode: '',
    phoneNumber: '',
  },
  workAvailability: '',
  level: '',
};
const userAboutReducer: UserAboutReducer = (state = userAboutInit, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_ABOUT:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default userAboutReducer;
