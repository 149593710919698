/* eslint-disable react/jsx-filename-extension */
import { HTMLAttributes, ReactElement } from 'react';
import { RadioInputContainer as Container, Radio } from './styles';

interface Props extends HTMLAttributes<HTMLInputElement> {
  isChecked?: boolean;
  name?: string;
}

export const RadioInput = ({
  isChecked,
  name,
  ...rest
}: Props): ReactElement => {
  const defaultCheckedProp = typeof isChecked === 'boolean' ? isChecked : false;

  return (
    <Container isChecked={defaultCheckedProp}>
      <Radio type="radio" name={name} checked={defaultCheckedProp} {...rest} />
    </Container>
  );
};
