import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_SKIP_FORM = '@skipForm/SET',
}

export type SkipForms = false;

export type SkipFormsReducer = Reducer<SkipForms, Action<ActionTypes>>;
