import styled, { css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

interface WorkspaceSelectionProps {
  isVisible: boolean;
}

interface WorkspaceHelperProps {
  isVisible: boolean;
}

interface CarouselProps {
  positionX: number;
}

interface IconButtonProps {
  iconSize?: string;
}

// interface BulletProps {
//   isCurrent: boolean;
// }

const CAROUSEL_POSITION_X = {
  0: '0%',
  1: '-100%',
  2: '-200%',
};

export const ModalWorkspaceContainer = styled.div`
  display: flex;
  gap: 18px;
  overflow: hidden;
  max-width: 412px;
  min-width: 412px;

  background-color: ${colors.background100};
  border-radius: 12px;

  @media screen and (max-width: 480px) {
    min-width: 100vw;
    max-width: 100vw;
  }
`;

export const ModalSelectWorkspaceHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
`;

export const ModalSelectWorkspaceTitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
  font-family: 'Mulish', sans-serif !important;

  color: ${colors.primary};
  background-color: ${colors.purple50};
  border-radius: 8px;
  margin: 0 auto;
  padding: 4px 10px;
`;

export const ModalSelectWorkspaceIconButton = styled.button<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px;
  padding: 0px;
  color: ${colors.text};

  svg {
    width: 14px;
    min-width: 14px;
    height: 14px;
  }

  ${({ iconSize }) =>
    iconSize &&
    css`
      svg {
        width: ${iconSize};
        min-width: ${iconSize};
        height: ${iconSize};
      }
    `}
`;

// *** WORKSPACE SELECTION
export const ModalSelectWorkspaceContainer = styled.div<WorkspaceSelectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 412px;
  min-width: 412px;
  padding: 26px 18px;

  @keyframes selectionClose {
    from {
      opacity: 1;
      transform: translateX(0px);
    }
    to {
      opacity: 0;
      transform: translateX(-450px);
    }
  }

  @keyframes selectionOpen {
    from {
      opacity: 0;
      transform: translateX(-450px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      animation: selectionClose 0.3s ease forwards;
    `}

  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: selectionOpen 0.3s ease forwards;
    `}

  @media screen and (max-width: 480px) {
    min-width: 100vw;
    max-width: 100vw;
  }
`;
export const WorkspacesWrapper = styled.div.attrs({
  className: 'cubicBezierAnimation',
})`
  display: flex;
  flex-direction: column;
  margin: 42px 0px;

  @media screen and (min-width: 1024px) and (max-height: 680px) {
    max-height: 245px;
    overflow: auto;
  }
  @media screen and (max-width: 768px) {
    max-height: 245px;
    overflow: auto;
    padding-right: 6px;
    padding-left: 6px;
  }
`;
// *** WORKSPACE SELECTION

// *** WORKSPACE HELPER
export const ModalWorkspaceHelperContainer = styled.div<WorkspaceHelperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 412px;
  min-width: 412px;
  padding: 26px 18px;

  @keyframes helperOpen {
    from {
      opacity: 0;
      transform: translateX(0px);
    }
    to {
      opacity: 1;
      transform: translateX(-430px);
    }
  }

  @keyframes helperClose {
    from {
      opacity: 1;
      transform: translateX(-430px);
    }
    to {
      opacity: 0;
      transform: translateX(0px);
    }
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: helperOpen 0.3s ease forwards;
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      animation: helperClose 0.3s ease forwards;
    `}
`;
export const WorkspaceHelperDescriptionWrapper = styled.div<CarouselProps>`
  width: 100%;
  min-height: 189px;
  max-height: 189px;
  margin: 21px 0px;

  overflow: hidden;
  position: relative;
  .carousel-content {
    position: absolute;
    left: ${({ positionX }) => CAROUSEL_POSITION_X[positionX]};
    transition: left 0.3s;
    display: flex;
    height: 100%;
    align-items: center;
  }
`;
export const WorkspaceHelperContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 250px;
  gap: 12px;
  width: 100%;
  min-width: calc(412px - 36px);
  height: 100%;
  .illustration-content {
    min-width: 94px;
    max-width: 125px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description-content {
    p {
      color: ${colors.text};
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      margin: 0px;
    }
  }
`;
export const WorkspaceHelperButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .progress-bullet-points-content {
    margin: 0px 56px;
  }
`;
// *** WORKSPACE HELPER
