import { TemplatesKeysEnum } from '../types';
import HuntersLoadingJSON from './hunters99Loading.json';
import GrowyxLoadingJSON from 'AppV2/assets/lotties/Loading-Growyx.json';

export const useLottieLoadings = () => {
  return {
    [TemplatesKeysEnum.GROWYX]: GrowyxLoadingJSON,
    [TemplatesKeysEnum.HUNTERS_99]: HuntersLoadingJSON,
  };
};
