/* eslint-disable no-case-declarations */
import { ActionTypes, UserPersonalityReducer } from './types';

const userPersonalityTypeInit = {
  calm: '',
  careful: '',
  competitive: '',
  conformity: '',
  dominance: '',
  enthusiastic: '',
  influence: '',
  influencer: '',
  listener: '',
  objective: '',
  optimist: '',
  organized: '',
  patient: '',
  personal: '',
  persuasive: '',
  planner: '',
  precision: '',
  radical: '',
  selfish: '',
  selfsteem: '',
  spontaneous: '',
  stability: '',
  trustworthy: '',
  visionary: '',
};

const userPersonalityReducer: UserPersonalityReducer = (
  state = userPersonalityTypeInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_PERSONALITY_TYPE:
      const { payload } = action;
      return payload;

    default:
      return state;
  }
};

export default userPersonalityReducer;
