/* eslint-disable react/jsx-filename-extension */
import { ReactNode } from 'react';
import TicketIcon from 'AppV2/assets/icons/Ticket';
import FolderIcon from 'AppV2/assets/icons/Folder';
import ScanIcon from 'AppV2/assets/icons/Scan';
import UserIcon from 'AppV2/assets/icons/User';
import UsersIcon from 'AppV2/assets/icons/Users';
import EditSquareIcon from 'AppV2/assets/icons/EditSquare';
import MoneyIcon from 'AppV2/assets/icons/Money';
import ChartIcon from 'AppV2/assets/icons/Chart';
import WorkspaceIcon from 'AppV2/assets/icons/Workspace';
import TaskFlagIcon from 'AppV2/assets/icons/TaskFlag';
import WorkIcon from 'AppV2/assets/icons/Work';
import LightningIcon from 'AppV2/assets/icons/Lightning';

interface LinkProps {
  path: string;
  text: string;
  icon: ReactNode;
  iconSize?: number;
}

export interface OwnerLinksProps {
  projectLI: LinkProps[];
  platformLI: LinkProps[];
}

export const OWNER_LINKS: OwnerLinksProps = {
  projectLI: [
    {
      path: '/dashboard',
      text: 'MENU_NAV_BAR_LINKS:OWNER_DASHBOARD',
      icon: <ChartIcon />,
      iconSize: 20,
    },
    {
      path: '/my-projects',
      text: 'MENU_NAV_BAR_LINKS:OWNER_PROJECTS',
      icon: <FolderIcon />,
    },
    // {
    //   path: '/matchmaking',
    //   text: 'MENU_NAV_BAR_LINKS:OWNER_MATCHMAKING',
    //   icon: <UsersIcon />,
    // },
    {
      path: '/task-assignments',
      text: 'MENU_NAV_BAR_LINKS:OWNER_TASKS',
      icon: <TaskFlagIcon />,
    },
    {
      path: '/workspace',
      text: 'MENU_NAV_BAR_LINKS:OWNER_WORKSPACE',
      icon: <WorkspaceIcon />,
    },
    {
      path: '/credits-and-payments',
      text: 'MENU_NAV_BAR_LINKS:OWNER_PAYMENTS',
      icon: <MoneyIcon />,
    },
    {
      path: '/ocai-organizational-culture-tool',
      text: 'MENU_NAV_BAR_LINKS:OWNER_OCAI_TOOL',
      icon: <WorkIcon />,
    },
    {
      path: '/assessments',
      text: 'MENU_NAV_BAR_LINKS:OWNER_ASSESSMENTS',
      icon: <LightningIcon />,
    },
  ],
  platformLI: [
    {
      path: '/squadOwner-profile',
      text: 'MENU_NAV_BAR_LINKS:OWNER_MY_PROFILE',
      icon: <UserIcon />,
    },
    {
      path: '/support-squadOwner-view',
      text: 'MENU_NAV_BAR_LINKS:OWNER_SUPPORT',
      icon: <TicketIcon />,
    },
  ],
};

export const MATCHMAKING_TRIAL_LINKS: OwnerLinksProps = {
  projectLI: [
    {
      path: '/squadOwner-profile',
      text: 'MENU_NAV_BAR_LINKS:OWNER_MY_PROFILE',
      icon: <UserIcon />,
    },
    {
      path: '/ocai-organizational-culture-tool',
      text: 'MENU_NAV_BAR_LINKS:OWNER_OCAI_TOOL',
      icon: <WorkIcon />,
    },
    {
      path: '/matchmaking',
      text: 'MENU_NAV_BAR_LINKS:OWNER_MATCHMAKING',
      icon: <UsersIcon />,
    },
    {
      path: '/assessments',
      text: 'MENU_NAV_BAR_LINKS:OWNER_ASSESSMENTS',
      icon: <LightningIcon />,
    },
    {
      path: '/workspace',
      text: 'MENU_NAV_BAR_LINKS:OWNER_WORKSPACE',
      icon: <WorkspaceIcon />,
    },
  ],
  platformLI: [],
};

export const MEMBER_LINKS: LinkProps[] = [
  {
    path: '/squadMember-profile',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_MY_PROFILE',
    icon: <UserIcon />,
  },
  {
    path: '/applications',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_APPLICATIONS',
    icon: <LightningIcon />,
  },
  {
    path: '/jobs',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_OPPORTUNITIES',
    icon: <FolderIcon />,
  },
  {
    path: '/task-assignments',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_TASKS',
    icon: <TaskFlagIcon />,
  },
  {
    path: '/tests-selection',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_MY_TESTS',
    icon: <ScanIcon />,
  },
  {
    path: '/support-squadMember-view',
    text: 'MENU_NAV_BAR_LINKS:MEMBER_SUPPORT',
    icon: <TicketIcon />,
  },
];

export const ADMIN_LINKS: LinkProps[] = [
  {
    path: '/cms-professionals',
    text: 'MENU_NAV_BAR_LINKS:ADMIN_CMS_PROFESSIONALS',
    icon: <UsersIcon />,
  },
  {
    path: '/cms-owners',
    text: 'MENU_NAV_BAR_LINKS:ADMIN_CMS_OWNERS',
    icon: <UserIcon />,
  },
  {
    path: '/cms-projects',
    text: 'MENU_NAV_BAR_LINKS:ADMIN_CMS_PROJECTS',
    icon: <ScanIcon />,
  },
  {
    path: '/project-brief-selection',
    text: 'MENU_NAV_BAR_LINKS:ADMIN_POST_PROJECT',
    icon: <FolderIcon />,
  },
  {
    path: '/cms-analytics',
    text: 'MENU_NAV_BAR_LINKS:ADMIN_ANALYTICS',
    icon: <TicketIcon />,
  },
];

export const NO_USER_ROLE_LINKS: LinkProps[] = [
  {
    path: '/login',
    text: 'Login',
    icon: <UsersIcon />,
  },
  {
    path: '/signup',
    text: 'Signup',
    icon: <EditSquareIcon />,
  },
];
