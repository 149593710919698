/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { memo } from 'react';
import WelcomeText from '../../Molecules/LoginSignUp/WelcomeText';
import LoginForm from '../../Molecules/LoginSignUp/LoginForm';
import SocialsButtons from '../../Molecules/LoginSignUp/SocialsButtons';

const Login = ({ setLoading }) => {
  return (
    <div className="login-register-page">
      <WelcomeText isLoginContent setLoading={setLoading} />
      <LoginForm />
      <SocialsButtons isLoginButton />
    </div>
  );
};

export default memo(() => <Login />);
