import { createContext, useContext, useState } from 'react';
import { ProviderDefaultProps } from 'AppV2/interfaces/providerDefaultProps';
import { useCookies } from 'react-cookie';
import { MenuNavPropsContextData, MenuPositionCookieKey } from './interfaces';
import {
  MenuNavBarClosedWidth,
  MenuNavBarOpenWidth,
} from 'AppV2/templates/components/MenuNavBar/style';

const Context = createContext({} as MenuNavPropsContextData);
const useMenuNavProps = () => useContext(Context);

const MenuNavPropsProvider = ({ children }: ProviderDefaultProps) => {
  // HOOKS
  const [cookie] = useCookies([MenuPositionCookieKey]);

  // STATES
  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const MenuPositionCookie = cookie[MenuPositionCookieKey];
    if (MenuPositionCookie) {
      const isMenuPositionOpen = MenuPositionCookie === 'true';
      return isMenuPositionOpen;
    }
    return false;
  });

  const currentMenuWidth = isMenuOpen
    ? MenuNavBarOpenWidth
    : MenuNavBarClosedWidth;

  // FUNCTIONS
  const handleOpenCloseMenu = () => setIsMenuOpen(!isMenuOpen);

  const setMenuPositionCookie = () => {
    document.cookie = `${MenuPositionCookieKey}=${String(isMenuOpen)}`;
  };

  const exportedValues = {
    isMenuOpen,
    currentMenuWidth,
    handleOpenCloseMenu,
    setMenuPositionCookie,
  };

  return <Context.Provider value={exportedValues}>{children}</Context.Provider>;
};

export { useMenuNavProps, MenuNavPropsProvider };
