import { Reducer } from 'redux';
import { Action } from 'Store/types';
export enum ActionTypes {
  SET_CURRENT_PAGE = '@currentPage/SET',
}

export type currentPage = number;

export type CurrentPageReducer = Reducer<
  currentPage,
  Action<ActionTypes, currentPage>
>;
