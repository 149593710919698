import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import {
  PostSelfAdmissionRequestProps,
  SelfAdmissionStorageActions,
  SelfAdmissionStorageKeys,
} from './types';

export const postSelfAdmission = async ({
  projectId,
  positionIndex,
  upsellId,
  replacementId,
  matchmakingId,
}: PostSelfAdmissionRequestProps): Promise<AxiosResponse<Response>> => {
  try {
    const requestBody: PostSelfAdmissionRequestProps = {
      projectId,
      positionIndex,
      matchmakingId,
    };

    if (upsellId) {
      requestBody.upsellId = upsellId;
    }
    if (replacementId) {
      requestBody.replacementId = replacementId;
    }

    const response = await apiV2Instance.post(
      'opportunities/selfAdmission',
      requestBody,
    );

    console.log('[services][postSelfAdmission][RESPONSE]', response);
    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][postSelfAdmission][ERROR]', errorResponse);

    throw errorResponse;
  }
};

export const localStorageSelfAdmission = (
  action: SelfAdmissionStorageActions,
  projectId = '',
  positionIndex = '',
  upsellOrReplacementQuery = '',
) => {
  switch (action) {
    case 'getJobDetailsUrl':
      return localStorage.getItem(
        SelfAdmissionStorageKeys.selfAdmissionJobDetailsUrl,
      );

    case 'setJobDetailsUrlForLogoffUsers':
      localStorage.setItem(
        SelfAdmissionStorageKeys.isLogOffUserSelfAdmission,
        'true',
      );
      localStorage.setItem(
        SelfAdmissionStorageKeys.selfAdmissionJobDetailsUrl,
        `/jobs/details-view/${projectId}/${positionIndex}${upsellOrReplacementQuery}`,
      );
      break;

    case 'setApplyDate':
      localStorage.setItem(
        SelfAdmissionStorageKeys.selfAdmissionDate,
        String(new Date().getTime()),
      );
      break;

    case 'getApplyDate':
      return localStorage.getItem(SelfAdmissionStorageKeys.selfAdmissionDate);

    case 'removeStorages':
      localStorage.removeItem(
        SelfAdmissionStorageKeys.isLogOffUserSelfAdmission,
      );
      localStorage.removeItem(
        SelfAdmissionStorageKeys.selfAdmissionJobDetailsUrl,
      );
  }
};
