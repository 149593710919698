/* eslint-disable react/jsx-filename-extension */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountTypeTexts } from 'Texts/LoginSignUp';
import setAccountTypeThunk from 'Store/modules/loginSignUpForms/accountType/thunk';

const AccountType = () => {
  const { browserLanguage } = useSelector(state => state);
  const { freelancer, employer } = accountTypeTexts;

  const [squadOwnerchecked, setSquadOwnerChecked] = useState(false);
  const [squadMemberchecked, setSquadMemberChecked] = useState(false);
  const dispatch = useDispatch();
  const changeAccountType = e => {
    if (e.target.id === 'squadOwner-radio') {
      setSquadOwnerChecked(true);
      setSquadMemberChecked(false);
      dispatch(setAccountTypeThunk(e.target.value));
    }
    if (e.target.id === 'squadMember-radio') {
      setSquadOwnerChecked(false);
      setSquadMemberChecked(true);
      dispatch(setAccountTypeThunk(e.target.value));
    }
  };

  useEffect(() => {
    localStorage.removeItem('accountType');
    const param = window.location.href.split('?')[1]
      ? window.location.href.split('?')[1]
      : '';
    if (param === 'employer') {
      setSquadOwnerChecked(true);
      setSquadMemberChecked(false);
      dispatch(setAccountTypeThunk('squadOwner'));
    } else {
      setSquadOwnerChecked(false);
      setSquadMemberChecked(true);
      dispatch(setAccountTypeThunk('squadMember'));
    }
  }, [dispatch]);

  return (
    <div className="account-type">
      <div>
        <input
          type="radio"
          name="account-type-radio"
          id="squadMember-radio"
          className="account-type-radio"
          value="squadMember"
          onChange={e => changeAccountType(e)}
          checked={squadMemberchecked}
        />
        <label htmlFor="squadMember-radio" className="ripple-effect-dark">
          <i className="icon-material-outline-account-circle"></i>{' '}
          {freelancer[browserLanguage]}
        </label>
      </div>

      <div>
        <input
          type="radio"
          name="account-type-radio"
          id="squadOwner-radio"
          className="account-type-radio"
          value="squadOwner"
          onChange={e => changeAccountType(e)}
          checked={squadOwnerchecked}
        />
        <label htmlFor="squadOwner-radio" className="ripple-effect-dark">
          <i className="icon-material-outline-business-center"></i>{' '}
          {employer[browserLanguage]}
        </label>
      </div>
    </div>
  );
};

export default AccountType;
