import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_BRIEF_FOR = '@briefFor/SET',
}

export type BriefFor = {
  answerIndex: number;
  userCount: number;
};

export type BriefForReducer = Reducer<BriefFor, Action<ActionTypes>>;
