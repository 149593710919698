/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { ProfessionsGroupResponseData } from './types';
import { auth } from 'FirebaseFiles/Firebase';

export const getProfessionsGroup = async (
  query,
): Promise<AxiosResponse<ProfessionsGroupResponseData>> => {
  try {
    const { jobTitles, jobTitle, correlacionadas, pontos_items } = query;
    let queryParam = ``;

    if (jobTitles) {
      queryParam = '?jobTitles=true';
    } else if (jobTitle) {
      queryParam = `?jobTitle=${jobTitle}`;
      if (correlacionadas) {
        queryParam = `?jobTitle=${jobTitle}&correlacionadas=true`;
      } else if (pontos_items) {
        queryParam = `?jobTitle=${jobTitle}&pontos_items=true`;
      }
    }

    const response = await apiV2Instance.get('/professionsGroup', {
      params: queryParam,
    });
    console.log('[services][getProfessionsGroup][RESPONSE]', response);

    return response;
    // return { data: mocked };
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][getProfessionsGroup][ERROR]', errorResponse);

    if (error?.response?.status === 401) {
      if (auth?.currentUser) {
        auth.signOut().then(() => open('/', '_self'));
      }
    }

    throw errorResponse;
  }
};
