/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useLayoutEffect } from 'react';
import * as S from '../styles';
import Lottie from 'lottie-react';
import { CSSObject } from 'styled-components';
import { useTemplatesConfig } from 'AppV2/hooks/TemplatesConfig';
import { useAuth } from 'Contexts/AuthContext';

interface Props {
  styles?: CSSObject;
}

export const LoadingPageDefault = ({ ...props }: Props): ReactElement => {
  const { isAppV2Paths } = useAuth();
  const { lottieLoading, templateKey } = useTemplatesConfig();

  useLayoutEffect(() => {
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.style.overflow = 'hidden';
    }
  }, []);

  return (
    <S.LoadingPageContainer styles={props.styles}>
      <S.MotionDivWrapper
        {...S.MotionAnimation}
        templateKey={templateKey}
        version={isAppV2Paths ? 'V2' : 'V1'}
      >
        <Lottie loop={true} autoPlay={true} animationData={lottieLoading} />
      </S.MotionDivWrapper>
    </S.LoadingPageContainer>
  );
};
