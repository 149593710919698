import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PutRemoveUserRequest } from './types';

export const putRemoveUser = async (
  userId: PutRemoveUserRequest,
): Promise<AxiosResponse<Response>> => {
  try {
    const response = await apiV2Instance.put(`/workspace/removeUser`, {
      userId: userId,
    });

    console.log(
      '[services][Workspace][RemoveResource:putRemoveResource][RESPONSE]',
      response,
    );

    return response;
  } catch (error) {
    console.log(
      '[services][Workspace][RemoveResource:putRemoveResource][ERROR]',
      error,
    );

    throw error;
  }
};
