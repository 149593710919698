/* eslint-disable react/jsx-filename-extension */
import { ProviderDefaultProps } from 'AppV2/interfaces/providerDefaultProps';
import { AuthProvider } from 'Contexts/AuthContext';
import { ModalProvider } from './ModalProvider';
import { MenuNavPropsProvider } from './MenuNavProvider';
import { AlertProvider } from './AlertProvider';

export const MainProvider = ({ children }: ProviderDefaultProps) => {
  return (
    <AuthProvider>
      <MenuNavPropsProvider>
        <AlertProvider>
          <ModalProvider>{children}</ModalProvider>
        </AlertProvider>
      </MenuNavPropsProvider>
    </AuthProvider>
  );
};
