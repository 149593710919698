import { ActionTypes, FillProfileFormsIndexReducer } from './types';

const fillProfileFormsIndexReducer: FillProfileFormsIndexReducer = (
  state = 0,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_FILL_PROFILE_FORMS_INDEX:
      return action.payload;

    default:
      return state;
  }
};

export default fillProfileFormsIndexReducer;
