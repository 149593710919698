import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_ALL_SKILLS = '@allSkills/SET',
}

export type AllSkills = {
  skill: any[];
  software: any[];
  methodologies: any[];
  language: any[];
  database: any[];
};

export type AllSkillsReducer = Reducer<AllSkills, Action<ActionTypes>>;
