import { Action } from 'Store/types';
import { BrowserLanguage, ActionTypes } from './types';

const setBrowserLanguage = (
  browserLanguage: BrowserLanguage,
): Action<ActionTypes> => ({
  type: ActionTypes.SET_BROWSER_LANGUAGE,
  payload: browserLanguage,
});

export default setBrowserLanguage;
