import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MODAL_RESULTS = '@modalResults/SET',
}
export type ModalResults = {
  isOpen: boolean;
  modalIndex: any;
};

export type ModalResultsReducer = Reducer<ModalResults, Action<ActionTypes>>;
