import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_PROJECT_IS_END_FORM = '@isEndForm/SET',
}

export type IsEndForm = boolean;

export type IsEndFormReducer = Reducer<IsEndForm, Action<ActionTypes>>;
