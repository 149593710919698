/* eslint-disable react/jsx-filename-extension */
import { useAuth } from 'Contexts/AuthContext';

import Button from 'Components/Atoms/Generals/Button';
import AccountType from 'Components/Molecules/LoginSignUp/AccountType';
import WelcomeText from 'Components/Molecules/LoginSignUp/WelcomeText';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Spinner from 'Components/Atoms/Generals/Spinner';
import { loginButton } from 'Texts/LoginSignUp';

const FormNewSocialLoginUser = () => {
  const { setCustomClaimFirstStage } = useAuth();
  const { accountType } = useSelector(state => state);
  const [loading, setLoading] = useState(false);
  const { browserLanguage } = useSelector(state => state);
  const handleRegister = async () => {
    setLoading(true);
    localStorage.removeItem('isFirstSocialLogin');
    await setCustomClaimFirstStage(accountType);
  };

  return (
    <div className="login-register-page">
      <WelcomeText isSignUpContent showsLoginOption={false} />
      <AccountType />
      {loading ? (
        <Spinner />
      ) : (
        <Button
          thisText={loginButton[browserLanguage]}
          thisId="btn-register"
          thisOnClick={() => handleRegister()}
        />
      )}
    </div>
  );
};

export default FormNewSocialLoginUser;
