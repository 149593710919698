import styled, { css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

interface Props {
  isCurrent: boolean;
}

export const ProgressBulletPointsContainer = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BulletPoint = styled.div<Props>`
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: 9px;
  background-color: ${colors.faded};
  border-radius: 50%;
  transition: 0.3s;

  ${({ isCurrent }) =>
    isCurrent &&
    css`
      background-color: ${colors.primary};
    `}
`;
