/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode } from 'react';
import ArrowsUpDownIcon from 'AppV2/assets/icons/ArrowsUpDown';
import UserIcon from 'AppV2/assets/icons/User';
import ExitIcon from 'AppV2/assets/icons/Exit';
import ChartIcon from 'AppV2/assets/icons/Chart';
import FolderIcon from 'AppV2/assets/icons/Folder';
import PostProjectIcon from 'AppV2/assets/icons/PostProject';
import MoneyIcon from 'AppV2/assets/icons/Money';
import TicketIcon from 'AppV2/assets/icons/Ticket';
import ScanIcon from 'AppV2/assets/icons/Scan';
import InternetIcon from 'AppV2/assets/icons/Internet';
import { useAuth } from 'Contexts/AuthContext';
import { useModal } from 'AppV2/providers/ModalProvider';
import { useHistory } from 'react-router-dom';
import { AppLanguageModalKey, WorkspaceModalKey } from '..';
import { translateText } from 'AppV2/common/translateText';
import { UserRoleType } from 'AppV2/interfaces/userRole';
import { Options } from 'AppV2/components/Options';
import WorkspaceIcon from 'AppV2/assets/icons/Workspace';
import LightningIcon from 'AppV2/assets/icons/Lightning';

interface LinkProps {
  text: string;
  action: () => void;
  icon: ReactNode;
  iconSize: string;
  color: 'regular' | 'warning';
}

interface OptionsProps {
  squadOwner: LinkProps[];
  squadMember: LinkProps[];
  admin: LinkProps[];
  anonymous: LinkProps[];
}

interface Props {
  userRole: UserRoleType;
}

export const WidjetDropDownOptions = ({ userRole }: Props): ReactElement => {
  const { logout, userCustomClaim, currentUser } = useAuth();
  const { setOpenModal } = useModal();
  const history = useHistory();

  const isMobileScreen = window.innerWidth <= 768;
  const isMatchmakingStage =
    userCustomClaim?.navigationStage === 'tests-selection';
  const isMatchmakingTrial = !!currentUser?.claims?.matchmakingTrial;
  const isAssessmentUser = !!currentUser?.claims?.assessmentUser;

  const handleRedirectTo = (path: string) => history.push(path);

  const handleLogout = () => logout();

  const handleOpenWorkspaceModal = () =>
    setOpenModal({ key: WorkspaceModalKey });

  const handleOpenLanguageModal = () =>
    setOpenModal({ key: AppLanguageModalKey });

  const DESKTOP_OPTIONS_LINKS: OptionsProps = {
    squadOwner: [
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_CHANGE_SPACE'),
        action: handleOpenWorkspaceModal,
        icon: <ArrowsUpDownIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_APP_LANGUAGE'),
        action: handleOpenLanguageModal,
        icon: <InternetIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_PROFILE'),
        action: () => handleRedirectTo('/squadOwner-profile'),
        icon: <UserIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    squadMember: [
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_APP_LANGUAGE'),
        action: handleOpenLanguageModal,
        icon: <InternetIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_MY_PROFILE'),
        action: () => handleRedirectTo('/squadMember-profile'),
        icon: <UserIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    admin: [
      {
        text: translateText('HEADER_USER_MENU:ADMIN_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    anonymous: [
      {
        text: translateText('HEADER_USER_MENU:ANONYMOUS_OPTIONS_SIGNIN'),
        action: () => handleRedirectTo('/login'),
        icon: <UserIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:ANONYMOUS_OPTIONS_SIGNOUT'),
        action: () => handleRedirectTo('/signup'),
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'regular',
      },
    ],
  };

  const MOBILE_OPTIONS_LINKS: OptionsProps = {
    squadOwner: [
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_CHANGE_SPACE'),
        action: handleOpenWorkspaceModal,
        icon: <ArrowsUpDownIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_WORKSPACE'),
        action: () => handleRedirectTo('/workspace'),
        icon: <WorkspaceIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_PROFILE'),
        action: () => handleRedirectTo('/squadOwner-profile'),
        icon: <UserIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: translateText('MENU_NAV_BAR_LINKS:OWNER_OCAI_TOOL'),
        action: () => handleRedirectTo('/ocai-organizational-culture-tool'),
        icon: <ScanIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: 'Assessments',
        action: () => handleRedirectTo('/assessments'),
        icon: <LightningIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_DASHBOARD'),
        action: () => handleRedirectTo('/dashboard'),
        icon: <ChartIcon />,
        iconSize: '14px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_MY_PROJECTS'),
        action: () => handleRedirectTo('/my-projects'),
        icon: <FolderIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_POST_PROJECTS'),
        action: () => handleRedirectTo('/project-brief-selection'),
        icon: <PostProjectIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_POST_PAYMENTS'),
        action: () => handleRedirectTo('/credits-and-payments'),
        icon: <MoneyIcon />,
        iconSize: '16px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_APP_LANGUAGE'),
        action: handleOpenLanguageModal,
        icon: <InternetIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_SUPORT'),
        action: () => handleRedirectTo('/support-squadOwner-view'),
        icon: <TicketIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:OWNER_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    squadMember: [
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_MY_PROFILE'),
        action: () => handleRedirectTo('/squadMember-profile'),
        icon: <UserIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('MENU_NAV_BAR_LINKS:MEMBER_APPLICATIONS'),
        action: () => handleRedirectTo('/applications'),
        icon: <LightningIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_OPORTUNITIES'),
        action: () => handleRedirectTo('/jobs'),
        icon: <FolderIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_MY_TESTS'),
        action: () => handleRedirectTo('/tests-selection'),
        icon: <ScanIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_APP_LANGUAGE'),
        action: handleOpenLanguageModal,
        icon: <InternetIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_SUPPORT'),
        action: () => handleRedirectTo('/support-squadMember-view'),
        icon: <TicketIcon />,
        iconSize: '',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    admin: [
      {
        text: translateText('HEADER_USER_MENU:ADMIN_OPTIONS_LOGOUT'),
        action: handleLogout,
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'warning',
      },
    ],
    anonymous: [
      {
        text: translateText('HEADER_USER_MENU:ANONYMOUS_OPTIONS_SIGNIN'),
        action: () => handleRedirectTo('/login'),
        icon: <UserIcon />,
        iconSize: '15px',
        color: 'regular',
      },
      {
        text: translateText('HEADER_USER_MENU:ANONYMOUS_OPTIONS_SIGNOUT'),
        action: () => handleRedirectTo('/signup'),
        icon: <ExitIcon />,
        iconSize: '14px',
        color: 'regular',
      },
    ],
  };

  if (isMatchmakingStage) {
    const newDesktopLinks = Array.from(DESKTOP_OPTIONS_LINKS.squadMember);
    newDesktopLinks.splice(1, 1, {
      text: translateText('HEADER_USER_MENU:MEMBER_OPTIONS_MY_TESTS'),
      action: () => handleRedirectTo('/tests-selection'),
      icon: <ScanIcon />,
      iconSize: '16px',
      color: 'regular',
    });

    const newMobileLinks = Array.from(MOBILE_OPTIONS_LINKS.squadMember).filter(
      (_item, index) => (isMatchmakingStage ? [1, 3, 5].includes(index) : true),
    );

    DESKTOP_OPTIONS_LINKS.squadMember = newDesktopLinks;
    MOBILE_OPTIONS_LINKS.squadMember = newMobileLinks;
  }

  if (isMatchmakingTrial) {
    MOBILE_OPTIONS_LINKS.squadOwner = MOBILE_OPTIONS_LINKS.squadOwner.filter(
      (_, index) => [0, 2, 3, 4, 9, 11].includes(index),
    );
  }

  if (isAssessmentUser) {
    const assessmentLinksIndex = [0, 3, 4, 5, 6];
    MOBILE_OPTIONS_LINKS.squadMember = MOBILE_OPTIONS_LINKS.squadMember.filter(
      (_, index) => assessmentLinksIndex.includes(index),
    );
  }

  const optionsDropDownLinks = isMobileScreen
    ? MOBILE_OPTIONS_LINKS
    : DESKTOP_OPTIONS_LINKS;

  return (
    <Options dropDownLinks={optionsDropDownLinks[userRole || 'anonymous']} />
  );
};
