export const sessionStorageSalesSignIn = (action: string) => {
  const key = 'isSalesSignIn';
  if (action === 'get') {
    const getIsSalesSignUp = sessionStorage.getItem(key);
    const isSalesSignUp = JSON.parse(getIsSalesSignUp) ?? false;
    return isSalesSignUp;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageShouldRedirectToMyProjects = (action: string) => {
  const key = 'shouldRedirectToMyProjects';
  if (action === 'get') {
    const getShouldRedirectToMyProjets = sessionStorage.getItem(key);
    const shouldRedirectToMyProject =
      JSON.parse(getShouldRedirectToMyProjets) ?? false;
    return shouldRedirectToMyProject;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageShouldRedirectToDashboard = (action: string) => {
  const key = 'shouldRedirectToDashboard';
  if (action === 'get') {
    const getShouldRedirectToDashboard = sessionStorage.getItem(key);
    const shouldRedirectToMyProject =
      JSON.parse(getShouldRedirectToDashboard) ?? false;
    return shouldRedirectToMyProject;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageShouldGoToOnboardAfterLogin = (action: string) => {
  const key = 'shouldGoToOnboardAfterLogin';
  if (action === 'get') {
    const getshouldGoToOnboardAfterLogin = sessionStorage.getItem(key);
    const shouldGoToOnboardAfterLogin =
      JSON.parse(getshouldGoToOnboardAfterLogin) ?? false;
    return shouldGoToOnboardAfterLogin;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageIsSquadOwnerSession = (action: string) => {
  const key = 'isSquadOwnerSession';
  if (action === 'get') {
    const getIsSquadOwnerSession = sessionStorage.getItem(key);
    const isSquadOwnerSession = JSON.parse(getIsSquadOwnerSession) ?? false;
    return isSquadOwnerSession;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageShouldRedirectToClosePageAfterLogin = (
  action: string,
) => {
  const key = 'shoudlRedirectToClosedPageAfterLogin';
  if (action === 'get') {
    const getShouldRedirectToClosedPageAfterLogin = sessionStorage.getItem(key);
    const shoudlRedirectToClosedPageAfterLogin =
      JSON.parse(getShouldRedirectToClosedPageAfterLogin) ?? false;
    return shoudlRedirectToClosedPageAfterLogin;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageUserLogout = (action: string) => {
  const key = 'userLogout';
  if (action === 'get') {
    const getUserLogout = sessionStorage.getItem(key);
    const userLogout = JSON.parse(getUserLogout) ?? false;
    return userLogout;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageIsFirstSignUp = (action: string) => {
  const key = 'isFirstSignUp';
  if (action === 'get') {
    const getIsFirstSignUp = sessionStorage.getItem(key);
    const isFirstSignUp = JSON.parse(getIsFirstSignUp) ?? false;
    return isFirstSignUp;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};

export const sessionStorageUrlToRedirect = (action: string, value?: string) => {
  const key = 'urlToRedirect';
  if (action === 'get') {
    const getUrlToRedirect = sessionStorage.getItem(key);
    const urlToRedirect = getUrlToRedirect ?? '';
    return urlToRedirect;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, value);
};

export const sessionStorageNeedsGoToThanksPage = (action: string) => {
  const key = 'needsGoToThanksPage';
  if (action === 'get') {
    const needsGoToThanksPageRequest = sessionStorage.getItem(key);
    const needsGoToThanksPage = needsGoToThanksPageRequest ?? false;
    return needsGoToThanksPage;
  }
  if (action === 'remove') {
    return sessionStorage.removeItem(key);
  }
  return sessionStorage.setItem(key, JSON.stringify(true));
};
