/* eslint-disable no-case-declarations */
import { ActionTypes, CMSProfessionalsReducer } from './types';

const cmsProfessionalsReducer: CMSProfessionalsReducer = (
  state = { isFilter: false, searchJSON: null },
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_CMS_PROFESSIONALS:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default cmsProfessionalsReducer;
