import { colors } from 'AppV2/styles/colors';
import styled from 'styled-components';

export const ModalMobileCustomStyles = {
  paddingBottom: '0px',
};

export const Container = styled.div.attrs({
  className: 'invisible-scroll',
})`
  max-height: 90vh;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .modalmobile-title {
    color: ${colors.red};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0px;
    max-width: 90%;
  }

  .description {
    color: ${colors.textBlack};
    font-size: 14px;
    line-height: 150%;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const IssuesOL = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid ${colors.faded};

  li {
    color: ${colors.textBlack};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
    list-style-position: inside;

    ::marker {
      color: ${colors.primary};
      font-size: 16px;
      font-weight: 600;
      z-index: 4;
    }
  }

  li:last-child {
    margin-bottom: 0px;
  }
`;
