import axios from 'axios';
import { setRefreshHeadersToken } from './helpers';

const apiV1Instance = axios.create({
  baseURL: `${process.env.REACT_APP_API}httpFunctions-gapi`,
  headers: {
    Accept: 'application/json',
  },
});

apiV1Instance.interceptors.request.use(config =>
  setRefreshHeadersToken(config, apiV1Instance),
);

export { apiV1Instance };
