/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function FolderIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7352 12.6772C22.1576 12.6772 22.5 12.3346 22.5 11.9121V10.8918C22.5 6.84609 20.6978 4.876 17.0055 4.876H14.2802C13.8366 4.8781 13.4173 4.67324 13.1462 4.32191L12.267 3.15216C11.6991 2.42311 10.8261 1.99777 9.9022 2.00001H7.98571C4.34615 2.00001 2.5 4.10203 2.5 8.2533V15.7555C2.5 19.6077 4.89121 22 8.75055 22H16.2407C20.0912 22 22.4912 19.6077 22.4912 15.7555C22.4491 15.3622 22.1174 15.0639 21.722 15.0639C21.3266 15.0639 20.9948 15.3622 20.9527 15.7555C20.9527 18.7898 19.2824 20.4697 16.2407 20.4697H8.74176C5.7 20.4697 4.02967 18.7986 4.02967 15.7555V15.0255H16.3374C16.7598 15.0255 17.1022 14.6829 17.1022 14.2603C17.1022 13.8377 16.7598 13.4952 16.3374 13.4952H4.02967V8.21812C4.02967 4.91118 5.20769 3.49517 7.97692 3.49517H9.91099C10.3546 3.50173 10.7715 3.70863 11.0451 4.05806L11.9242 5.219C12.4822 5.95874 13.3539 6.3948 14.2802 6.39754H16.9967C19.8538 6.39754 20.9615 7.67283 20.9615 10.8918V11.9121C20.9615 12.1165 21.0433 12.3125 21.1887 12.4562C21.334 12.6 21.5308 12.6796 21.7352 12.6772Z"
        fill={iconColor}
      />
    </svg>
  );
}
