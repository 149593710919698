import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_IN_EDIT_PROJECT_DESCRIPTION = '@inEditProjectDescription/SET',
}
export type InEditProjectDescription = {
  inEdit: boolean;
  modalIsOpen: boolean;
  headerLink?: string;
};

export type InEditProjectDescriptionReducer = Reducer<
  InEditProjectDescription,
  Action<ActionTypes, InEditProjectDescription>
>;
