import { apiV1Instance } from 'AppV2/configs/Axios/api_V1';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { UserRoleType } from 'AppV2/interfaces/userRole';

type Operation = 'setHeaders' | 'setAll';
export interface IdTokenResultProps {
  claims: {
    userRole: UserRoleType;
    workspaceId: string;
  };
  token: string;
  authTime: string;
}

export const setAxiosDefaultWorkspaceId = (workspaceId: string) => {
  apiV1Instance.defaults.headers.workspaceId = workspaceId;
  apiV2Instance.defaults.headers.workspaceId = workspaceId;
};

export const setAxiosDefaultUserRole = (userRole: UserRoleType) => {
  apiV1Instance.defaults.headers.userRole = userRole;
  apiV2Instance.defaults.headers.userRole = userRole;
};

export const setAxiosDefaultOptions = (
  operation: Operation,
  idTokenResult: IdTokenResultProps,
) => {
  if (operation === 'setHeaders') {
    apiV1Instance.defaults.headers.userRole = idTokenResult.claims?.userRole;
    apiV1Instance.defaults.headers.workspaceId =
      idTokenResult.claims?.workspaceId;

    apiV2Instance.defaults.headers.userRole = idTokenResult.claims?.userRole;
    apiV2Instance.defaults.headers.workspaceId =
      idTokenResult.claims?.workspaceId;

    apiV1Instance.defaults.headers.authTime = idTokenResult.authTime;
    apiV2Instance.defaults.headers.authTime = idTokenResult.authTime;
  }

  if (operation === 'setAll') {
    apiV1Instance.defaults.headers.userRole = idTokenResult.claims?.userRole;
    apiV2Instance.defaults.headers.userRole = idTokenResult.claims?.userRole;

    apiV1Instance.defaults.headers.workspaceId =
      idTokenResult.claims?.workspaceId;
    apiV2Instance.defaults.headers.workspaceId =
      idTokenResult.claims?.workspaceId;

    apiV1Instance.defaults.headers.authTime = idTokenResult.authTime;
    apiV2Instance.defaults.headers.authTime = idTokenResult.authTime;

    apiV1Instance.defaults.headers.Authorization = idTokenResult.token;
    apiV2Instance.defaults.headers.Authorization = idTokenResult.token;
  }
};

export const setAxiosDefaultToken = (token: string) => {
  apiV1Instance.defaults.headers.Authorization = token;
  apiV2Instance.defaults.headers.Authorization = token;
};
