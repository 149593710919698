import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type StorageURLKeysType =
  | 'MATCHMAKING_ACCEPT_INVITE'
  | 'PROJECT_MATCHMAKING_APPLY';

export const useStorageRedirects = () => {
  const location = useLocation();

  const getStorageUrl = useCallback(
    (storageKey: StorageURLKeysType) =>
      localStorage.getItem(`STORAGE_REDIRECT@${storageKey}`),
    [],
  );

  const setStorageUrl = useCallback(
    (storageKey: StorageURLKeysType, url: string) =>
      localStorage.setItem(`STORAGE_REDIRECT@${storageKey}`, url),
    [],
  );

  const removeStorageUrl = useCallback(
    (storageKey: StorageURLKeysType) =>
      localStorage.removeItem(`STORAGE_REDIRECT@${storageKey}`),
    [],
  );

  const redirectTo = useCallback(
    (
      storageKey: StorageURLKeysType,
      ignorePaths: string[] = [],
      target: '_blank' | '_self' = '_self',
      removeFromStorage = true,
    ) => {
      if (
        ignorePaths.some(ignorePath => location.pathname.startsWith(ignorePath))
      )
        return;

      const url = getStorageUrl(storageKey);

      if (!url) {
        throw new Error(`URL by this storageKey: ${storageKey} is undefined`);
      }

      open(url, target);

      if (removeFromStorage) {
        removeStorageUrl(storageKey);
      }
    },
    [getStorageUrl, removeStorageUrl, location],
  );

  return {
    getStorageUrl,
    setStorageUrl,
    removeStorageUrl,
    redirectTo,
  };
};
