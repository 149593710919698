import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_FORMS_COMPLETED = '@formsCompleted/SET',
  REMOVE_FORMS_COMPLETED = '@formsCompleted/REMOVE',
}

export type FillProfileFormCompleted = any[];

export type FillProfileFormCompletedReducer = Reducer<
  FillProfileFormCompleted,
  Action<ActionTypes>
>;
