/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function WorkIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33332 17.5001C2.87499 17.5001 2.48263 17.3369 2.15624 17.0105C1.82985 16.6841 1.66666 16.2917 1.66666 15.8334V6.66675C1.66666 6.20842 1.82985 5.81605 2.15624 5.48967C2.48263 5.16328 2.87499 5.00008 3.33332 5.00008H6.66666V3.33341C6.66666 2.87508 6.82985 2.48272 7.15624 2.15633C7.48263 1.82994 7.87499 1.66675 8.33332 1.66675H11.6667C12.125 1.66675 12.5174 1.82994 12.8437 2.15633C13.1701 2.48272 13.3333 2.87508 13.3333 3.33341V5.00008H16.6667C17.125 5.00008 17.5174 5.16328 17.8437 5.48967C18.1701 5.81605 18.3333 6.20842 18.3333 6.66675V15.8334C18.3333 16.2917 18.1701 16.6841 17.8437 17.0105C17.5174 17.3369 17.125 17.5001 16.6667 17.5001H3.33332ZM3.33332 15.8334H16.6667V6.66675H3.33332V15.8334ZM8.33332 5.00008H11.6667V3.33341H8.33332V5.00008Z"
        fill={iconColor}
      />
    </svg>
  );
}
