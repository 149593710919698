import { useLayoutEffect, useMemo } from 'react';
import { TemplatesKeysEnum } from './types';
import { useFavIcons } from './favIcons';
import { useLogos } from './logos';
import { useLottieLoadings } from './lotties';

export const useTemplatesConfig = () => {
  // HOOKS
  const favIcons = useFavIcons();
  const logos = useLogos();
  const lottieLoadings = useLottieLoadings();

  // VALUES
  const locationHref = String(window.location.href).toLowerCase();

  const templateKey = useMemo(() => {
    if (locationHref.includes(TemplatesKeysEnum.HUNTERS_99)) {
      return TemplatesKeysEnum.HUNTERS_99;
    }

    return TemplatesKeysEnum.GROWYX;
  }, [locationHref]);

  const headTemplateData = useMemo(
    () => ({
      [TemplatesKeysEnum.GROWYX]: {
        title: 'Growyx - Hire and manage top talent',
        description:
          'Contrate e gerencie os melhores talentos de forma rápida, segura e transparente',
        favIcon: favIcons.growyx.favIcon,
        favIconAppleTouch: favIcons.growyx.appleTouch,
      },
      [TemplatesKeysEnum.HUNTERS_99]: {
        title: 'HunterHero',
        description:
          'Contrate os melhores talentos com eficiência e agilidade. A 99Hunters é sua parceira global em recrutamento ativo, conectando sua empresa com talentos excepcionais. Descubra como podemos ajudar você a preencher vagas com precisão e rapidez.',
        favIcon: favIcons[TemplatesKeysEnum.HUNTERS_99].favIcon,
        favIconAppleTouch: favIcons[TemplatesKeysEnum.HUNTERS_99].appleTouch,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // EFFECTS
  useLayoutEffect(() => {
    const storageTemplateKey = localStorage.getItem('TEMPLATE_KEY');

    if (storageTemplateKey !== templateKey) {
      localStorage.setItem('TEMPLATE_KEY', templateKey);
    }
  }, [templateKey]);

  // RETURN
  return {
    templateKey: templateKey,
    logos: logos,
    lottieLoading: lottieLoadings[templateKey],
    head: headTemplateData[templateKey],
  };
};
