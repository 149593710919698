/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { signupFormTexts, socialButtonsTexts } from 'Texts/LoginSignUp';
import { useAuth } from 'Contexts/AuthContext';
import Spinner from 'Components/Atoms/Generals/Spinner';

const SocialsButtons = ({
  isLoginButton = false,
  isRegisterButton = false,
}) => {
  // HOOKS
  const { googleLogin, googleSignup } = useAuth();
  const { browserLanguage, accountType } = useSelector(state => state);
  const [googleRegisterLoading, setGoogleRegisterLoading] = useState(false);

  // VALUES
  const isRedirectFromWorkspaceInvite =
    !!localStorage.getItem('WORKSPACE_INVITE');

  const { loginButtons, registerButtons, spanOr } = socialButtonsTexts;
  let googleButtonText = null;

  if (isLoginButton) {
    googleButtonText = loginButtons.google[browserLanguage];
  }
  if (isRegisterButton) {
    googleButtonText = registerButtons.google[browserLanguage];
  }

  // FUNCTIONS
  const loginWithGoogle = async () => {
    await googleLogin();
  };

  const registerWithGoogle = async () => {
    setGoogleRegisterLoading(true);
    try {
      const userRole = isRedirectFromWorkspaceInvite
        ? 'squadOwner'
        : accountType;

      await googleSignup(userRole);
    } finally {
      setGoogleRegisterLoading(false);
    }
  };

  const isUserTermsChecked = useCallback(() => {
    try {
      const isChecked = !!document.querySelector('.user-terms-container')
        .children[0].children[0].checked;

      if (!isChecked) {
        alert(signupFormTexts.useTerms.alertText[browserLanguage]);
      }

      return isChecked;
    } catch {
      alert(signupFormTexts.useTerms.alertText[browserLanguage]);
      return false;
    }
  }, [browserLanguage]);

  return (
    <>
      <div className="social-login-separator">
        <span>{spanOr[browserLanguage]}</span>
      </div>

      <div className="social-login-buttons" style={{ width: '100%' }}>
        <button
          className="google-login ripple-effect"
          onClick={() =>
            isLoginButton
              ? loginWithGoogle()
              : isUserTermsChecked()
              ? registerWithGoogle()
              : null
          }
          disabled={googleRegisterLoading}
          style={{
            maxWidth: '100%',
            width: '100%',
            height: '44px',
            marginRight: '0px',
          }}
        >
          {googleRegisterLoading ? (
            <Spinner margin="0px" height="100%" />
          ) : (
            <>
              <i className="icon-brand-google-plus-g"></i>
              {googleButtonText}
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default SocialsButtons;
