import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_EDUCATIONS = '@educations/ADD',
  REMOVE_EDUCATIONS = '@educations/REMOVE',
  RESET_EDUCATIONS = '@educations/RESET',
}

export type Educations = any[];

export type EducationsReducer = Reducer<Educations, Action<ActionTypes>>;
