import { TemplatesKeysEnum } from '../types';
import Hunters99Logo from './99HuntersLogo.svg';
import Hunters99LogoIcon from './99HuntersLogoIcon.svg';

export const useLogos = () => {
  return {
    [TemplatesKeysEnum.HUNTERS_99]: {
      logo: Hunters99Logo,
      icon: Hunters99LogoIcon,
    },
  };
};
