/* eslint-disable no-case-declarations */
import { ActionTypes, BackButtonTestToProfilePageReducer } from './types';

const backButtonTestToProfilePageInit = false;

const backButtonTestToProfilePageReducer: BackButtonTestToProfilePageReducer = (
  state = backButtonTestToProfilePageInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_BACK_BUTTON_TEST_TO_PROFILE_PAGE:
      const { payload } = action;
      return payload;
    default:
      return state;
  }
};
export default backButtonTestToProfilePageReducer;
