import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translations from './translations.json';

interface ITranslationsResource {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

const translationsJSON: ITranslationsResource = translations;
const resources: ITranslationsResource = {};
// const cookieAppLanguage = document.cookie.split(';').find(row => row.startsWith('appLanguage'))

for (const namespace in translationsJSON) {
  for (const key in translationsJSON[namespace]) {
    for (const lang in translationsJSON[namespace][key]) {
      if (!resources[lang]) {
        resources[lang] = {};
      }

      if (!resources[lang][namespace]) {
        resources[lang][namespace] = {};
      }

      resources[lang][namespace][key] = translationsJSON[namespace][key][lang];
    }
  }
}

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
    },
  });

export default i18n;
