/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { SpinnerContainer } from 'Containers';
import { SemipolarLoading } from 'react-loadingg';
import { SpinnerContent } from './styles';

const Spinner = ({
  width = '45px',
  height = '45px',
  margin = '',
  color = '',
  type = 'primary',
}) => {
  return (
    <SpinnerContainer
      thisHeight={height}
      thisMargin={margin}
      color={color}
      id="cubicBezierAnimation"
    >
      {type === 'primary' ? (
        <SpinnerContent />
      ) : (
        <SemipolarLoading
          color={color || 'var(--purple)'}
          size="small"
          style={{ width: width, height: width }}
        />
      )}
    </SpinnerContainer>
  );
};

export default Spinner;
