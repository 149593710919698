/* eslint-disable no-case-declarations */
import { ActionTypes, BrowserLanguageReducer } from './types';
import { setBrowserLanguageDefaultPattern } from './thunk';

const initAppLanguage = setBrowserLanguageDefaultPattern(navigator?.language);

const browserLanguageReducer: BrowserLanguageReducer = (
  state = initAppLanguage,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_BROWSER_LANGUAGE:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default browserLanguageReducer;
