import { colors } from 'AppV2/styles/colors';
import styled, { css } from 'styled-components';

interface WorkspaceCardProps {
  isSelected?: boolean;
  isOwnerCard?: boolean;
  isSkeleton?: boolean;
}

export const WorkspaceCardContainer = styled.div<WorkspaceCardProps>`
  width: 100%;
  margin-bottom: 12px;
  max-width: 371px;
  height: 58px;
  padding: 6px 22px 6px 6px;
  border: 1px solid ${colors.faded};
  border-radius: 62px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .left-side-content,
  .right-side-content {
    display: flex;
    align-items: center;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${colors.primary};
      background-color: ${colors.purple50};
      cursor: initial;
    `}

  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      justify-content: flex-start;
      border-color: ${colors.gray};
    `}
`;
export const WorkspaceCardTitleLabelContent = styled.div<WorkspaceCardProps>`
  margin: 0px 30px 0px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .name {
    font-weight: 700;
    font-size: 14px !important;
    line-height: 17px;
    color: ${colors.text};
    margin: 0px;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Mulish', sans-serif !important;
  }

  .label {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 17px;
    color: ${colors.text300};
    margin: 0px;
    font-family: 'Mulish', sans-serif !important;
  }

  ${({ isOwnerCard }) =>
    !isOwnerCard &&
    css`
      flex-direction: column-reverse;
    `}
`;
export const WorkspaceCardSelectedTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Mulish', sans-serif !important;

  height: 22px;
  color: ${colors.primary};
  background-color: ${colors.purple50};
  padding: 2px 9px;
  margin: 0px;
  margin-right: 9px;
  border-radius: 63px;
`;
