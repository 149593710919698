import styled from 'styled-components';

export const UlColumn = styled.ul`
  display: flex;
  flex-direction: column;

  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
`;
