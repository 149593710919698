/* eslint-disable no-case-declarations */
import { ActionTypes, OnboardingIndexReducer } from './types';

const onboardingIndexReducer: OnboardingIndexReducer = (state = 1, action) => {
  switch (action.type) {
    case ActionTypes.SET_ONBOARDING_INDEX:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default onboardingIndexReducer;
