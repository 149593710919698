import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_QUESTIONS_INDEX = '@questionIndex/SET',
}
export type QuestionsIndex = number;

export type QuestionsIndexReducer = Reducer<
  QuestionsIndex,
  Action<ActionTypes>
>;
