import { colors } from 'AppV2/styles/colors';
import styled from 'styled-components';
import { MainContainer, SectionContainer } from '../index';

export const LoginSignupMainContainer = styled(MainContainer)`
  padding-top: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 9rem;
  background-color: ${colors.background100};

  .logo-content {
    width: 180px;
  }

  @media screen and (max-height: 766px) {
    padding-top: 1.5rem;
    gap: 1.5rem;
    .logo-content {
      width: 145px;
    }
  }

  @media screen and (max-width: 540px) {
    .logo-content {
      width: 120px;
    }
  }
`;

export const LoginSignupSectionContainer = styled(SectionContainer)`
  background-color: ${colors.background100};
  border: 1.2px solid #f8f8f8;
  border-radius: 6px;
  box-shadow: 0 9px 21px 0 #2a232321;

  .linkButton {
    font-weight: bold !important;
    color: var(--purple) !important;

    margin-left: 12px;
    font-size: 1.5rem;
    transition: 0.3s;
    :hover {
      opacity: 0.6;
    }
  }

  .change-form-content {
    height: 62px;
    background: #f8f8f8;
    border-radius: 4px 4px 0 0;
  }
  .buttons-content {
    height: 100%;
    display: flex;
  }
  .buttons-content > button {
    width: 94px;
    font-weight: 600;
    border-radius: 1px;
    transition: 0.3s;
    font-size: 1.5rem;
  }

  .inLogin {
    background-color: #ffffff;
    color: var(--purple);
    border-bottom: 2px solid var(--purple);
  }
  .notInLogin {
    background-color: #f8f8f8;
    :hover {
      opacity: 0.6;
      color: var(--purple);
    }
  }

  .inRegister {
    background-color: #ffffff;
    color: var(--purple);
    border-bottom: 2px solid var(--purple);
  }
  .notInRegister {
    background-color: #f8f8f8;
    :hover {
      opacity: 0.6;
      color: var(--purple);
    }
  }

  .welcome-text span {
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fa-eye-slash,
  .fa-eye {
    background: none;
    border: none;
    right: 0px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
      opacity: 0.6;
    }
  }

  .login-register-page a {
    font-size: 1.5rem;
  }

  #register-account-form .input-with-icon-left input {
    margin: 0px;
  }
  #register-account-form .input-with-icon-left {
    margin-top: 0.9rem;
  }

  .social-login-separator {
    font-size: 1.3rem;
    span {
      padding: 0px 6.3px;
    }
  }

  #btn-login {
    background-color: var(--purple);
  }
  #btn-register {
    background-color: var(--purple);
  }

  .error-message-content {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: flex-start;
  }
  .error-message-content span {
    font-size: 1.5rem;
    color: var(--light-red);
  }

  .input-with-icon-left {
    border: 2px solid rgb(0 0 0 / 0%);
    margin-top: 0.9rem;
  }

  #error-input {
    margin-bottom: 3.6rem;
    border-color: var(--light-red);
    border-radius: 4px;
    transition: 0.3s;
    .error-message-content {
      bottom: -29px;
    }
  }
  #emailaddress {
    margin-bottom: 0px;
  }
  #password {
    margin-bottom: 0px;
  }

  .forgot-password-content {
    margin: 0.9rem 0rem 1.5rem 0rem;
  }
  .forgot-password {
    font-size: 1.5rem;
    color: var(--lightblue);
    transition: 0.3s;
    margin: 1.8rem 0rem;
    :hover {
      color: var(--purple);
    }
  }

  .user-terms-container {
    display: flex;
    padding: 1rem 0.4rem;
    align-items: center;
    gap: 0.9rem;
    input {
      width: 21px;
      height: 21px;
      margin: 0px;
      cursor: pointer;
      box-shadow: none;
    }
    p {
      text-align: left;
      font-size: 1.2rem;
      margin: 0px;
      margin-top: 12px;
      strong {
        font-size: 1.2rem;
      }
      a {
        font-size: 1.2rem;
        color: var(--lightblue);
        cursor: pointer;
        :hover {
          color: var(--purple);
        }
      }
    }
  }

  /* #spinner {
        width: 100%;
        margin-top: 1.5rem;
        font-size: 2.1rem;
        color: var(--purple);
    } */

  @media screen and (max-width: 540px) {
    box-shadow: none;
    border: none;
    width: 100%;

    input {
      padding: 0px 12px 0px 63px !important;
      font-size: 1.2rem !important;
    }
    input::placeholder {
      font-size: 1.2rem !important;
    }

    .login-register-page {
      padding: 18px;
    }

    .change-form-content {
      height: 45px;
      button {
        font-size: 1.2rem;
      }
    }

    .welcome-text {
      margin-bottom: 18px;
      h3 {
        font-size: 2.1rem !important;
      }
    }
    .account-type {
      margin-bottom: 18px;
    }

    .social-login-separator {
      margin: 2.1rem 0rem;
    }
  }

  @media screen and (max-height: 767px) and (min-width: 541px) {
    .change-form-content {
      height: 45px;
    }

    .login-register-page {
      padding: 27px;
    }
  }
`;
