import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_FILL_PROFILE_FORMS_INDEX = '@formIndex/SET',
}

export type FillProfileFormsIndex = number;

export type FillProfileFormsIndexReducer = Reducer<
  FillProfileFormsIndex,
  Action<ActionTypes>
>;
