import { CurrentUser } from './../../../../Contexts/AuthContext/types';
import { InvitedRepository } from './../../../services/Workspace/GetInvitedWorkspaces/types';

export const formatInvitedWorkspaces = (
  invitedWorkspaces: InvitedRepository[],
) =>
  invitedWorkspaces.map(workspace => ({
    isOwner: false,
    workspaceId: workspace.workspaceId,
    userId: workspace.uid,
    userName: workspace.displayName,
    userPhoto: workspace.userPhoto,
  }));

export const currentUserWorkspaceObject = (currentUser: CurrentUser) => ({
  isOwner: true,
  workspaceId: currentUser?.claims ? currentUser.claims.workspaceId : '',
  userId: currentUser?.uid,
  userName: currentUser?.displayName,
  userPhoto: currentUser?.photoURL,
});
