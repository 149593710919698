/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { memo, useEffect, useState } from 'react';
import { useAuth } from 'Contexts/AuthContext';
import WelcomeText from 'Components/Molecules/LoginSignUp/WelcomeText';
import AccountType from 'Components/Molecules/LoginSignUp/AccountType';
import SignUpForm from 'Components/Molecules/LoginSignUp/SignUpForm';
import SocialsButtons from 'Components/Molecules/LoginSignUp/SocialsButtons';
import FormNewSocialLoginUser from './FormNewSocialLoginUser';

const SingUp = ({ showsAccountType = true, showsLoginOption = true }) => {
  const { isFirstSocialLogin } = useAuth();
  const [isNewSocialLoginUser, setIsNewSocialLoginUser] = useState(false);

  useEffect(() => {
    if (isFirstSocialLogin) {
      return setIsNewSocialLoginUser(true);
    } else {
      return setIsNewSocialLoginUser(false);
    }
  }, [isFirstSocialLogin]);

  return isNewSocialLoginUser ? (
    <FormNewSocialLoginUser />
  ) : (
    <div className="login-register-page">
      <WelcomeText isSignUpContent showsLoginOption={showsLoginOption} />
      {showsAccountType && <AccountType />}
      <SignUpForm />
      <SocialsButtons isRegisterButton />
    </div>
  );
};

export default memo(props => <SingUp {...props} />);
