/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ExitIcon({ color }: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.62508 9.46168C1.62508 9.76451 1.37308 10.0103 1.06264 10.0103C0.752198 10.0103 0.500195 9.76451 0.500195 9.46168V4.03578C0.500195 2.29293 1.95378 0.875 3.74118 0.875H7.30064C9.09169 0.875 10.5489 2.29649 10.5489 4.04361V4.70698C10.5489 5.00909 10.2969 5.25491 9.98648 5.25491C9.67604 5.25491 9.42404 5.00909 9.42404 4.70698V4.04361C9.42404 2.90072 8.47154 1.97229 7.30064 1.97229H3.74118C2.57393 1.97229 1.62508 2.89858 1.62508 4.03578V9.46168ZM9.42385 11.2937C9.42385 10.9909 9.67585 10.7451 9.98629 10.7451C10.2967 10.7451 10.5487 10.9909 10.5487 11.2937V11.9642C10.5487 13.7071 9.09515 15.125 7.30775 15.125H3.74756C1.95724 15.125 0.5 13.7035 0.5 11.9564C0.5 11.6536 0.752003 11.4085 1.06244 11.4085C1.37288 11.4085 1.62489 11.6536 1.62489 11.9564C1.62489 13.0993 2.57739 14.0277 3.74756 14.0277H7.30775C8.475 14.0277 9.42385 13.1021 9.42385 11.9642V11.2937ZM15.4575 7.79066C15.3705 7.58474 15.1653 7.45078 14.9374 7.45078H6.16182C5.85139 7.45078 5.59865 7.69732 5.59865 8.00014C5.59865 8.30297 5.85139 8.54879 6.16182 8.54879H13.5758L12.4064 9.68456C12.1858 9.89832 12.1858 10.2453 12.4049 10.4605C12.5145 10.5681 12.6591 10.6222 12.803 10.6222C12.9469 10.6222 13.0901 10.5681 13.2004 10.4619L15.3348 8.38918C15.4954 8.23243 15.5444 7.99587 15.4575 7.79066ZM13.3414 6.99555C13.1982 6.99555 13.055 6.94211 12.9455 6.83665L12.4071 6.31722C12.1865 6.10418 12.1851 5.75646 12.4035 5.54199C12.6226 5.32538 12.9776 5.32396 13.1989 5.53771L13.7365 6.05644C13.9579 6.27019 13.9593 6.6172 13.7409 6.83238C13.6306 6.94068 13.486 6.99555 13.3414 6.99555Z"
        fill={iconColor}
      />
    </svg>
  );
}
