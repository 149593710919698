import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_CULTURE_COMPANY_TYPE = '@userCultureCompanyType/SET',
}
export type UserCultureCompany = {
  adhocracia: string;
  cla: string;
  companyCulture: string;
  hierarchy: string;
  market: string;
};

export type UserCultureCompanyReducer = Reducer<
  UserCultureCompany,
  Action<ActionTypes>
>;
