import { colors } from 'AppV2/styles/colors';
import styled, { css } from 'styled-components';

interface RadioSelectProps {
  isChecked: boolean;
}

export const ModalSelectAppLanguageContainer = styled.div`
  width: 272px;
`;

export const Title = styled.h3`
  width: fit-content;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Mulish', sans-serif !important;

  color: ${colors.primary};
  background-color: ${colors.purple50};
  border-radius: 8px;
  margin: 0 auto;
  padding: 8px 10px;
`;

export const SelectLanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 42px 0px;
`;

export const RadioInputContent = styled.div<RadioSelectProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  width: 100%;
  border-radius: 62px;
  border: 1px solid ${colors.faded};

  padding: 6px;
  transition: 0.3s;

  label {
    margin: 0px;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: ${colors.text};
    font-family: 'Mulish', sans-serif !important;
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      border-color: ${colors.primary};
      background-color: ${colors.purple50};
    `}
`;
