import { ActionTypes, SoftwaresReducer } from './types';

const localStorageSoftwares = localStorage.getItem('userSoftwares');

const userSoftwaresInit: any[] = [];

const userSoftwares = localStorageSoftwares
  ? JSON.parse(localStorageSoftwares)
  : userSoftwaresInit;

const softwaresReducer: SoftwaresReducer = (state = userSoftwares, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SOFTWARES:
      return [...state, action.payload];

    case ActionTypes.SET_SOFTWARES:
      return action.payload;

    case ActionTypes.REMOVE_SOFTWARES:
      return action.payload;

    case ActionTypes.RESET_SOFTWARES:
      return action.payload;

    default:
      return state;
  }
};

export default softwaresReducer;
