import styled, { CSSObject, css } from 'styled-components';
import { colors } from 'AppV2/styles/colors';
import PopUpTopArrowSVG from './PopUpTopArrow.svg';
import { motion } from 'framer-motion/dist/framer-motion';

interface OptionsButtonProps {
  isActive: boolean;
  isDropDownOpen: boolean;
  loading: 'true' | 'false';
  styles?: CSSObject;
}

interface OptionLinkProps {
  color?: 'regular' | 'warning';
  hasDivider?: boolean;
  iconSize?: string;
  textFontSize?: string;
  styles?: CSSObject;
  animationOnAction: boolean;
}

export const OptionsButton = styled.button<OptionsButtonProps>`
  height: 100%;
  max-height: 48px;
  margin-left: auto;
  width: 10px;

  position: relative;
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;

  .option-bullet {
    position: relative;
    .hover-content {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: -12px;
      left: -12px;
      content: '';
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: ${colors.grayFaded};
    }
  }

  :hover {
    .option-bullet {
      .hover-content {
        opacity: 1;
      }
    }
  }

  :disabled {
    opacity: 0.2;
  }

  ${({ isDropDownOpen }) =>
    isDropDownOpen &&
    css`
      .option-bullet {
        .hover-content {
          opacity: 1;
        }
      }
    `}

  ${({ loading }) => {
    if (loading === 'false') {
      return css`
        .option-bullet,
        ::after,
        ::before {
          transition: 0.3s;
          content: '';
          line-height: 0px;
          width: 3px;
          min-width: 3px;
          max-width: 3px;
          height: 3px;
          background-color: ${({ isActive }) =>
            isActive ? colors.primary : colors.purple600};
          border-radius: 50%;
        }
      `;
    }
  }}

  ${({ styles }) => styles};
`;

export const OptionsAnimationProps = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
};

export const OptionsPopupContainer = styled(motion.div)`
  min-width: 180px;
  padding: 8px;
  background-color: ${colors.background100};
  box-shadow: 0px 10px 18px rgba(38, 22, 113, 0.1) !important;
  border: 1px solid ${colors.faded};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  position: absolute;
  top: 28px !important;
  right: -21px !important;
  z-index: 9999998;

  ::after {
    content: '';
    position: absolute;
    top: -13.9px;
    right: 12px;
    width: 21px;
    height: 14px;
    background-image: url(${PopUpTopArrowSVG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 4.5px;
  }

  ::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 200%;
    top: -50%;
    left: -12%;
    /* z-index: -1; */
  }

  @media screen and (max-width: 480px) {
    gap: 3px;
    padding-bottom: 6px;
  }
`;

export const OptionLink = styled.div.attrs({
  className: 'option-link',
})<OptionLinkProps>`
  font-family: 'Mulish', sans-serif !important;
  color: ${({ color }) => (color === 'warning' ? colors.red : colors.text)};
  font-weight: 600;
  font-size: ${({ textFontSize }) => `${textFontSize || '12px'}`};
  line-height: 28px;
  padding: 6px 14px;
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 14px;

  position: relative;
  transition: 0.3s;
  :hover {
    opacity: 0.9;
    ::after {
      content: '';
      z-index: 3;
      position: absolute;
      left: -8px;
      width: calc(100% + 16px);
      height: 100%;
      background-color: ${({ color }) =>
        color === 'warning' ? '#FFECEC' : '#edebff'};
    }
    .icon-content,
    .link-text-content {
      z-index: 6;
      ${({ color }) => (color === 'warning' ? colors.red : colors.purple300)};
    }
  }

  .link-text-content {
  }

  .icon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 16px;
  }

  svg {
    width: ${({ iconSize }) => iconSize || '14px'};
    min-width: ${({ iconSize }) => iconSize || '14px'};
    height: ${({ iconSize }) => iconSize || '14px'};
    color: ${({ color }) => (color === 'warning' ? colors.red : colors.text)};
  }

  ${({ color, hasDivider }) =>
    color === 'warning' &&
    hasDivider &&
    css`
      margin-top: 0px;
      ::before {
        content: '';
        position: absolute;
        left: 0px;
        top: -5.5px;
        width: 100%;
        height: 1px;
        background-color: ${colors.faded};
        border-radius: 12px;
        @media screen and (max-width: 480px) {
          top: -1px;
        }
      }
    `}

  /* ${({ animationOnAction }) => {
    if (animationOnAction) {
      return css`
        @keyframes activeAnimation {
          from {
            scale: 0.5;
          }
          to {
            scale: 1;
          }
        }

        :active {
          animation: activeAnimation 0.2s ease-out;
        }
      `;
    }
  }} */

  ${({ styles }) => styles}
`;
