import { ActionTypes, MyCompanyReducer } from './types';
/* eslint-disable no-case-declarations */
const storagedUserData = localStorage.getItem('userData');

const myCompanyState = storagedUserData
  ? { ...JSON.parse(storagedUserData) }
  : {};

const myCompanyReducer: MyCompanyReducer = (state = myCompanyState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MY_COMPANY:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default myCompanyReducer;
