import styled, { CSSObject } from 'styled-components';
import { motion } from 'framer-motion/dist/framer-motion';
import { colors } from 'AppV2/styles/colors';
import { HeaderHeight } from 'AppV2/templates/components/Header/styles';

interface CustomStyle {
  customStyles?: CSSObject;
}

export const BackdropWrapper = styled(motion.aside)<CustomStyle>`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 99999;
  height: calc(100vh - ${HeaderHeight});
  height: calc(100dvh - ${HeaderHeight});
  overflow: hidden;

  ${({ customStyles }) => customStyles}
`;

export const BackdropAnimation = {
  initial: { width: 0 },
  animate: { width: 525 },
  exit: { width: 0 },
  transition: { type: 'tween', duration: 0.36 },
};

export const MobileBackdropAnimation = {
  initial: { width: 0 },
  animate: { width: 'initial' },
  exit: { width: 0 },
  transition: { type: 'tween', duration: 0.2 },
};

export const Container = styled(motion.section)<CustomStyle>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 493px;
  min-width: 493px;
  max-width: 493px;
  background-color: ${colors.background700};
  border-left: 1px solid ${colors.faded};
  box-shadow: 0px 18px 40px 0px rgba(142, 121, 235, 0.35);

  ${({ customStyles }) => {
    if (customStyles?.width) {
      return {
        ...customStyles,
        minWidth: customStyles?.width,
        maxWidth: customStyles?.width,
      };
    }

    return customStyles;
  }}

  @media screen and (max-width: 480px) {
    min-width: initial;
    padding-bottom: 0px !important;
  }
`;

export const ContainerAnimation = {
  initial: { x: 493 },
  animate: { x: 0 },
  exit: { x: 493 },
  transition: { type: 'tween', duration: 0.36 },
};

export const MobileContainerAnimation = {
  initial: { x: '100%' },
  animate: { x: 0 },
  exit: { x: '100%' },
  transition: { type: 'tween', duration: 0.36 },
};

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;

  background-color: ${colors.purpleFaded};
  border-bottom: 1px solid ${colors.primary};

  .modal-aside-title-content {
    display: flex;
    align-items: center;
    gap: 6px;

    .modal-aside-icon-content {
      display: grid;
      place-items: center;
      width: 20px;
      color: ${colors.primary};
    }

    .modal-aside-title {
      color: ${colors.primary};
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      margin-bottom: 0px;
    }
  }

  .modal-aside-button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 2px;
  }
`;
