import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_CMS_PROFESSIONALS = '@CMSProfessional/SET',
}

export type CMSProfessional = {
  isAdvancedFilter?: boolean;
  isFilter: boolean;
  currentPage?: number;
  searchJSON: null | {
    operation: string;
    pageSize: number;
    page: number;
    query: any;
  };
};

export type CMSProfessionalsReducer = Reducer<
  CMSProfessional,
  Action<ActionTypes, CMSProfessional>
>;
