import { ActionTypes, NewLanguagesReducer } from './types';

/* eslint-disable no-case-declarations */
const newLanguagesReducer: NewLanguagesReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_NEW_LANGUAGE:
      return action.payload;

    case ActionTypes.ADD_NEW_LANGUAGE:
      return [...state, action.payload];

    case ActionTypes.REMOVE_NEW_LANGUAGE:
      return action.payload;

    default:
      return state;
  }
};

export default newLanguagesReducer;
