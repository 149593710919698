import { useCookies } from 'react-cookie';
import { useAuth } from 'Contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { queryClient } from 'AppV2/configs/QueryClient';
import { WorkspacePathsEnum } from 'AppV2/assets/allWorkspacePaths';
import { setAxiosDefaultWorkspaceId } from 'AppV2/common/setAxiosDefaultOptions';
import { CurrentUser } from 'Contexts/AuthContext/types';
import {
  GetInvitedWorkspacesQueryCacheKey,
  InvitedRepository,
} from './../../services/Workspace/GetInvitedWorkspaces/types';
import {
  WorkspaceUserNameCookieKey,
  WorkspaceIdCookieKey,
  WorkspaceUserPhotoCookieKey,
  WorkspaceForbiddensSessionStorageKey,
} from '../../services/Workspace/index';
import { useCallback } from 'react';
import { ProfessionsGroupQueryCacheKey } from 'AppV2/services/ProfessionsGroup/GetProfessionsGroup/types';

export type SelectWorkspaceParams = {
  workspaceId: string;
  owner: {
    name: string;
    photoURL: string;
  };
  onSuccess?: () => void;
  removeQueries?: boolean;
};

export const useWorkspace = () => {
  // HOOKS
  const history = useHistory();
  const { currentUser } = useAuth();
  const [cookie, setCookie] = useCookies([
    `${WorkspaceIdCookieKey}${currentUser?.uid}`,
    `${WorkspaceUserNameCookieKey}${currentUser?.uid}`,
    `${WorkspaceUserPhotoCookieKey}${currentUser?.uid}`,
  ]);

  // FUNCTIONS
  const selectWorkspace = useCallback(
    ({
      workspaceId,
      owner,
      onSuccess,
      removeQueries = true,
    }: SelectWorkspaceParams) => {
      setAxiosDefaultWorkspaceId(workspaceId);
      setCookie(`${WorkspaceIdCookieKey}${currentUser?.uid}`, workspaceId);
      setCookie(`${WorkspaceUserNameCookieKey}${currentUser?.uid}`, owner.name);
      setCookie(
        `${WorkspaceUserPhotoCookieKey}${currentUser?.uid}`,
        owner.photoURL,
      );

      if (removeQueries) {
        const querieCache = queryClient.getQueryCache().getAll();

        querieCache
          .filter(
            querie =>
              ![
                ProfessionsGroupQueryCacheKey,
                GetInvitedWorkspacesQueryCacheKey,
              ].includes(querie.queryKey as string),
          )
          .forEach(querie => queryClient.removeQueries(querie.queryKey));
      }

      if (onSuccess) {
        return onSuccess();
      }

      if (history.location.pathname !== '/dashboard') {
        return history.push('/dashboard');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, cookie, history],
  );

  const setSessionStorageWorkspaceSelectedForbiddens = (
    workspaceSelected: InvitedRepository,
  ) => {
    const thisWorkspaceForbiddens =
      workspaceSelected?.forbiddens?.map(permission => permission.path) || [];

    sessionStorage.setItem(
      WorkspaceForbiddensSessionStorageKey,
      JSON.stringify(thisWorkspaceForbiddens),
    );

    return thisWorkspaceForbiddens as WorkspacePathsEnum[];
  };

  const getSessionStorageWorkspaceSelectedForbiddens =
    (): WorkspacePathsEnum[] =>
      JSON.parse(
        sessionStorage.getItem(WorkspaceForbiddensSessionStorageKey) || '[]',
      );

  const hasWorkspacePathPermission = (workspacePath: WorkspacePathsEnum) =>
    !getSessionStorageWorkspaceSelectedForbiddens().find(
      forbiddenPath => forbiddenPath === workspacePath,
    );

  const isAnotherWorkspaceSelected = useCallback(
    (currentUser: CurrentUser) => {
      try {
        const workspaceIdCookie =
          cookie[`${WorkspaceIdCookieKey}${currentUser.uid}`];

        if (workspaceIdCookie) {
          return workspaceIdCookie !== currentUser.claims.workspaceId;
        }
      } catch {
        return false;
      }
    },
    [cookie],
  );

  const getUserNameOfWorkspaceSelected = (currentUser: CurrentUser) =>
    cookie[`${WorkspaceUserNameCookieKey}${currentUser.uid}`];

  return {
    selectWorkspace,
    setSessionStorageWorkspaceSelectedForbiddens,
    getSessionStorageWorkspaceSelectedForbiddens,
    getUserNameOfWorkspaceSelected,
    hasWorkspacePathPermission,
    isAnotherWorkspaceSelected,
  };
};
