import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MODAL_FORM = '@modalForm/SET',
}
export type ModalForm = {
  isOpen: boolean;
  isEditForm: boolean;
  thisFormIndex: any;
  thisJobOrEducationIndex: any;
};

export type ModalFormReducer = Reducer<ModalForm, Action<ActionTypes>>;
