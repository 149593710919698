/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import * as S from './styles';
import PhoneIcon from 'AppV2/assets/icons/Phone';
import { translateText } from 'AppV2/common/translateText';

export const Notification = (): ReactElement => {
  return (
    <S.Container>
      <div className="icon-wrapper">
        <PhoneIcon />
      </div>
      <p className="header-notification-paragraph">
        {translateText('HEADER:NOTIFICATION_NO_MOBILE_PAGE')}
      </p>
    </S.Container>
  );
};
