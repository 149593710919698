import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_MEMBER_MY_COMPANY_DATA = '@memberMyCompanyData/SET',
}
export type MemberMyCompanyData = any;

export type MemberMyCompanyDataReducer = Reducer<
  MemberMyCompanyData,
  Action<ActionTypes>
>;
