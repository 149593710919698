import {
  ActionTypesProfilePicture,
  ActionTypesReloadProfilePicture,
  ProfilePictureReducer,
  ReloadProfilePictureReducer,
} from './types';

const profilePictureInit = {
  isOpenCropModal: false,
  url: '',
  file: '',
};

export const profilePictureReducer: ProfilePictureReducer = (
  state = profilePictureInit,
  action,
) => {
  switch (action.type) {
    case ActionTypesProfilePicture.SET_PROFILE_PICTURE:
      return action.payload;

    default:
      return state;
  }
};

export const reloadProfilePictureReducer: ReloadProfilePictureReducer = (
  state = false,
  action,
) => {
  switch (action.type) {
    case ActionTypesReloadProfilePicture.RELOAD_PROFILE_PICTURE:
      return action.payload;

    default:
      return state;
  }
};
