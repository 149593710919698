/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ArrowRightSolidIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-3.48342e-07 1.03086L-8.87718e-08 6.96914C-5.25057e-08 7.79881 0.952092 8.26762 1.60971 7.76176L5.46959 4.79262C5.98997 4.39233 5.98997 3.60767 5.46959 3.20738L1.60971 0.238239C0.952091 -0.267622 -3.84608e-07 0.201189 -3.48342e-07 1.03086Z"
        fill={iconColor}
      />
    </svg>
  );
}
