/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useCallback, useRef, useState } from 'react';
import * as S from './styles';
import { ModalMobile } from 'AppV2/components/ModalMobile';
import { IllustrationSVG } from './IllustrationSVG';
import { MessageBox } from 'AppV2/components/MessageBox';
import { Button } from 'AppV2/components/Button';
import LinkIcon from 'AppV2/assets/icons/Link';
import { translateText } from 'AppV2/common/translateText';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalNoMobileOptimization = ({
  ...props
}: Props): ReactElement => {
  // STATES
  const [loading, setLoading] = useState(false);

  //	FUNCTIONS
  const handleCopyToClipboard = useCallback(() => {
    setLoading(true);

    try {
      navigator.clipboard.writeText(window.location.href);
    } catch (error) {
      console.log('[Navigator.Clipboard][ERROR]', error);
    }

    setTimeout(() => setLoading(false), 1200);
  }, []);

  return (
    <ModalMobile
      isOpen={props.isOpen}
      onClose={props.onClose}
      styles={S.ModalMobileCustomStyles}
    >
      <S.Container>
        <h1 className="modalmobile-title">
          {translateText('HEADER:MODAL_NO_MOBILE_TITLE')}
        </h1>

        <div className="illustration-wrapper flexbox-center">
          <IllustrationSVG />
        </div>

        <p className="description">
          {translateText('HEADER:MODAL_NO_MOBILE_DESCRIPTION_P1')}{' '}
          <strong>
            {translateText('HEADER:MODAL_NO_MOBILE_DESCRIPTION_STRONG')}
          </strong>{' '}
          {translateText('HEADER:MODAL_NO_MOBILE_DESCRIPTION_P2')}
        </p>

        <S.IssuesOL>
          <li>{translateText('HEADER:MODAL_NO_MOBILE_LI_1')}</li>
          <li>{translateText('HEADER:MODAL_NO_MOBILE_LI_2')}</li>
          <li>{translateText('HEADER:MODAL_NO_MOBILE_LI_3')}</li>
        </S.IssuesOL>

        <MessageBox
          description={`ℹ️ ${translateText(
            'HEADER:MODAL_NO_MOBILE_MESSAGE_BOX',
          )}`}
        />

        <div className="buttons-wrapper">
          <Button full onClick={handleCopyToClipboard} loading={loading}>
            <LinkIcon />
            {translateText('HEADER:MODAL_NO_MOBILE_BUTTON_COPY_LINK')}
          </Button>

          <Button bordered onClick={props.onClose}>
            {translateText('HEADER:MODAL_NO_MOBILE_BUTTON_CONTINUE')}
          </Button>
        </div>
      </S.Container>
    </ModalMobile>
  );
};
