/* eslint-disable no-case-declarations */
import { ActionTypes, UserIsAvailableReducer } from './types';

const userIsAvailableInit = true;

const userIsAvailableReducer: UserIsAvailableReducer = (
  state = userIsAvailableInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_IS_AVAILABLE:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default userIsAvailableReducer;
