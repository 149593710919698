import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export const APPV2GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    #root {
      display: flex;
    }

    ::-webkit-scrollbar {
      background-color: ${colors.background200} !important;
      width: 13px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${colors.text} !important;
      border-style: solid !important;
      border-width: 3px !important;
      border-radius: 8px !important;
      border-color: ${colors.background200} !important;
      box-shadow: none !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${colors.purple600} !important;
    }
  }
  html {
    font-size: 16px;
    /* user-select: none; */
  }
  body {
    -webkit-font-smoothing: antialiased;
    background: ${colors.background200};
    color: ${colors.purple600};
  }
  body, input, textarea, button {
    font-family: Mulish, sans-serif;
    font-weight: 400;
    font-size: 1rem;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: Mulish, sans-serif;
    color: ${colors.purple600};
  }
  p, label {
    color: ${colors.darkText};
		margin-bottom: 0px;
  }
  strong {
    font-weight: 700;
  }
  button {
    cursor: pointer;
  }
  img, svg {
    max-width: 100%;
  }
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .flexbox {
    display: flex;
  }
  .flexbox-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexbox-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .flexbox-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .flexbox-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flexbox-column-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexbox-column-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .cubicBezierAnimation, .cubic-bezier-animation {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
    @keyframes anvil {
        0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        }
    }
  }

  .styled-v2-scroll {
    overflow: auto;
    ::-webkit-scrollbar {
      background: ${colors.lightBackground} !important;
      background-color: ${colors.lightBackground} !important;
      width: 8px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.lightBackground} !important;
      background-color: ${colors.purple600} !important;
      border-radius: 9px !important;
      border-color: transparent;
      box-shadow: none !important;
    }
  }

	.secondary-styled-scroll {
		overflow: auto;
		::-webkit-scrollbar {
			background-color: transparent !important;
			width: 8px !important;
		}
		::-webkit-scrollbar-thumb {
			background-color: ${`${colors.faded} !important`};
			border-color: ${colors.background200} !important;
		}
	}

  .styled-invisible-v2-scroll, .invisible-scroll {
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: transparent !important;
      border: none !important;
      width: 0px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border: none !important;
      border-radius: 0px !important;
    }
  }

  .scroll-shadow {
    box-shadow: 0px 18px 40px rgba(142, 121, 235, 0.35);
  }

  .styled-scroll-bar {
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: ${colors.lightBackground};
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${colors.light};
      border-radius: 9px;
    }
  }

	@media screen and (max-width: 768px) {
		.styled-scroll {
				overflow: auto;
			::-webkit-scrollbar {
				background-color: transparent !important;
				border: none !important;
				width: 0px !important;
			}
			::-webkit-scrollbar-thumb {
				background-color: transparent !important;
				border: none !important;
				border-radius: 0px !important;
			}
		}
	};

  @media screen and (max-width: 480px) {
    html {
      font-size: 12px;
      p {
        line-height: 1.5rem;
      }
      label {
        margin-bottom: 4px;
      }
    }
  }
`;
