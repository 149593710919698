import {
  sessionStorageIsSquadOwnerSession,
  sessionStorageShouldRedirectToClosePageAfterLogin,
  sessionStorageShouldRedirectToMyProjects,
  sessionStorageUrlToRedirect,
} from 'Common/sessionStorage';

const resetSquadMemberData = {
  profileLevel: {
    myProfile: 0,
    experiences: 0,
    educations: 0,
    skills: 0,
    languages: 0,
    values: 0,
  },
  userData: {
    about: '',
    country: '',
    city: '',
    nativeLanguage: 'português',
    englishLevel: '',
    pages: {
      linkedin: '',
      behance: '',
      github: '',
      webPage: '',
    },
    dailyTime: [],
    newLanguagesList: [],
    newLanguagesListForModal: [],
    photoUrl: '',
  },
  userExperiences: [],
  userEducations: [],
  userSkills: [],
  userSoftwares: [],
  userMethodologies: [],
  userLanguages: [],
  userDatabases: [],
  userValues: {
    hourValue: '',
    receive: '',
    monthValue: '',
  },
  resetLocalStorage: function () {
    localStorage.removeItem('userValues');
    localStorage.removeItem('userDatabases');
    localStorage.removeItem('userLanguages');
    localStorage.removeItem('userExperiences');
    localStorage.removeItem('userSkills');
    localStorage.removeItem('userSoftwares');
    localStorage.removeItem('profileLevel');
    localStorage.removeItem('userEducations');
    localStorage.removeItem('userData');
    localStorage.removeItem('userMethodologies');
    localStorage.removeItem('projectBriefFlow');
    localStorage.removeItem('projectBriefLogin');
    sessionStorageShouldRedirectToClosePageAfterLogin('remove');
    sessionStorageUrlToRedirect('remove');
    sessionStorageIsSquadOwnerSession('remove');
    sessionStorageShouldRedirectToMyProjects('remove');
  },
  clearCacheData: function () {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
  },
};
export default resetSquadMemberData;
