import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';
import styled from 'styled-components';

export const TooltipContainer = styled(motion.div).attrs({
  className: 'tooltip-content',
})`
  position: absolute;

  background-color: ${colors.text};
  border-radius: 4px;
  padding: 4px 8px;
  color: ${colors.textLight};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  white-space: nowrap;

  .arrow-icon-content {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0px !important;
    left: -6px !important;
    svg {
      transform: rotate(0deg) !important;
      color: ${colors.text} !important;
      width: 9px !important;
      min-width: 9px !important;
      height: 9px !important;
    }
  }
`;
