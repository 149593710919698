/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { useSelector } from 'react-redux';
import { changeFormsTexts } from 'Texts/LoginSignUp';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'Contexts/AuthContext';

const ButtonsChangeForms = ({ showsSignInLoginOptions = true }) => {
  const history = useHistory();
  const { shouldLoadingSalesSignup } = useAuth();
  const { isLoginForm, browserLanguage } = useSelector(state => state);
  const { login, signup } = changeFormsTexts;

  return (
    <>
      {showsSignInLoginOptions ? (
        <div className="change-form-content">
          <div className="buttons-content">
            <button
              onClick={() => {
                return history.push('/login');
              }}
              className={isLoginForm ? 'inLogin' : 'notInLogin'}
            >
              {login[browserLanguage]}
            </button>

            <button
              onClick={() => {
                return shouldLoadingSalesSignup();
              }}
              className={isLoginForm ? 'notInRegister' : 'inRegister'}
            >
              {signup[browserLanguage]}
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ButtonsChangeForms;
