/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function ArrowLeftSolidIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 6.96914L6.5 1.03086C6.5 0.20119 5.54791 -0.267621 4.89029 0.238239L1.03041 3.20738C0.510026 3.60767 0.510026 4.39233 1.03041 4.79262L4.89029 7.76176C5.54791 8.26762 6.5 7.79881 6.5 6.96914Z"
        fill={iconColor}
      />
    </svg>
  );
}
