/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import {
  BulletPoint,
  ProgressBulletPointsContainer as Container,
} from './styles';

interface Props {
  totalSteps: number;
  currentStep: number;
}

export const ProgressBulletPoints = ({
  totalSteps,
  currentStep,
}: Props): ReactElement => {
  const stepsList = [];
  let positiveStepsCount = totalSteps >= 0 ? totalSteps : totalSteps * -1;

  while (positiveStepsCount > 0) {
    stepsList.push(`step-${positiveStepsCount}`);

    positiveStepsCount--;
  }

  return (
    <Container>
      {stepsList.map((step, index) => (
        <BulletPoint key={step} isCurrent={currentStep === index} />
      ))}
    </Container>
  );
};
