import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_IS_UPSELL_FINISH = '@isUpsellFinish/SET',
}

export type IsUpsellFinish = boolean;

export type IsUpsellFinishReducer = Reducer<
  IsUpsellFinish,
  Action<ActionTypes>
>;
