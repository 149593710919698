/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { IconProps } from '../../../interfaces/iconProps';

export default function NavBarHoverIcon({
  color,
}: IconProps): React.ReactElement {
  const iconColor = color || 'currentColor';

  return (
    <svg
      width="3"
      height="31"
      viewBox="0 0 3 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-1 0C1.20914 0 3 1.79086 3 4V27C3 29.2091 1.20914 31 -1 31V0Z"
        fill={iconColor}
      />
    </svg>
  );
}
