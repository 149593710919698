/* eslint-disable no-case-declarations */
import { ActionTypes, QuestionsIndexReducer } from './types';

const questionIndexReducer: QuestionsIndexReducer = (state = 1, action) => {
  switch (action.type) {
    case ActionTypes.SET_QUESTIONS_INDEX:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default questionIndexReducer;
