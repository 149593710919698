import { ActionTypes, CMSProjectsReducer } from './types';

/* eslint-disable no-case-declarations */
const INITIAL_VALUES = {
  isFilter: false,
  searchJSON: null,
};

const cmsProjectsReducer: CMSProjectsReducer = (
  state = INITIAL_VALUES,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_CMS_PROJECTS:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default cmsProjectsReducer;
