import { ActionTypes, EducationsReducer } from './types';

/* eslint-disable no-case-declarations */

const storagedEducations = localStorage.getItem('userEducations');

const userEducationsInit: any[] = [];

const userEducations = storagedEducations
  ? JSON.parse(storagedEducations)
  : userEducationsInit;

const educationsReducer: EducationsReducer = (
  state = userEducations,
  action,
) => {
  switch (action.type) {
    case ActionTypes.ADD_EDUCATIONS:
      return [...state, action.payload];

    case ActionTypes.REMOVE_EDUCATIONS:
      return action.payload;

    case ActionTypes.RESET_EDUCATIONS:
      return action.payload;

    default:
      return state;
  }
};

export default educationsReducer;
