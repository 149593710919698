import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_ABOUT = '@userAbout/SET',
}
export type UserAbout = {
  jobTitle: string;
  gender: string;
  email: string;
  dob: string;
  phoneNumber: {
    countryCode: string;
    phoneNumber: string;
  };
  workAvailability: string;
  level: string;
};

export type UserAboutReducer = Reducer<UserAbout, Action<ActionTypes>>;
