/* eslint-disable react/jsx-filename-extension */
import { ReactElement, ReactNode } from 'react';
import * as S from './styles';
import { CSSObject } from 'styled-components';
import XIcon from 'AppV2/assets/icons/XIcon';
import {
  AnimatePresence,
  MotionAdvancedProps,
} from 'framer-motion/dist/framer-motion';

interface Props {
  children: ReactNode;
  isOpen?: boolean;
  styles?: CSSObject;
  motionAnimation?: MotionAdvancedProps;
  indicatorSide?: 'left' | 'right';
  indicatorPosition?: 'top' | 'right' | 'left' | 'bottom';
  showCloseButton?: boolean;
  onClickButtonClose?: () => void;
}

export const PopUp = ({
  children,
  isOpen,
  styles,
  motionAnimation,
  indicatorSide = 'left',
  indicatorPosition = 'top',
  showCloseButton = true,
  onClickButtonClose,
}: Props): ReactElement => {
  return (
    <AnimatePresence>
      {isOpen && (
        <S.Container
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          indicatorSide={indicatorSide}
          indicatorPosition={indicatorPosition}
          styles={{ ...styles }}
          {...motionAnimation}
        >
          <div className="inner-wrapper">
            {showCloseButton && (
              <S.ClosePupUpButton
                type="button"
                indicatorSide={indicatorSide}
                onClick={ev => {
                  ev.stopPropagation();
                  onClickButtonClose && onClickButtonClose();
                }}
              >
                <XIcon />
              </S.ClosePupUpButton>
            )}

            {children}
          </div>
        </S.Container>
      )}
    </AnimatePresence>
  );
};
