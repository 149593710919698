import { ModalMainDefault } from './variations/Default';
import { ModalMainAside } from './variations/Aside';
import { ModalSecondary } from './variations/Secondary';

type ModalMainDefaultType = typeof ModalMainDefault;
type ModalSecondaryType = typeof ModalSecondary;
type ModalMainAsideType = typeof ModalMainAside;
interface CompoundedComponent extends ModalMainDefaultType {
  Secondary: ModalSecondaryType;
  Aside: ModalMainAsideType;
}

export const ModalMain = ModalMainDefault as CompoundedComponent;
ModalMain.Aside = ModalMainAside;
ModalMain.Secondary = ModalSecondary;
