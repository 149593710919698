/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import { translateText } from 'AppV2/common/translateText';
import { Avatar } from 'AppV2/components/Avatar';
import { colors } from 'AppV2/styles/colors';
import {
  WorkspaceCardContainer as Container,
  WorkspaceCardTitleLabelContent as TitleLabelContent,
  WorkspaceCardSelectedTag as SelectedTag,
} from './styles';
import { Options } from 'AppV2/components/Options';
import ArrowsUpDownIcon from 'AppV2/assets/icons/ArrowsUpDown';
import { SelectWorkspaceParams } from 'AppV2/hooks/Workspace';

interface Props {
  workspaceId: string;
  userPhoto: string;
  userName: string;
  isOwnerCard: boolean;
  isSelected: boolean;
  onSelectWorkspace: (params: SelectWorkspaceParams) => void;
}

export const WorkspaceCard = ({
  workspaceId,
  userPhoto,
  userName,
  isOwnerCard,
  isSelected,
  onSelectWorkspace,
}: Props): ReactElement => {
  return (
    <Container
      isSelected={isSelected}
      onClick={(e: any) => {
        if (e.target.className !== 'hover-content' && !isSelected) {
          onSelectWorkspace({
            workspaceId: workspaceId,
            owner: { name: userName, photoURL: userPhoto },
          });
        }
      }}
    >
      <div className="left-side-content">
        <Avatar
          width="48px"
          url={userPhoto}
          borderColor={!isSelected && colors.faded}
        />

        <TitleLabelContent isOwnerCard={isOwnerCard}>
          <h2 className="name">{userName}</h2>
          <label className="label">
            {isOwnerCard
              ? translateText('HEADER_USER_MENU:MODAL_WORKSPACE_CARD_LABEL')
              : translateText('HEADER_USER_MENU:SPACE_OF')}
          </label>
        </TitleLabelContent>
      </div>

      <div className="right-side-content">
        {isSelected && (
          <SelectedTag>
            {translateText(
              'HEADER_USER_MENU:MODAL_WORKSPACE_CARD_SELECTED_TAG',
            )}
          </SelectedTag>
        )}

        <Options
          disabled={isSelected}
          isActive={isSelected}
          dropDownLinks={[
            {
              text: translateText(
                'HEADER_USER_MENU:MODAL_WORKSPACE_CARD_SELECT',
              ),
              icon: <ArrowsUpDownIcon />,
              action: () =>
                onSelectWorkspace({
                  workspaceId: workspaceId,
                  owner: { name: userName, photoURL: userPhoto },
                }),
            },
          ]}
        />
      </div>
    </Container>
  );
};
