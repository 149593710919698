/* eslint-disable no-case-declarations */
import { ActionTypes, TestsCompletedReducer } from './types';

const testsCompletedReducer: TestsCompletedReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_TESTS_COMPLETED:
      const { payload } = action;

      return [...state, payload];

    default:
      return state;
  }
};

export default testsCompletedReducer;
