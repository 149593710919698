import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { GetWorkspaceInviteRequest, GetWorkspaceInviteResponse } from './types';

export const getInvite = async (
  request: GetWorkspaceInviteRequest,
): Promise<AxiosResponse<GetWorkspaceInviteResponse>> => {
  try {
    const response = await apiV2Instance.get(`/workspace/getInvite`, {
      params: request,
    });
    console.log('[services][Workspace][GetInvite][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][Workspace][GetInvite][ERROR]', errorResponse);

    throw errorResponse;
  }
};
