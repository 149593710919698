/* eslint-disable array-callback-return */
const getPageValue = (urlPage: string) => {
  const pages = {
    '/confirm-email': 0,
    '/email-checked': 0,
    // '/service': 0,
    '/squadMember-register': 0,
    '/squadMember-fill-profile': 1,
    '/squadOwner-register': 3,
    '/project-brief-forms': 2,
    '/project-brief-selection': 2,
    '/project-brief-onboarding': 2,
    '/project-brief-squadOwner-view': 2,
    '/squadOwner-profile': 4,
    '/tests-selection': 2,
    '/tests-personality-questions': 2,
    '/tests-organizational-culture-questions': 2,
    '/tests-softskills-questions': 2,
    '/tests-organizational-culture-results': 2,
    '/tests-personality-results': 2,
    '/tests-softskills-results': 2,
    '/tests-organizational-culture-completed': 2,
    '/tests-personality-completed': 2,
    '/project-brief-view': 4,
    '/squadMember-viewProfile': 4,
    '/credits': 4,
    '/extracts': 4,
    '/my-projects': 4,
    '/allocate-hours': 4,
    '/squadMember-profile': 4,
    '/jobs': 3,
    '/support-squadOwner-view': 4,
    '/support-squadMember-view': 4,
  };

  return pages[urlPage];
};

const getNavigationStage = (navigationStage: string) => {
  const stages = {
    'squadMember-register': 0,
    'project-brief-selection': 0,
    'squadOwner-register': 1,
    'squadMember-fill-profile': 1,
    'squadMember-profile': 2,
    'squadOwner-profile': 2,
  };
  return stages[navigationStage];
};

export default async function usersPagePermission(
  userType: string,
  urlPage: string,
  navigationStage: string,
) {
  const service = ['/service'];
  const projectBriefView = ['/project-brief-view'];
  const squadMemberProjectsForbiddenPages = [
    '/project-brief-forms',
    '/project-brief-onboarding',
    '/project-brief-thanks-page',
    '/project-brief-searching-matchs-page',
    '/project-brief-matchs-page',
  ];
  const squadMemberForbiddenPages = [
    '/project-brief-squadOwner-view',
    '/project-brief-squad-approved-thanks-page',
    '/project-brief-squad-desapproved-thanks-page',
    '/squadOwner-register',
    '/squadOwner-profile',
    '/squadOwner-viewProfile',
    '/credits',
    '/allocate-hours',
    '/extracts',
    '/my-projects',
    '/support-squadOwner-view',
  ];
  const squadOwnerForbiddenPages = [
    '/squadMember-register',
    '/squadMember-fill-profile',
    '/tests-selection',
    '/tests-softskills-questions',
    '/tests-personality-questions',
    '/tests-organizational-culture-questions',
    '/tests-softskills-results',
    '/tests-personality-results',
    '/tests-organizational-culture-results',
    '/tests-softskills-completed',
    '/tests-personality-completed',
    '/tests-organizational-culture-completed',
    '/squadMember-profile',
    '/project-brief-squadMember-view',
    '/project-brief-accept-page',
    '/jobs',
  ];
  let urlPageCleaner = urlPage;

  if (service.includes(urlPage && urlPage.split('?')[0])) {
    return urlPage;
  }

  if (projectBriefView.includes(`/${urlPage?.split('/')[1]}`)) {
    urlPageCleaner = `/${urlPage.split('/')[1]}`;
  }

  if (userType === 'squadMember') {
    if (squadMemberProjectsForbiddenPages.includes(urlPage)) {
      return '/project-brief-blocked-page';
    }
    if (squadMemberForbiddenPages.includes(urlPage)) {
      return '/forbidden';
    }
  }

  if (userType === 'squadOwner') {
    if (squadOwnerForbiddenPages.includes(urlPage)) {
      return '/forbidden';
    }
  }
  const urlPageValue = getPageValue(urlPageCleaner);
  const navigationStageValue = getNavigationStage(navigationStage);

  // console.info(
  //   'urlPageValue: ' +
  //     urlPageValue +
  //     ' - ' +
  //     'navigationStageValue: ' +
  //     navigationStageValue +
  //     ' - ' +
  //     'navigationStage: ' +
  //     navigationStage,
  // );
  if (userType === 'squadMember') {
    if (navigationStageValue === 0) {
      if (urlPageValue === 0) {
        return false;
      }
      if (urlPageValue > 0) {
        return navigationStage;
      }
    }
    if (navigationStageValue === 1) {
      if (urlPageValue === 0) {
        return navigationStage;
      }
      if (urlPageValue === 1 || urlPageValue === 2) {
        return false;
      }
      if (urlPageValue > 2) {
        return navigationStage;
      }
    }
    if (navigationStageValue === 2) {
      if (urlPageValue < 2) {
        return navigationStage;
      }
      if (urlPageValue >= 2) {
        return false;
      }
    }
  }
  if (userType === 'squadOwner') {
    if (urlPageValue === 0) {
      return navigationStage;
    }
    if (navigationStageValue === 0) {
      if (urlPageValue === 2) {
        return false;
      }
      if (urlPageValue > 2) {
        return navigationStage;
      }
    }
    if (navigationStageValue === 1) {
      if (urlPageValue === 2) {
        return navigationStage;
      }
      if (urlPageValue === 3) {
        return false;
      }
      if (urlPageValue > 3) {
        return navigationStage;
      }
    }
    if (navigationStageValue === 2) {
      if (urlPageValue === 3) {
        return navigationStage;
      } else {
        return false;
      }
    }
  }
}
