import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_SQUAD_OWNER_DATA = '@squadOwnerData/SET',
}
export type SquadOwnerData = {
  name: string;
  lastName: string;
  phone: { number: string; code: string };
  company: string;
  positionCompany: string;
  employees: string;
  country: string;
  city: string;
  site: string;
};

export type SquadOwnerDataReducer = Reducer<
  SquadOwnerData,
  Action<ActionTypes>
>;
