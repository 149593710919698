/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useEffect } from 'react';
import { Container } from './styles';
import { ModalMain } from 'AppV2/components/Modal';
import { ButtonsWrapper } from 'AppV2/styles/containers/ButtonsWrapper';
import { Button } from 'AppV2/components/Button';
import { translateText } from 'AppV2/common/translateText';
import { useHistory } from 'react-router-dom';

export const IsNewOwnerBySalesSignupSessionStorageKey =
  'isNewOwnerBySalesSignup';

interface Props {
  isOpen: boolean;
  onCloseModal?: () => void;
  onRedirectToOwnerProfileWithMyCompanyModalOpened?: () => void;
}

export const ModalFillCompanyInfosAlert = ({
  isOpen,
  onCloseModal,
  onRedirectToOwnerProfileWithMyCompanyModalOpened,
}: Props): ReactElement => {
  const history = useHistory();

  const handleCloseModal = () => {
    sessionStorage.removeItem(IsNewOwnerBySalesSignupSessionStorageKey);

    onCloseModal && onCloseModal();
  };

  const handleRedirectToOwnerProfileWithMyCompanyModalOpened = () => {
    history.push('/squadOwner-profile', {
      shouldOpenMyCompanyModal: true,
    });

    sessionStorage.removeItem(IsNewOwnerBySalesSignupSessionStorageKey);

    onRedirectToOwnerProfileWithMyCompanyModalOpened &&
      onRedirectToOwnerProfileWithMyCompanyModalOpened();
  };

  useEffect(() => {
    if (isOpen) {
      sessionStorage.removeItem(IsNewOwnerBySalesSignupSessionStorageKey);
    }
  }, [isOpen]);

  return (
    <ModalMain isOpen={isOpen} closeButtonAction={handleCloseModal}>
      <Container>
        <h1>
          {translateText('DASHBOARD:MODAL_FILL_COMPANY_INFOS_ALERT_TITLE')}
        </h1>
        <p>
          {translateText(
            'DASHBOARD:MODAL_FILL_COMPANY_INFOS_ALERT_DESCRIPTION',
          )}
        </p>

        <ButtonsWrapper>
          <Button bordered onClick={handleCloseModal}>
            {translateText(
              'DASHBOARD:MODAL_FILL_COMPANY_INFOS_ALERT_CANCEL_BUTTON',
            )}
          </Button>
          <Button
            full
            onClick={handleRedirectToOwnerProfileWithMyCompanyModalOpened}
          >
            {translateText(
              'DASHBOARD:MODAL_FILL_COMPANY_INFOS_ALERT_CONFIRM_BUTTON',
            )}
          </Button>
        </ButtonsWrapper>
      </Container>
    </ModalMain>
  );
};
