/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { CheckBoxContainer } from 'Containers';

const CheckboxHandMade = ({
  thisChecked,
  thisValue,
  thisKey,
  thisOnClick,
  thisId,
  isWhitOutLabel = false,
  isBigLabel = false,
}) => {
  return (
    <CheckBoxContainer
      key={thisKey}
      onClick={thisOnClick}
      className="container"
      id={thisId}
      isWhitOutLabel={isWhitOutLabel}
      bigLabel={isBigLabel}
    >
      <input
        type="checkbox"
        value={thisValue}
        checked={thisChecked}
        onChange={e => e}
      />
      <span className="checkmark" id={thisChecked ? 'checked-mark' : ''}>
        <i className="fas fa-check-square" />
      </span>
      <span className="value">{thisValue}</span>
    </CheckBoxContainer>
  );
};

export default CheckboxHandMade;
