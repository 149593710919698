import styled from 'styled-components';
import PopUpTopArrowSVG from './PopUpTopArrow.svg';
import { colors } from 'AppV2/styles/colors';

export const WorkspacePopUpContainer = styled.div.attrs({
  className: 'cubicBezierAnimation',
})`
  background-color: ${colors.background100};
  border: 1px solid ${colors.purple400};
  border-radius: 8px;
  box-shadow: 0px 8px 22px rgba(73, 42, 216, 0.12);

  display: flex;
  align-items: center;
  gap: 6px;

  width: 309px;
  height: 131px;
  position: absolute;
  bottom: -130px;
  left: -134px;

  .close-popup-button {
    color: ${colors.purple400};
    position: absolute;
    left: -8px;
    top: -12px;
    width: 22px;
    height: 22px;
  }

  ::after {
    content: '';
    position: absolute;
    top: -14px;
    right: 12px;
    width: 21px;
    height: 14px;
    background-image: url(${PopUpTopArrowSVG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 3px;
  }

  .illustration-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 100%;
    img {
      width: 72px;
    }
  }

  .description-content {
    max-width: 175px;
    h2 {
      color: ${colors.primary};
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 0px;
    }
    p {
      color: ${colors.text};
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      margin: 0px;
    }
  }
`;
