/* eslint-disable no-case-declarations */
import { ActionTypes, UserMotivatorsReducer } from './types';

const userMotivatorsReducer: UserMotivatorsReducer = (
  state = {
    priorityCards: [],
    motivates: [],
    desmotivates: [],
    user: {},
    canRetakeQuiz: false,
    updateDate: 0,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_MOTIVATORS_TYPE:
      const { payload } = action;
      return payload;

    default:
      return state;
  }
};

export default userMotivatorsReducer;
