/* eslint-disable react/jsx-filename-extension */
import { ReactNode, useMemo } from 'react';
import { MessageBoxContent, Variation } from './styles';
import { CSSObject } from 'styled-components';

interface MessageBoxProps {
  icon?: ReactNode;
  iconStyle?: CSSObject;
  title?: string | ReactNode;
  description: string | ReactNode;
  styles?: CSSObject;
  variation?: Variation;
}

export const MessageBox = ({
  icon,
  iconStyle,
  title,
  description,
  styles,
  variation = 'primary',
}: MessageBoxProps): React.ReactElement => {
  const TITLE = useMemo(() => {
    if (typeof title === 'string') {
      return <h6>{title}</h6>;
    }

    return title;
  }, [title]);

  const DESCRIPTION = useMemo(() => {
    if (typeof description === 'string') {
      return <p>{description}</p>;
    }

    return description;
  }, [description]);

  return (
    <MessageBoxContent
      styles={styles}
      iconStyle={iconStyle}
      variation={variation}
    >
      {icon && <div className="icon-content">{icon}</div>}

      <div className="message-content">
        {title && TITLE}

        {DESCRIPTION}
      </div>
    </MessageBoxContent>
  );
};
