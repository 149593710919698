/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProviderDefaultProps } from 'AppV2/interfaces/providerDefaultProps';
import { ModalAlertProps, ModalContextData } from './types';
import { translateText } from 'AppV2/common/translateText';

const ModalContext = createContext<ModalContextData>({} as ModalContextData);
const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }: ProviderDefaultProps) => {
  const [openModal, setOpenModal] = useState({ key: null });
  const [openAlert, setOpenAlert] = useState({
    shouldOpen: false,
  } as ModalAlertProps);

  const isModalClosed = !openModal?.key;
  const isAlertClosed = !openAlert?.shouldOpen;

  const closeModal = () => !isModalClosed && setOpenModal({ key: null });
  const closeAlert = () =>
    !isAlertClosed &&
    setOpenAlert({ shouldOpen: null, description: null, type: null });

  const setDefaultAlertError = useCallback(
    (error: Error, onlyErrorMessage = false) => {
      const errorMessage = error?.message || '';
      const description = onlyErrorMessage
        ? errorMessage
        : `${translateText(
            'ALERT:GENERAL_ERROR_DESCRIPTION',
          )} \n\n${errorMessage}`;

      setOpenAlert({
        type: 'error',
        shouldOpen: true,
        title: translateText('ALERT:GENERAL_ERROR_TITLE'),
        description: description,
      });
    },
    [],
  );

  const setDefaultAlertWarning = useCallback(
    ({ errorMessage, customMessage }) => {
      const description = customMessage || errorMessage;

      setOpenAlert({
        type: 'warning',
        shouldOpen: true,
        title: translateText('ALERT:GENERAL_ERROR_TITLE'),
        description: description,
      });
    },
    [],
  );

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (openModal?.key) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }, [openModal]);

  const EXPORTED_VALUES = {
    openModal,
    openAlert,
    isModalClosed,
    isAlertClosed,
    setOpenModal,
    closeModal,
    setOpenAlert,
    closeAlert,
    setDefaultAlertError,
    setDefaultAlertWarning,
  };

  return (
    <ModalContext.Provider value={EXPORTED_VALUES}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider, useModal };
