import ArrowsUpDownIconDefault from './variations/Default';
import ArrowsUpDownIconV2 from './variations/v2';

type ArrowsUpDownIconDefaultType = typeof ArrowsUpDownIconDefault;
type ArrowsUpDownIconV2Type = typeof ArrowsUpDownIconV2;
interface CompoundedComponent extends ArrowsUpDownIconDefaultType {
  V2: ArrowsUpDownIconV2Type;
}

const ArrowsUpDownIcon = ArrowsUpDownIconDefault as CompoundedComponent;
ArrowsUpDownIcon.V2 = ArrowsUpDownIconV2;

export default ArrowsUpDownIcon;
