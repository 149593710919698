import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  ADD_DATABASES = '@databases/ADD',
  SET_DATABASES = '@databases/SET',
  REMOVE_DATABASES = '@databases/REMOVE',
  RESET_DATABASES = '@databases/RESET',
}

export type Databases = any[];

export type DatabasesExpertise = {
  tag: string;
  expertise: number;
}[];

export type DatabasesReducer = Reducer<Databases, Action<ActionTypes>>;
