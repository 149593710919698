/* eslint-disable no-case-declarations */
import { ActionTypes, FillProfileFormCompletedReducer } from './types';

const fillProfileFormCompletedReducer: FillProfileFormCompletedReducer = (
  state = [],
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_FORMS_COMPLETED:
      return [...state, action.payload];

    case ActionTypes.REMOVE_FORMS_COMPLETED:
      return action.payload;

    default:
      return state;
  }
};

export default fillProfileFormCompletedReducer;
