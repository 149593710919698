/* eslint-disable no-case-declarations */
import { ActionTypes, IsEndFormReducer } from './types';

const projectBriefIsEndFormReducer: IsEndFormReducer = (
  state = false,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_PROJECT_IS_END_FORM:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default projectBriefIsEndFormReducer;
