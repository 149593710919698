/* eslint-disable no-case-declarations */
import { ActionTypes, ToolTipReducer } from './types';

const toolTipReducer: ToolTipReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_TOOL_TIP:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default toolTipReducer;
