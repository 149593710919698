import { ReactNode } from 'react';
import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_OPEN_ALERT = '@openAlert/SET',
}

export type OpenAlert = {
  shouldOpen: boolean;
  type?: 'success' | 'error';
  title?: string;
  description?: string;
  icon?: ReactNode;
};

export type OpenAlertReducer = Reducer<
  OpenAlert,
  Action<ActionTypes, OpenAlert>
>;
