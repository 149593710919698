/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';

import {
  ShimmerThumbnail,
  ShimmerCircularImage,
  ShimmerText,
  ShimmerCategoryItem,
  ShimmerButton,
} from 'react-shimmer-effects';
import { SkeletonCardInfo, SkeletonLoaderContainer } from './styles';

interface Props {
  type: 'thumbnailBlock' | 'avatar' | 'text' | 'info' | 'card' | 'button'; // TODO::: config to Card and Button types
  className?: string;
  width?: string;
  height?: string;
  thumbnailBlockConfig?: {
    className?: string;
    width?: number;
    height?: number;
    rounded?: boolean;
    fitOnFrame?: boolean;
  };
  avatarConfig?: {
    className?: string;
    size?: number;
  };
  textConfig?: {
    className?: string;
    line?: number;
    gap?: 10 | 15 | 20 | 30;
  };
  infoConfig?: {
    className?: string;
    iconContentWidth?: number;
    iconContentHeight?: number;
  };
  cardConfig?: {
    className?: string;
    imageWidth?: number;
    imageHeight?: number;
    imageType?: 'circular' | 'thumbnail';
  };
}

export const SkeletonLoader = ({
  type,
  className,
  width,
  height,
  thumbnailBlockConfig,
  avatarConfig,
  textConfig,
  infoConfig,
  cardConfig,
}: Props): ReactElement => {
  const SKELETON_TYPES = {
    thumbnailBlock: (
      <ShimmerThumbnail
        {...thumbnailBlockConfig}
        fitOnFrame={!thumbnailBlockConfig?.height}
        className={className}
      />
    ),
    avatar: (
      <ShimmerCircularImage
        {...avatarConfig}
        size={avatarConfig?.size || 100}
        className={className}
      />
    ),
    text: (
      <ShimmerText
        {...textConfig}
        line={textConfig?.line || 1}
        gap={textConfig?.gap || 10}
        className={className}
      />
    ),
    info: (
      <SkeletonCardInfo>
        <div className="icon-content-skeleton-wrapper">
          <ShimmerThumbnail
            width={infoConfig?.iconContentWidth || 34}
            height={infoConfig?.iconContentHeight || 34}
          />
        </div>
        <div className="infos-skeleton-wrapper">
          <ShimmerText line={1} />
          <ShimmerText line={1} />
        </div>
      </SkeletonCardInfo>
    ),
    card: (
      <ShimmerCategoryItem
        hasImage
        imageType={cardConfig?.imageType || 'circular'}
        imageWidth={cardConfig?.imageWidth || 43}
        imageHeight={cardConfig?.imageHeight || 43}
        title
        {...cardConfig}
      />
    ),
    button: <ShimmerButton size="lg" />,
    // TODO::: other Skeleton types
  };

  return (
    <SkeletonLoaderContainer width={width || '100%'} height={height || '100%'}>
      {SKELETON_TYPES[type]}
    </SkeletonLoaderContainer>
  );
};
