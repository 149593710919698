import styled, { css } from 'styled-components';

interface Props {
  width: string;
  height: string;
}

export const SkeletonLoaderContainer = styled.div<Props>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

  .shimmer-text,
  .shimmer-avatar,
  .shimmer-thumbnail {
    margin-bottom: 0px;
    display: inherit;
  }

  .shimmer-thumbnail {
    min-width: 0px;
  }

  .shimmer-button {
    width: 100%;
    margin-bottom: 0px;
  }

  .shimmer-title--secondary {
    margin-bottom: 0px;
  }
  .p-15 {
    padding: 0px;
    padding-left: 8px;
  }
`;

export const SkeletonCardInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .shimmer-thumbnail {
    min-width: 0px;
    border-radius: 8px;
  }
  .shimmer-text {
    width: inherit;
  }
  .infos-skeleton-wrapper {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`;
