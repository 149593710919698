/* eslint-disable no-case-declarations */
import { ActionTypes, UserSoftSkillsReducer } from './types';

const userSoftSkillsTypeInit = {
  innovation: '',
  level: '',
  mentalSecurity: '',
  purpose: '',
  teamWork: '',
  totalPoints: '',
  trustiness: '',
};

const userSoftSkillsReducer: UserSoftSkillsReducer = (
  state = userSoftSkillsTypeInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_SOFT_SKILLS_TYPE:
      const { payload } = action;
      return payload;

    default:
      return state;
  }
};

export default userSoftSkillsReducer;
