// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from 'AppV2/translations/i18next.init';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import setBrowserLanguageThunk from 'Store/modules/browserLanguage/thunk';
import { AppLanguage, AppLanguageCookieKey } from './types';
import moment from 'moment-timezone';

export const useAppLanguage = () => {
  const [_, setCookie] = useCookies();
  const dispatch = useDispatch();

  const i18Language = i18n.language.split('-')[0];
  const appLanguage = ['pt', 'en'].includes(i18Language)
    ? (i18Language as AppLanguage)
    : ('en' as AppLanguage);
  const appLocale = appLanguage === 'pt' ? 'pt-BR' : 'en-US';

  const changeAppLanguage = (language: AppLanguage) => {
    // set language in global states
    i18n.changeLanguage(language);
    dispatch(setBrowserLanguageThunk(language));

    // set language in browser cookie
    setCookie(AppLanguageCookieKey, language, {
      expires: new Date(moment(new Date()).add(1, 'year').valueOf()),
      path: '/',
    });
  };

  return { appLanguage, appLocale, changeAppLanguage };
};
