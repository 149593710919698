import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PatchInviteUserRequest, PatchInviteUserResponse } from './types';

export const patchInviteUser = async ({
  invitationEmail,
}: PatchInviteUserRequest): Promise<AxiosResponse<PatchInviteUserResponse>> => {
  try {
    const response = await apiV2Instance.patch(`/workspace/inviteUser`, {
      invitationEmail: invitationEmail,
    });
    console.log('[services][Workspace][PatchInviteUser][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][Workspace][PatchInviteUser][ERROR]', errorResponse);

    throw errorResponse;
  }
};
