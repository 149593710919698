import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';
import { Styles } from 'react-modal';
import styled, { CSSObject } from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(6, 6, 6, 72%);
  backdrop-filter: blur(6px);
  z-index: 99999;
`;

export const ModalContainer = styled(motion.div)<{ styles: CSSObject }>`
  width: 100vw;
  min-height: 210px;
  padding: 34px 31px;
  border-radius: 30px 30px 0px 0px;
  background-color: ${colors.background100};

  position: relative;
  .button-close {
    position: absolute;
    z-index: 999999;
    right: 31px;
    top: 34px;

    color: ${colors.text300};
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
  }

  ${({ styles }) => styles};
`;

export const ModalCustomStyles = {
  content: {
    position: 'initial',
    overflow: 'hidden',
    width: '100% !important',
    height: '100% !important',
    inset: 'initial !important',
    left: '0px !important',
    bottom: '0px !important',
    padding: '0px !important',
    border: 'none !important',
    borderRadius: '0px !important',
    background: 'transparent !important',
  },
  overlay: {
    backgroundColor: 'rgba(6, 6, 6, 72%)',
    backdropFilter: 'blur(6px)',
    zIndex: 99999,
    display: 'flex',
    alignItems: 'flex-end',
  },
} as Styles;
