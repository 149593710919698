import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_CARD_RADIO_MODAL = '@cardRadioModal/SET',
}

export type CardRadioModal = {
  isOpen: boolean;
  thisCard: null;
};

export type CardRadioModalReducer = Reducer<
  CardRadioModal,
  Action<ActionTypes>
>;
