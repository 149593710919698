import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PutPermissionsRequest } from './types';

export const putEditUserPermissions = async (
  request: PutPermissionsRequest,
): Promise<AxiosResponse<Response>> => {
  try {
    const requestBody = {
      userId: request.userId,
      forbiddens: request.forbiddens,
    };

    const response = await apiV2Instance.put(
      '/workspace/updateUserPermission',
      requestBody,
    );
    console.log(
      '[services][Workspace][PutEditUserPermissions][response]',
      response,
    );

    return response;
  } catch (error) {
    console.log('[services][Workspace][PutEditUserPermissions][error]', error);

    throw error;
  }
};
