import { Reducer } from 'redux';
import { Action } from 'Store/types';
export enum ActionTypes {
  SET_POPUP_ERROR = '@popUpError/SET',
}

export type PopUpError = {
  isOpen: boolean;
  message: string | null;
};

export type PopUpErrorReducer = Reducer<
  PopUpError,
  Action<ActionTypes, PopUpError>
>;
