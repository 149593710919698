/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-escape */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'Contexts/AuthContext';
import Loading from 'Components/Organisms/Loading';
import { useAlert } from 'AppV2/providers/AlertProvider';

export const ResetPasswordActionCodeSessionStorageKey =
  'resetPasswordActionCode';

const ServicePage = () => {
  const history = useHistory();
  const { setOpenAlert } = useAlert();
  const { verifyUSerEmail, isAuth } = useAuth();

  const getParameterByName = name => {
    const paramName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regexS = '[\\?&]' + paramName + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.href);

    if (results === null) return '';
    else return decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
  const checkUserEmail = async urlActionCode => {
    const startVerify = await verifyUSerEmail(urlActionCode);
    return startVerify;
  };

  const mode = getParameterByName('mode');
  const urlActionCode = getParameterByName('oobCode');
  // const continueUrl = getParameterByName('continueUrl');
  // const lang = getParameterByName('lang') || 'pt-br';

  useEffect(() => {
    if (mode === 'verifyEmail') {
      checkUserEmail(urlActionCode).then(async res => {
        if (!res.status) {
          setOpenAlert({
            shouldOpen: true,
            type: 'error',
            title: 'Error',
            description: res.message,
          });

          if (!isAuth) {
            return history.push('/login');
          }

          history.push('/confirm-email');
        } else {
          if (isAuth) {
            return history.push('/email-checked');
          }
          return history.push('/login');
        }
      });
    }
    if (mode === 'resetPassword') {
      localStorage.setItem(
        ResetPasswordActionCodeSessionStorageKey,
        urlActionCode,
      );

      history.push('/reset-password');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, urlActionCode, setOpenAlert]);

  return <Loading />;
};

export default ServicePage;
