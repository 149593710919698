/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
const Button = ({
  style,
  thisText,
  thisType,
  thisId,
  thisIcon,
  thisDisabled,
  thisOnClick,
  thisTestId = null,
}) => {
  const testId = {};
  if (thisTestId) testId['data-testid'] = thisTestId;

  return (
    <button
      style={{
        background: 'var(--purple)',
        fontWeight: '600',
        cursor: thisDisabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      className="button full-width button-sliding-icon ripple-effect"
      type={thisType}
      id={thisId}
      disabled={thisDisabled}
      onClick={thisOnClick}
      {...testId}
    >
      {thisText}
      <i className={thisIcon || 'icon-material-outline-arrow-right-alt'} />
    </button>
  );
};

export default Button;
