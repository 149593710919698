/* eslint-disable no-case-declarations */
import { ActionTypes, BriefAnswerReducer } from './types';

const projectBriefFormAnswerReducer: BriefAnswerReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_PROJECT_BRIEF_ANSWER:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default projectBriefFormAnswerReducer;
