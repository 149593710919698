import { GetMovingMotivatorsResponse } from 'AppV2/services/UserTests/MovingMotivators/GetResults/types';
import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_USER_MOTIVATORS_TYPE = '@userMotivatorsType/SET',
}
export type UserMotivators = GetMovingMotivatorsResponse;

export type UserMotivatorsReducer = Reducer<
  UserMotivators,
  Action<ActionTypes>
>;
