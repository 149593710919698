import { ActionTypes, CMSSquadSelectionReducer } from './types';

/* eslint-disable no-case-declarations */
const cmsSquadSelectionReducer: CMSSquadSelectionReducer = (
  state = { isFilter: false, searchJSON: null },
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_CMS_SQUAD_SELECTION_SET:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default cmsSquadSelectionReducer;
