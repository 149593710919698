import techLeadSVG from 'Images/cardTechLead.svg';
import POSVG from 'Images/cardPO.svg';
import designerSVG from 'Images/cardDesigner.svg';
import developerSVG from 'Images/cardDeveloper.svg';

const randomCardIcon = {
  generateCard: function () {
    const iconsArray = [
      techLeadSVG,
      POSVG,
      designerSVG,
      developerSVG,
      POSVG,
      developerSVG,
      designerSVG,
      techLeadSVG,
      techLeadSVG,
      developerSVG,
      POSVG,
      designerSVG,
    ];
    const randomIndex = Math.floor(Math.random() * 12) - 1;
    return iconsArray[randomIndex < 0 ? 0 : randomIndex];
  },
};

export default randomCardIcon;
