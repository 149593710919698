/* eslint-disable no-case-declarations */
import { ActionTypes, AllSkillsReducer } from './types';

const allSkillsInit = {
  skill: [],
  software: [],
  methodologies: [],
  language: [],
  database: [],
};

const allSkillsReducer: AllSkillsReducer = (state = allSkillsInit, action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_SKILLS:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default allSkillsReducer;
