import styled, { css } from 'styled-components';

interface Props {
  width: string;
  isWorkspaceSelected?: boolean;
  isOnlyIcon: boolean;
}

export const LogoContent = styled.div<Props>`
  width: ${({ width }) => width || '120px'};

  ${({ isWorkspaceSelected }) =>
    isWorkspaceSelected &&
    css`
      scale: 1.2;
    `}

  ${({ isOnlyIcon }) =>
    isOnlyIcon &&
    css`
      width: auto;
      max-width: 36px;
    `}
`;
