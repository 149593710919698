import { getUsers } from './GetUsers/index';
import { getOwnerByEmail } from './GetOwnerByEmail/index';
import { getInvitedWorkspaces } from './GetInvitedWorkspaces/index';
import { postAddUsers } from './PostAddUsers/index';
import { putRemoveUser } from './PutRemoveUser/index';
import { putEditUserPermissions } from './PutEditUserPermissions/index';
import { patchInviteUser } from './PatchInviteUser';
import { getInvite } from './GetInvite';
import { patchAcceptInvite } from './PatchAcceptInvite';

export const WorkspaceIdCookieKey = 'workspaceId:userId:';
export const WorkspaceUserNameCookieKey = 'workspaceUserName:userId:';
export const WorkspaceUserPhotoCookieKey = 'workspaceUserPhoto:userId:';
export const WorkspaceForbiddensSessionStorageKey = 'WorkspaceForbiddens';

export const workspaceServices = {
  getUsers,
  getInvitedWorkspaces,
  getOwnerByEmail,
  postAddUsers,
  putRemoveUser,
  putEditUserPermissions,
  patchInviteUser,
  getInvite,
  patchAcceptInvite,
};
