/* eslint-disable react/jsx-filename-extension */
import { ReactElement, useState } from 'react';
import { ModalMain } from 'AppV2/components/Modal';
import {
  ModalSelectAppLanguageContainer as Container,
  RadioInputContent,
  SelectLanguageWrapper,
  Title,
} from './styles';
import { Button } from 'AppV2/components/Button';
import { RadioInput } from 'AppV2/components/RadioInput';
import i18n from 'AppV2/translations/i18next.init';
import { useAppLanguage } from 'AppV2/hooks/AppLanguage';
import { useModal } from 'AppV2/providers/ModalProvider';
import { translateText } from 'AppV2/common/translateText';
import { AppLanguage } from 'AppV2/hooks/AppLanguage/types';
import { colors } from 'AppV2/styles/colors';

interface Props {
  isOpen: boolean;
}

export const ModalSelectLanguage = ({ ...props }: Props): ReactElement => {
  const { closeModal } = useModal();
  const { changeAppLanguage } = useAppLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language.split('-')[0],
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectLanguage = (language: AppLanguage) => {
    setSelectedLanguage(language);
  };

  const handleConfirmSelectLanguage = () => {
    setIsLoading(true);
    const language = selectedLanguage as AppLanguage;
    changeAppLanguage(language);

    setTimeout(() => {
      closeModal();
      setIsLoading(false);
    }, 2100);
  };

  const languageRadioOptions = translateText(
    'HEADER_USER_MENU:MODAL_SELECT_APP_LANGUAGES',
  ).split(',');

  const languagesEnum = {
    0: 'pt',
    1: 'en',
  };

  return (
    <ModalMain isOpen={props.isOpen} bgColor={colors.background100}>
      <Container>
        <Title>
          {translateText('HEADER_USER_MENU:MODAL_SELECT_APP_LANGUAGE_TITLE')}
        </Title>

        <SelectLanguageWrapper>
          {languageRadioOptions.map((lang, index) => (
            <RadioInputContent
              key={lang}
              isChecked={selectedLanguage === languagesEnum[index]}
              onClick={() => handleSelectLanguage(languagesEnum[index])}
            >
              <RadioInput
                name={languagesEnum[index]}
                isChecked={selectedLanguage === languagesEnum[index]}
                onChange={() => handleSelectLanguage(languagesEnum[index])}
              />
              <label htmlFor={languagesEnum[index]}>{lang}</label>
            </RadioInputContent>
          ))}
        </SelectLanguageWrapper>

        <Button full onClick={handleConfirmSelectLanguage} loading={isLoading}>
          {translateText('HEADER_USER_MENU:MODAL_SELECT_APP_CONFIRM_BUTTON')}
        </Button>
      </Container>
    </ModalMain>
  );
};
