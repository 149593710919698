/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { memo, Suspense } from 'react';

import { SuspenseImg } from 'Components/Atoms/SuspenseImg';
import primaryLogoSVG from 'AppV2/assets/images/logo-Growyx.svg';
import secondaryLogoSVG from 'AppV2/assets/images/logo-Growyx-whiteText.svg';

const Logo = ({ isWhiteLogo = false, isSuspense = false }) => {
  const primaryLogo = primaryLogoSVG;

  const cacheBuster = +new Date();
  const primaryCacheLogo = `${primaryLogo}?cachebust=${cacheBuster}`;
  const secondaryCacheLogo = `${secondaryLogoSVG}?cachebust=${cacheBuster}`;

  return (
    <>
      {isSuspense ? (
        <Suspense fallback={<></>}>
          <div className="logo-content">
            <SuspenseImg
              src={isWhiteLogo ? secondaryCacheLogo : primaryCacheLogo}
            />
          </div>
        </Suspense>
      ) : (
        <div className="logo-content">
          <img
            src={isWhiteLogo ? secondaryLogoSVG : primaryLogo}
            alt={isWhiteLogo ? secondaryLogoSVG : primaryLogo}
          />
        </div>
      )}
    </>
  );
};

export default memo(props => <Logo {...props} />);
