/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import CloseCircleIcon from 'AppV2/assets/icons/CloseCircle';
import WorkspaceSVG from 'AppV2/assets/images/workspace.svg';
import { WorkspacePopUpContainer } from './styles';
import { translateText } from 'AppV2/common/translateText';

interface Props {
  handleClose: () => void;
}

export const PopUpWorkspacePresentation = ({
  handleClose,
}: Props): ReactElement => {
  return (
    <WorkspacePopUpContainer>
      <button className="close-popup-button" onClick={handleClose}>
        <CloseCircleIcon />
      </button>
      <div className="illustration-content">
        <img src={WorkspaceSVG} />
      </div>
      <div className="description-content">
        <h2>{translateText('HEADER_USER_MENU:WORKSPACE_POPUP_TITLE')}</h2>
        <p>{translateText('HEADER_USER_MENU:WORKSPACE_POPUP_DESCRIPTION')}</p>
      </div>
    </WorkspacePopUpContainer>
  );
};
