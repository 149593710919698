/* eslint-disable react/jsx-filename-extension */
import React, { ReactElement } from 'react';
import { LoadingSpinner, StyledButton } from './styles';
import { CSSObject } from 'styled-components';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  bordered?: boolean;
  outlined?: boolean;
  text?: boolean;
  full?: boolean;
  hlarge?: boolean;
  borderColor?: string;
  fullColor?: string;
  blue?: boolean;
  red?: boolean;
  redBordered?: boolean;
  white?: boolean;
  type?: 'button' | 'submit' | 'reset';
  iconWidth?: string;
  styles?: CSSObject;
  stylesVariation?: 'default' | 'mini' | 'text_mini';
}

export function Button({
  id,
  loading,
  disabled,
  icon,
  children,
  type,
  hlarge,
  iconWidth,
  styles,
  stylesVariation,
  ...props
}: ButtonProps): React.ReactElement {
  return (
    <StyledButton
      id={id}
      type={type || 'button'}
      icon={icon}
      iconWidth={iconWidth}
      hlarge={hlarge}
      disabled={disabled || loading}
      styles={styles}
      stylesVariation={stylesVariation}
      {...props}
    >
      <>
        {loading ? (
          <LoadingSpinner data-testid="button-loading" />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </>
    </StyledButton>
  );
}
