/* eslint-disable no-case-declarations */
import { ActionTypes, MobileFormsReducer } from './types';

const mobileFormInit = {
  isOpen: false,
  isHidden: true,
  thisFormIndex: null,
};

const mobileFormReducer: MobileFormsReducer = (
  state = mobileFormInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_MOBILE_FORM:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default mobileFormReducer;
