/* eslint-disable no-case-declarations */
import { ActionTypes, OpenAlertReducer } from './types';

const alertReducer: OpenAlertReducer = (
  state = { shouldOpen: false },
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_OPEN_ALERT:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default alertReducer;
