import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_JOB_SPECIFICATIONS = '@jobSpecificationsModal/SET',
}

export type JobSpecificationModal = {
  isOpen: boolean;
  text: null;
};

export type JobSpecificationModalReducer = Reducer<
  JobSpecificationModal,
  Action<ActionTypes>
>;
