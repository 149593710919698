/* eslint-disable no-case-declarations */
import { ActionTypes, InEditProjectDescriptionReducer } from './types';

const inEditInit = {
  inEdit: false,
  modalIsOpen: false,
};

const inEditProjectDescriptionReducer: InEditProjectDescriptionReducer = (
  state = inEditInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_IN_EDIT_PROJECT_DESCRIPTION:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default inEditProjectDescriptionReducer;
