import { ActionTypes, UserValuesReducer } from './types';

const storagedUserValues = localStorage.getItem('userValues');

const initUserValues = {
  hourValue: '',
  hourReceive: '',
  monthValue: '',
  monthReceive: '',
  currency: '',
};

const stateUserValues = storagedUserValues
  ? JSON.parse(storagedUserValues)
  : initUserValues;

const userValuesReducer: UserValuesReducer = (
  state = stateUserValues,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_VALUES:
      return action.payload;

    default:
      return state;
  }
};

export default userValuesReducer;
