/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { memo } from 'react';

import {
  Link,
  LinkIconContent,
  LogoContent,
  MenuContent,
  OpenCloseMenuButton,
  ULinksList,
} from '../style';

import { translateText } from 'AppV2/common/translateText';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';
import NavBarHoverIcon from 'AppV2/assets/icons/NavBarHover';
import ArrowLeftSolidIcon from 'AppV2/assets/icons/ArrowLeftSolid';
import { Logo } from 'AppV2/components/Logo';
import { MenuNavBarProps } from '../index';
import { Tooltip } from 'AppV2/components/MenuTooltip';
import { ADMIN_LINKS } from '../userRoleLinks';

export const AdminViewMenuNavBar = memo(
  ({
    appVersionStyle,
    isMenuOpen,
    openTooltip,
    handleOpenTooltip,
    handleOpenCloseMenu,
    handleRedirect,
    isLinkSelected,
    openCloseMenuTooltipText,
  }: MenuNavBarProps) => {
    return (
      <MenuContent appVersionStyle={appVersionStyle} isOpen={isMenuOpen}>
        <OpenCloseMenuButton
          onClick={handleOpenCloseMenu}
          onMouseOver={() =>
            !openTooltip && handleOpenTooltip('openCloseButton')
          }
          onMouseLeave={() => openTooltip && handleOpenTooltip('')}
        >
          <ArrowLeftSolidIcon />
          <Tooltip
            isOpen={openTooltip === 'openCloseButton'}
            text={openCloseMenuTooltipText}
            positionX={isMenuOpen ? 67 : 64}
            initialPositionX={32}
          />
        </OpenCloseMenuButton>

        <LogoContent isOpen={isMenuOpen}>
          <Logo onlyIcon={!isMenuOpen} />
        </LogoContent>

        <ULinksList isMenuOpen={isMenuOpen}>
          {ADMIN_LINKS.map(link => (
            <Link
              key={link.path}
              isMenuOpen={isMenuOpen}
              isSelected={isLinkSelected(link.path)}
              onMouseOver={() => !isMenuOpen && handleOpenTooltip(link.path)}
              onMouseLeave={() => !isMenuOpen && handleOpenTooltip('')}
              onClick={() =>
                handleRedirect(link.path, link.text, UserRoleEnum.admin)
              }
            >
              <LinkIconContent
                isSelected={isLinkSelected(link.path)}
                iconSize={link?.iconSize}
              >
                {link.icon}
              </LinkIconContent>
              <span className="li-span">
                <>{translateText(link.text)}</>
              </span>
              <div className="nav-bar-hover-icon">
                <NavBarHoverIcon />
              </div>

              {!isMenuOpen && (
                <Tooltip
                  isOpen={openTooltip === link.path}
                  text={translateText(link.text)}
                />
              )}
            </Link>
          ))}
        </ULinksList>
      </MenuContent>
    );
  },
);
