/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint react/prop-types: 0 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';
import store from 'Store';
import { MainProvider } from 'AppV2/providers';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { queryClient } from 'AppV2/configs/QueryClient';
import reportWebVitals from './reportWebVitals';
import 'dotenv/config';
import 'AppV2/translations/i18next.init';
import App from 'App';

const root = createRoot(document.getElementById('root'));

if (process.env.REACT_APP_ENABLE_CONSOLE_LOG === 'false') {
  console.log = function () {};
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <MainProvider>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </MainProvider>
        </BrowserRouter>
      </ReduxProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
