/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { TemplatesConfig } from 'AppV2/hooks/TemplatesConfig/types';

interface Props {
  templatesConfig: TemplatesConfig;
}

export const Head = ({ templatesConfig }: Props): ReactElement => {
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href={templatesConfig.head.favIconAppleTouch}
      />
      <link rel="icon" href={templatesConfig.head.favIcon} />

      <meta name="description" content={templatesConfig.head.description} />
      <meta name="og:description" content={templatesConfig.head.description} />

      <title>{templatesConfig.head.title}</title>
    </Helmet>
  );
};
