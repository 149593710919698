/* eslint-disable no-case-declarations */
import { ActionTypes, CurrentPageReducer } from './types';

const currentPageReducer: CurrentPageReducer = (state = 1, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PAGE:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default currentPageReducer;
