/* eslint-disable no-case-declarations */
import { ActionTypes, CardRadioModalReducer } from './types';

const cardRadioModalInit = {
  isOpen: false,
  thisCard: null,
};

const cardRadioModalReducer: CardRadioModalReducer = (
  state = cardRadioModalInit,
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_CARD_RADIO_MODAL:
      const { payload } = action;

      return payload;

    default:
      return state;
  }
};

export default cardRadioModalReducer;
