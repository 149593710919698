import { Reducer } from 'redux';
import { Action } from 'Store/types';

export enum ActionTypes {
  SET_PROJECT_BRIEF_ANSWER = '@briefFormAnswer/SET',
}

export type BriefAnswer = any;

export type BriefAnswerReducer = Reducer<BriefAnswer, Action<ActionTypes>>;
