/* eslint-disable prefer-regex-literals */
import axios from 'axios';
import { auth } from 'FirebaseFiles/Firebase';
import { apiV1Instance } from "AppV2/configs/Axios/api_V1"
import { queryClient } from 'AppV2/configs/QueryClient';
import { hasPositionIndexAndIsDigit } from 'Common/ProjectBriefView/hasPositionIndexAndIsDigit';
import { setAxiosDefaultToken } from 'AppV2/common/setAxiosDefaultOptions';

const getIdToken = async () => {
  const idTokenRequest = auth.currentUser
    ? await auth.currentUser.getIdToken(true)
    : null;
  // const idTokenRequest = await auth.currentUser.getIdToken(true)
  return idTokenRequest;
};

export const apiInstance = axios.create();

const gapi = {
  projects: {
    post: async function (obj) {
      try {
        const res = await apiV1Instance.post(
          process.env.REACT_APP_FIREBASE_PROJECTS_API,
          obj
        );
        queryClient.removeQueries();
        return res;
      } catch (error) {
        const errorResponse = error?.response?.data || error;
        console.error('post projects error', errorResponse);
        throw errorResponse;
      }
    },
    get: async function (projectId) {
      try {
        const res = await apiV1Instance.get(
          `${process.env.REACT_APP_FIREBASE_PROJECTS_API}?projectId=${projectId}`)
        return res;
      } catch (error) {
        console.error('get projects error', error);
        return error.message;
      }
    },
    put: async function (obj) {
      try {
        const res = await apiV1Instance.put(
          process.env.REACT_APP_FIREBASE_PROJECTS_API,
          obj);

        queryClient.removeQueries();
        return res;
      } catch (error) {
        console.error('put projects error', error);
        return error;
      }
    },
  },
  users: {
    post: async function (obj) {
      try {
        setAxiosDefaultToken(await getIdToken());

        const res = await apiV1Instance.post(
          process.env.REACT_APP_FIREBASE_USERS_API,
          obj,
        );
        return res;
      } catch (error) {
        const errorResponse = error?.response?.data || error;
        console.error('post users error', errorResponse);
        throw errorResponse;
      }
    },
    put: async function (obj) {
      try {
        let putUserUrl = process.env.REACT_APP_FIREBASE_USERS_API
        const isAdminEditingCompanyInfo = obj?.updateObj?.isAdminEditingCompanyInfo
        if (isAdminEditingCompanyInfo) {
          putUserUrl += `?userId=${obj.updateObj.userId}&operation=squadOwnerCompanyInfo`
          delete obj.updateObj.userId
          delete obj.updateObj.isAdminEditingCompanyInfo
        }
        const res = await apiV1Instance.put(
          putUserUrl,
          obj
        );
        return res;
      } catch (error) {
        const errorResponse = error?.response?.data || error;
        console.error('put users error', errorResponse);
        throw errorResponse;
      }
    },
    get: async function () {
      try {
        const res = await apiV1Instance.get(
          process.env.REACT_APP_FIREBASE_USERS_API
        );
        return res;
      } catch (error) {
        const errorResponse = error?.response?.data || error;
        console.error('get users error', errorResponse);
        throw errorResponse;
      }
    },
    getByID: async function (userId) {
      try {
        const res = await apiV1Instance.get(
          `${process.env.REACT_APP_FIREBASE_USERS_API}?id=${userId}`
        );
        return res;
      } catch (error) {
        console.error('get by id users error', error);
        throw error?.response?.data || error;
      }
    },
  },
  customClaim: {
    post: async function (obj) {
      try {
        const idToken = await getIdToken();
        const customClaimData = {
          idToken: idToken,
          customClaims: obj,
        };

        const res = await apiV1Instance.post(
          process.env.REACT_APP_FIREBASE_AUTH_API,
          customClaimData,
          {
            headers: {
              Authorization: idToken
            }
          }
        );
        return res;
      } catch (error) {
        console.error('post custom claim', error);
        return error.message;
      }
    },
  },
  admission: {
    put: async function (obj) {
      try {
        const res = await apiV1Instance.put(
          process.env.REACT_APP_FIREBASE_PROJECT_ADMISSION_API,
          obj
        );
        return res;
      } catch (error) {
        console.error('put project squads', error);
        return error;
      }
    },
  },
  selfAdmission: {
    execute: async function ({ projectId = '', positionIndex = null }) {
      try {
        let queryParam = `?projectId=${projectId}`;

        if (hasPositionIndexAndIsDigit(positionIndex)) {
          queryParam = `?projectId=${projectId}&position=${positionIndex}`;
        }
        const res = await apiV1Instance.get(
          `${process.env.REACT_APP_FIREBASE_PROJECT_SELF_ADMISSION_API}${queryParam}`
        );
        return res;
      } catch (error) {
        console.error('self Admission execute error', error?.message || error);
        throw error?.message || error;
      }
    },
  },

  log: {
    get: async function (obj) {
      try {
        const res = await apiV1Instance.get(
          `${process.env.REACT_APP_FIREBASE_GAPIV1}log?${obj}`
        );
        return res;
      } catch (error) {
        console.error('[gapi][logs][catchError]: ', error);
        return error;
      }
    },
  },
  adminIsAvailable: {
    put: async function (obj) {
      try {
        const res = await apiV1Instance.put(
          process.env.REACT_APP_FIREBASE_GAPIV1 + 'disableAdmission',
          obj
        );
        return res;
      } catch (error) {
        console.error('[gapi][adminIsAvailable][catchError]: ', error);
        return error;
      }
    },
  },
};

export default gapi;
