/* eslint-disable camelcase */

// GET QUERY
export interface ProfessionsGroupQuery {
  jobTitles?: boolean;
  jobTitle?: string;
  correlacionadas?: boolean;
  pontos_items?: boolean;
}
// QUERY CACHE KEY
export const ProfessionsGroupQueryCacheKey = 'GetProfessionsGroup';

// TYPES
export enum JobTitleTypesEnum {
  generic = 'generic',
  techProduct = 'techProduct',
  marketingDesign = 'marketingDesign',
}
export type JobTitleType = typeof JobTitleTypesEnum;
export type JobTitlesArray = string[];
export type JobTitlesDictionary = string[];
export type PontoItemsObject = {
  methodology?: number;
  programing_language?: number;
  skills?: number;
  softwares?: number;
};
export type JobTitleText = {
  ['pt-BR']: string;
  ['en-US']: string;
};

export interface ProfessionsGroupResponseData {
  [jobTitle: string]: {
    type: JobTitleType;
    correlacionadas: JobTitlesArray;
    pontos_items: PontoItemsObject;
    dictionary: JobTitlesDictionary;
    text: JobTitleText;
  };
}
