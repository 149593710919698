/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  LoginSignupMainContainer,
  LoginSignupSectionContainer,
} from 'Containers/LoginSignUp';
import { useAuth } from 'Contexts/AuthContext';
import setLoginOrSignUpFormThunk from 'Store/modules/loginSignUpForms/changeForm/thunk';
import SignUp from 'Components/Organisms/SignUp';
import Logo from 'Components/Atoms/Generals/Logo';
import Loading from 'Components/Organisms/Loading';
import setAccountTypeThunk from 'Store/modules/loginSignUpForms/accountType/thunk';
import {
  sessionStorageIsSquadOwnerSession,
  sessionStorageSalesSignIn,
} from 'Common/sessionStorage';

export const IsCampaignFlowSessionStorageKey = 'isCampaignFlow';

export const SalesSignIn = () => {
  const { campaign } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getCustomClaim, isFirstSocialLogin } = useAuth();
  const [loadingPage, setLoading] = useState(false);
  const [loadWhitePage, setLoadWhitePage] = useState(true);

  dispatch(setAccountTypeThunk('squadOwner'));

  useEffect(() => {
    if (isFirstSocialLogin) {
      dispatch(setLoginOrSignUpFormThunk(false));
    }
    if (getCustomClaim('claim')) {
      setLoading(false);
      setLoadWhitePage(true);
      getCustomClaim('claim').then(() => {
        sessionStorageIsSquadOwnerSession('set');
        sessionStorageSalesSignIn('set');
        setLoadWhitePage(false);
        setLoading(true);
        setTimeout(() => setLoading(false), 2100);
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (campaign) {
      console.log('isSales: ', campaign);
      sessionStorage.setItem(IsCampaignFlowSessionStorageKey, 'true');
    }
  }, [campaign]);

  return (
    <>
      {loadingPage && <Loading />}
      {loadWhitePage && ( // se estiver logado, setar tela branca até terminar o getCustomClaim, evitando a dupla chamada de <Loading/> ao fizer push para 'navigationStage'
        <Loading />
      )}
      <LoginSignupMainContainer>
        <Logo />
        <LoginSignupSectionContainer>
          <SignUp setLoading={setLoading} showsAccountType={false} />
        </LoginSignupSectionContainer>
      </LoginSignupMainContainer>
    </>
  );
};
