import { LoadingBasic } from './variations/Basic';
import { LoadingPageDefault } from './variations/Default';
import { LazyLoadingPage } from './variations/Lazy';

type LoadingPageTypes = typeof LoadingPageDefault;
type LazyLoadingPageTypes = typeof LazyLoadingPage;
type LoadingBasicTypes = typeof LoadingBasic;
interface CompoundedComponent extends LoadingPageTypes {
  Lazy: LazyLoadingPageTypes;
  Basic: LoadingBasicTypes;
}

export const LoadingPage = LoadingPageDefault as CompoundedComponent;
LoadingPage.Lazy = LazyLoadingPage;
LoadingPage.Basic = LoadingBasic;
