import { ActionTypes, LoadingPageReducer } from './types';

const loadingPageReducer: LoadingPageReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING_PAGE:
      return action.payload;

    default:
      return state;
  }
};

export default loadingPageReducer;
